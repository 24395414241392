// import styled from '@emotion/styled'
// import { Component } from 'react'
// import { AnimatedPlaceholderLogo } from './AnimatedPlaceholderLogo'
// import { Box } from './Box'

// interface State {
//   loading: boolean
// }

// export class MediaImage extends Component<any, State> {
//   state: State = {
//     loading: !this.props.media?.mimetype?.includes('video') ?? false,
//   }

//   render() {
//     const { media, finishLoad, fullRadius, ...rest } = this.props
//     const { loading } = this.state

//     console.log('media', media)
//     return (
//       <>
//         {loading && (
//           <Box {...(rest as any)}>
//             <AnimatedPlaceholderLogo style={{ width: 70, height: 70 }} />
//           </Box>
//         )}
//         {!media?.mimetype?.includes('video') && (
//           <Image
//             alt={media?.name}
//             loading="eager" // forza il caricamento immediato
//             decoding="async" // ottimizza il decode
//             onLoad={() => this.setState({ loading: false })}
//             style={{
//               objectFit: 'cover',
//               borderTopLeftRadius: 14,
//               borderTopRightRadius: 14,
//               borderBottomLeftRadius: fullRadius ? 14 : fullRadius ? 14 : 0,
//               borderBottomRightRadius: fullRadius ? 14 : 0,
//               padding: 1,
//               display: loading ? 'none' : 'unset ',
//             }}
//             src={media?.url}
//             {...rest}
//           />
//         )}
//         {media?.mimetype?.includes('video') && (
//           <video
//             {...rest}
//             className="video-media"
//             controls
//             onLoadedData={() => {
//               try {
//                 finishLoad()
//               } catch (e) {}
//             }}
//             style={{
//               borderTopLeftRadius: 14,
//               borderTopRightRadius: 14,
//               borderBottomLeftRadius: fullRadius ? 14 : 0,
//               borderBottomRightRadius: fullRadius ? 14 : 0,
//             }}
//             src={media.url}
//           />
//         )}
//       </>
//     )
//   }
// }

// const Image = styled.img`
//   min-height: 100px !important;
//   max-height: 400px;
// `

import React, { Suspense, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { AnimatedPlaceholderLogo } from './AnimatedPlaceholderLogo'

interface MediaProps {
  media?: {
    url?: string
    name?: string
    mimetype?: string
  }
  fullRadius?: boolean
  finishLoad?: () => void
  index?: number // Aggiungiamo l'indice per identificare la posizione
  [key: string]: any
}

const StyledImage = styled.img`
  min-height: 200px !important;
  max-height: 400px;
  object-fit: cover;
  transition: opacity 0.3s ease-in-out;
  padding: 1px;
`

const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const MediaImage = React.memo(({ media, finishLoad, fullRadius, index = 0, ...rest }: MediaProps) => {
  const [loading, setLoading] = useState(!media?.mimetype?.includes('video'))
  const [error, setError] = useState(false)
  const imageRef = useRef<HTMLImageElement>(null)
  const observerRef = useRef<IntersectionObserver | null>(null)

  const isInFirstTwoRows = index < 6 // Assumendo una griglia di 3 colonne (3 * 2 = 6)

  useEffect(() => {
    if (!imageRef.current || !window.IntersectionObserver || isInFirstTwoRows) return

    observerRef.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const img = entry.target as HTMLImageElement
            if (img.dataset.src) {
              img.src = img.dataset.src
              img.removeAttribute('data-src')
            }
            observerRef.current?.unobserve(img)
          }
        })
      },
      {
        rootMargin: '50px',
        threshold: 0.1,
      }
    )

    observerRef.current.observe(imageRef.current)

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect()
      }
    }
  }, [media?.url, isInFirstTwoRows])

  const getImageStyles = (isFullRadius?: boolean) => ({
    borderTopLeftRadius: 14,
    borderTopRightRadius: 14,
    borderBottomLeftRadius: isFullRadius ? 14 : 0,
    borderBottomRightRadius: isFullRadius ? 14 : 0,
    opacity: loading ? 0 : 1,
  })

  const handleImageLoad = () => {
    setLoading(false)
    setError(false)
  }

  const handleImageError = () => {
    setLoading(false)
    setError(true)
  }

  const handleVideoLoad = () => {
    try {
      finishLoad?.()
    } catch (e) {
      console.error('Error in video load handler:', e)
    }
  }

  const isVideo = media?.mimetype?.includes('video')

  return (
    <>
      {loading && !isVideo && (
        <Box {...rest}>
          <AnimatedPlaceholderLogo style={{ width: 70, height: 70 }} />
        </Box>
      )}

      {error && !isVideo && (
        <Box {...rest} display="flex" alignItems="center" justifyContent="center">
          <span>Failed to load image</span>
        </Box>
      )}

      {!isVideo && media?.url && (
        <StyledImage
          ref={imageRef}
          src={isInFirstTwoRows ? media.url : undefined}
          data-src={!isInFirstTwoRows ? media.url : undefined}
          alt={media?.name || 'Media content'}
          onLoad={handleImageLoad}
          onError={handleImageError}
          style={getImageStyles(fullRadius)}
          {...rest}
        />
      )}

      {isVideo && media?.url && (
        <StyledVideo
          controls
          preload={isInFirstTwoRows ? 'auto' : 'metadata'}
          onLoadedData={handleVideoLoad}
          style={getImageStyles(fullRadius)}
          src={media.url}
          {...rest}
        />
      )}
    </>
  )
})

// Componente Grid che passa l'indice
export const MediaGrid = ({ items }: { items: MediaProps[] }) => {
  return (
    <div className="grid grid-cols-3 gap-4">
      {items.map((item, index) => (
        <MediaImage key={index} {...item} index={index} />
      ))}
    </div>
  )
}
