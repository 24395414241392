/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import PostV2, { IResponseCalendarDayPosts } from 'api/PostV2'
import Utils from 'api/Utils'
import { Box, Icons } from 'components'
import { SpecificDayCard } from 'components/Calendar/Content/SpecificDay/SpecificDayCard'
import { editPost } from 'components/Modal/PreviewPostModal/iconConfig'
import { CardContainer, EmptyState, GridContainer } from 'components/UI'
import SkeletonCard from 'pages/ai-discover/UI/SkeletonCard'
import { useEffect, useRef, useState } from 'react'
import { useDraftStore } from 'stores/DraftStore'
import { T } from 'utils'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {}

export const DraftCardsContent = ({}: Props) => {
  /* ----------------- STATE  */
  const [totalPages, setTotalPages] = useState<number>(1)
  const [page, setPage] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [canLoadMore, setCanLoadMore] = useState<boolean>()

  /* ----------------- REF  */
  const loaderRef = useRef<HTMLDivElement>(null)

  /* ----------------- ZUSTAND  */
  const { update, labelType, labels, socials, selectedPostsId, draftPosts, createdFrom, contentType } = useDraftStore(
    (s) => ({
      update: s.update,
      labelType: s.labelType,
      labels: s.labels,
      socials: s.socials,
      selectedPostsId: s.selectedPostsId,
      draftPosts: s.draftPosts,
      createdFrom: s.createdFrom,
      contentType: s.contentType,
    })
  )

  const { setSelectedPostsId } = useDraftStore()

  /* ----------------- VARIABLES  */

  // Cards to render
  const postData =
    draftPosts?.map((post) => (
      <CardContainer
        key={post._id}
        content={
          <SpecificDayCard
            post={post}
            action={() => onClickCard(post._id)}
            someSelected={selectedPostsId.length > 0}
            from="drafts"
          />
        }
        isSelectable
        isSelected={selectedPostsId.includes(post._id)}
        action={() => handleSelectId(post._id)}
      />
    )) ?? []

  let updatedPage = page

  /* ----------------- METHODS  */
  /**
   * Funzione che gestisce il click sulla card
   * Se ci sono card selezionate, esegue l'azione passata come prop
   * Altrimenti apre il modal di preview del post
   */
  const onClickCard = async (postId: string) => {
    try {
      if (selectedPostsId.length > 0) {
        handleSelectId(postId)
      } else {
        const res = await PostV2.getSinglePost(postId)

        editPost(res.data!)
      }
    } catch (e) {
      console.error(e)
    }
  }

  /**
   * Funzione per selezionare / deselezionare un post
   * @param id id del post
   */
  const handleSelectId = (id: string) => {
    if (selectedPostsId.includes(id)) {
      setSelectedPostsId(selectedPostsId.filter((postId) => postId !== id))
    } else {
      setSelectedPostsId([...selectedPostsId, id])
    }
  }

  /**
   * Funzione per resettare la pagina e i post
   */
  const resetPageAndPosts = () => {
    setPage(0)
    updatedPage = 0

    useDraftStore.getState().setDraftPosts([])
    // updatedPostList = new Set()

    setLoading(true)
  }

  /* ----------------- API CALL  */
  const getDayPostsWithFilters = async () => {
    if (page > totalPages) return
    setLoading(true)

    const labelParam =
      labels.length === 0
        ? undefined
        : {
            type: labelType,
            labels: labels.map((label) => label._id),
          }

    const params = {
      page: updatedPage,
      filters: {
        social: socials,
        labels: labelParam,
        createdFrom: createdFrom,
        contentType: contentType,
      },
    }

    try {
      const res = await PostV2.getDrafts(params)
      const { posts } = res.data!
      setTotalPages(res.data!.totalPages)
      const currentPosts = useDraftStore.getState().draftPosts
      useDraftStore.getState().setDraftPosts([...currentPosts, ...posts])
    } catch (e) {
      console.error(e)
    }

    setLoading(false)
  }

  /* ----------------- USEEFFECT  */

  // UseEffect per fetchare i post al cambio dei filtri
  useEffect(() => {
    resetPageAndPosts()
    getDayPostsWithFilters()
  }, [update, socials, labelType, contentType, createdFrom])

  // UseEffect per fetchare i dati delle nuove pagine
  useEffect(() => {
    getDayPostsWithFilters()
  }, [page])

  const onScroll = (e: any) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
    if (bottom && !loading && page <= totalPages) setPage(page + 1)
  }

  return (
    <DraftCardsContentContainer>
      <InnerContainer id="draft-scroll" onScroll={onScroll}>
        {/* Empty state */}
        {!canLoadMore && !loading && draftPosts.length === 0 && (
          <EmptyState
            text={T.draftModal.noDraft}
            icon={<Icons.Preview width={72} height={72} />}
            fill={true}
            noPaddingTop
            styles={{ width: '403px', height: '257px' }}
          />
        )}

        {/* Elements */}
        {draftPosts.length > 0 && (
          <GridContainer data={postData} cols={3} colGap={24} rowGap={32} styles={{ marginBottom: '24px' }} />
        )}

        {/* OBSERVER */}
        {draftPosts.length !== 0 && <div ref={loaderRef} />}

        {/* Loading skeleton */}
        {(canLoadMore || loading) && (
          <LoadingContainer>
            <SkeletonCard key={Utils.generateRandomUIDKey()} num={18} type="calendarCard" />
          </LoadingContainer>
        )}
      </InnerContainer>
    </DraftCardsContentContainer>
  )
}

const DraftCardsContentContainer = styled(Box)`
  width: 100%;
  height: 100%;
  flex: 1;
  background-color: transparent;
`

const InnerContainer = styled(Box)`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-right: 10px;
  padding-bottom: 24px;
`

const LoadingContainer = styled(Box)`
  display: grid;
  align-self: 'center';
  gap: 24px;
  grid-column-gap: 24px;
  grid-row-gap: 32px;
  grid-template-columns: repeat(3, 1fr);
`
