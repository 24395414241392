import PostPageAnimation from 'components/Animations/PostPageAnimation'
import { Box } from 'components/Box'
import gsap from 'gsap'
import { useEffect, useRef, useState } from 'react'
import { GridContextProvider, GridDropZone, GridItem, move, swap } from 'react-grid-dnd'
import { updateActive } from 'utils/PostUtils/Post'
import './Drag.css'
import PostImg from './PostImg'

export default function DragDropImg({ AllMedia, onChangeMedia }) {
  const [items, setItems] = useState({
    left: [{ position: 0, component: <></> }],
  })

  const itemRef = useRef(items)
  const allMediaRef = useRef(AllMedia)

  const onChangeItemLegth = (length) => {
    if (length === 0) {
      // gsap.to('#zeroMediaLoaded', {
      //   opacity: 1,
      //   display: 'flex',
      // })
    } else if (length <= 3) {
      PostPageAnimation.reduceHeight('media-container-scroll', 330, 'hidden')
    } else if (length <= 6) {
      PostPageAnimation.reduceHeight('media-container-scroll', 660, 'hidden')
    } else {
      PostPageAnimation.reduceHeight('media-container-scroll', 950, 'auto')
    }
  }

  useEffect(() => {
    const item: any = { left: [] }

    AllMedia.forEach((element, index) => {
      item.left.push({
        position: index,
        component: (
          //------------------------------ qui ci va il componente di post img
          <PostImg
            onChangeMedia={onChangeMedia}
            onDelete={() => {
              const itemsPlace: any = { left: [] }
              itemsPlace.left = itemRef.current.left.filter((el) => {
                if (el.position !== index) {
                  return el
                }
              })
              setItems(itemsPlace)
              onChangeItemLegth(itemsPlace.left.length)

              const newMedias = itemRef.current.left
                .filter((el) => {
                  if (el.position !== index) {
                    return el
                  }
                })
                .map((media) => {
                  return media.component.props.media
                })

              updateActive({ medias: newMedias })
              onChangeMedia(undefined)
              
            }}
            media={element}
            index={index}
          />
        ),
      })
    })

    setItems(item)
    itemRef.current = items
    allMediaRef.current = AllMedia
    onChangeItemLegth(item.left.length)
  }, [AllMedia.length])

  useEffect(() => {
    itemRef.current = items
  }, [items])

  useEffect(() => {
    return () => {
      try {
        const newOrder = []
        itemRef.current.left.forEach((el) => {
          // @ts-ignore
          newOrder.push(allMediaRef.current[el.position])
        })

        if (JSON.stringify(newOrder) !== JSON.stringify(AllMedia)) updateActive({ medias: newOrder })
      } catch (e) {
        console.error(e)
      }
    }
  }, [])

  function onChange(sourceId, sourceIndex, targetIndex, targetId) {
    if (sourceIndex < AllMedia.length && targetIndex < AllMedia.length) {
      if (targetId) {
        const result = move(items[sourceId], items[targetId], sourceIndex, targetIndex)

        setItems({
          ...items,
          [sourceId]: result[0],
          [targetId]: result[1],
        })
      }

      const result = swap(items[sourceId], sourceIndex, targetIndex)
      return setItems({
        ...items,
        [sourceId]: result,
      })
    }
  }

  const zoomP = 1920 / window.innerWidth

  return (
    <GridContextProvider onChange={onChange}>
      <div className="container" style={{ width: 800 * zoomP, marginLeft: '8px' }}>
        <GridDropZone
          className="dropzone left"
          id="left"
          boxesPerRow={3}
          rowHeight={300}
          style={{
            height: AllMedia.length > 0 ? '1000px' : 0,
          }}
        >
          {items.left.map((item, index) => (
            <GridItem key={item.position}>
              <Box className="grid-item">
                <Box className="grid-item-content">{item.component}</Box>
              </Box>
            </GridItem>
          ))}
        </GridDropZone>
      </div>
    </GridContextProvider>
  )
}
