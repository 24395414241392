import { Tracker } from 'api/Tracker'
import Utils from 'api/Utils'
import { AnimatedPlaceholderLogo, Box, Button } from 'components'
import ChangeWorkspaceModal from 'components/ChangeWorkspaceModal'
import { Tagging } from 'components/CreatePost/ExtraComponents/InstagramExtras/TagingModal'
import { PhotoEditorSDK } from 'components/CreatePost/MediaComponents/DragDrop/PhotoEditorSDK'
import Emoji from 'components/CreatePost/PreviewComponents/BasicComponents/BasicContentComponents/GenericComponents/TextInfo/Emoji'
import { UtmModal } from 'components/CreatePost/PreviewComponents/BasicComponents/BasicContentComponents/GenericComponents/Utm/UtmModal'
import { FeedbackGenerationModal } from 'components/CreatePost/TextComponents/AiGeneric/FeedbackGenerationModal'
import { Template } from 'components/CreatePost/TextComponents/Template/Template'
import { ConfirmModal, LanguageBox } from 'components/UI'
import ManageContentModal from 'pages/ai-discover/DiscoverAside/ManageContentModal'
import { TSidebarType } from 'pages/ai-discover/aiDiscoverInterface'
import CustomMediaModal from 'pages/media/CustomMediaModal'
import { MediaTemplateModal } from 'pages/media/Editor/MediaTemplateModal'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { CrelloResponse, Media } from 'types'
import { IPostData, IPostVariant, IUrl } from 'types/PostInterface'
import { getEditorData, TRACKEREVENTS } from 'utils/eventsTracker'
import { MixpanelEditorData } from 'utils/eventsTracker/editor/editorInterfaces'
import AutopilotGenerateKeyModal from './AutopilotGenerateKey/AutopilotGenerateKeyModal'
import { DraftModal } from './DraftModal/DraftModal'
import { ManageLabels } from './ManageLabels/ManageLabels'
import CreateHashtagGroup from './Post/CreateHashtagGroupModal'
import { PostMediaModel } from './Post/PostMediaModel'
import { PostPlanning } from './Post/PostPlanning'
import ZoomedModal from './Post/ZoomedModal'
import { PreviewPostModal } from './PreviewPostModal/PreviewPostModal'
import { RenameRoleModal } from './RenameRoleModal'
import { AppStore, showInfo, T, openVistaCreate, CrelloType } from 'utils'

export const openZoomedModal = (item, index) =>
  AppStore.openModal({
    id: 'zoom',
    body: <ZoomedModal index={index} item={item} />,
    width: '1000px',
  })

export const openPiningModal = (variantId: string) => {
  AppStore.openModal({
    id: 'preview-modal',
    body: <Tagging variantId={variantId} />,
    style: {
      minHeight: 800,
      minWidth: 800,
    },
  })
}

// Modale per la selezione del formato del editor
export const openEditor = async (selectedMedia?: any, onCrelloSave?: (crelloResponse: CrelloResponse) => void) => {
  try {
    const check = await AppStore.checkPermission('manage_media')
    if (!check) return showInfo(`${T.error.noPermissionTo} ${T.error.manageFolders}`)

    // console.log('open editor', selectedMedia)
    AppStore.openModal({
      width: 1000,
      id: 'media-template',
      body: (
        <MediaTemplateModal
          selectedMedia={selectedMedia}
          onTemplateClick={(template) => {
            AppStore.closeAllModal()
            AppStore.reloadRouting()
            setTimeout(() => {
              if (template.designType === 'custom') return openCustomMediaCreate()
              openCrello(template, selectedMedia, onCrelloSave, CrelloType.DESIGN)
            }, 100)
          }}
          onCrelloSave={onCrelloSave}
        />
      ),
    })
  } catch (e) {
    console.error(e)
  }
}

// Editor modal
export const openCrello = (template, selectedMedia, onCrelloSave, crelloType) => {
  const { width, height, measureUnits } = template.customDimensions ?? { width: 0, height: 0, measureUnits: 'px' }

  // console.log('open crello', template, selectedMedia)
  //console.log('open crello', template, width, height, measureUnits)
  const graphicEditorData: MixpanelEditorData = {
    designType: template.designType,
    width: width,
    height: height,
    measureUnits: measureUnits,
  }

  Tracker.trackEvent(TRACKEREVENTS.GRAPHIC_EDITOR_OPENED, getEditorData(graphicEditorData))

  AppStore.openModal({
    id: 'crello-loader',
    closeIcon: false,
    modal: (
      <div
        style={{
          minWidth: 1000,
          minHeight: 400,
          position: 'fixed',
          backgroundColor: 'rgba(0, 0, 0, 0.45)',
          inset: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 9,
        }}
      >
        <AnimatedPlaceholderLogo
          containerStyle={{ backgroundColor: 'transparent' }}
          style={{ height: '10vh', width: '10vw' }}
        />
        <Button style={{ marginBottom: 20 }} onClick={() => AppStore.closeModal('crello-loader')}>
          {'Cancel'}
        </Button>
      </div>
    ),
  })

  openVistaCreate(onCrelloSave, {
    sidebarDesignType: template.designType,
    customDimensions: template.customDimensions,
    customTemplateId: template.templateId ?? template.customTemplateId ?? null,
    crelloType,
    user: {
      //  email: AppStore.loggedUser.email,
      externalUserId: AppStore.loggedUser._id.toString(),
    },
    image: selectedMedia?.[0]?.url ?? template.image ?? '',
  })
}

export const openCustomMediaCreate = () => {
  // console.log('open custom media create')
  setTimeout(() => {
    AppStore.openModal({
      width: 500,
      id: 'custom-media-create',
      body: (
        <CustomMediaModal
          onConfirm={(width, height) => {
            AppStore.closeModal('custom-media-create')
            openCrello(
              {
                designType: 'instagramSM',
                customDimensions: { width, height, measureUnits: 'px' },
              },
              [],
              () => {},
              CrelloType.DESIGN
            )
          }}
        />
      ),
    })
  }, 300)
}

export const openMediaModal = ({
  onMediaSelected,
  selectedMedias,
  context,
  closeOnlyMedia,
}: {
  onMediaSelected: (medias: Media[]) => void
  selectedMedias: Media[]
  context?: string
  closeOnlyMedia?: boolean
}) => {
  AppStore.openModal({
    width: 1700,
    id: 'media-modal-selector',
    body: (
      <PostMediaModel
        onMediaSelected={(medias: Media[]) => onMediaSelected(medias)}
        selectedMedias={selectedMedias}
        context={context}
        closeOnlyMedia={closeOnlyMedia}
      />
    ),
    style: {
      backgroundColor: 'transparent',
      minWidth: 1600,
      minHeight: 550,
    },
  })
}

//---------------

export const CreateHashtagGroupModal = () => {
  AppStore.openModal({
    id: 'createGroup',
    body: <CreateHashtagGroup />,
    width: '787',
  })
}

// Modale delle lingue
export const openLanguageSelectModal = (outputLanguage, setOutputLanguage) =>
  AppStore.openModal({
    id: 'language-modal',
    body: (
      <Box width={'auto'} height={500} flex p={32}>
        <LanguageBox InputOutput={false} onClick={(e) => setOutputLanguage(e)} language={outputLanguage} />
      </Box>
    ),
    width: '1210',
  })

// Modale delle lingue
export const openTemplateModal = (variantId: string) =>
  AppStore.openModal({
    id: 'add-template-to-extra',
    body: <Template variantId={variantId} />,
    style: {
      minWidth: 930,
      maxHeight: 700,
    },
  })

// Modale delle thumbnail
export const openThumbnailConfirmModal = (onConfirm, onCancel?) => {
  AppStore.openModal({
    id: 'preview-modal',
    body: (
      <ConfirmModal
        text={
          <p>
            {T.postPage.youtubeTubeExtra.thumbnailConfirm1} <br />
            <span style={{ marginRight: 5, marginLeft: 5 }} />
            <br />
            {T.postPage.youtubeTubeExtra.thumbnailConfirm2}
          </p>
        }
        cancelText={T.postPage.cancel}
        confirmText={T.settings.confirm}
        onClickCancel={() => {
          onCancel()
          AppStore.closeModal('preview-modal')
        }}
        onClickConfirm={async () => {
          await onConfirm()
          AppStore.closeModal('preview-modal')
        }}
      />
    ),
    style: {
      minHeight: 300,
      minWidth: 700,
      alignContent: 'center',
    },
  })
}

// Modale emoji
export const openEmojidModal = (identifier: string) => {
  const { activePost } = useCreatePostStore.getState()

  AppStore.openModal({
    id: 'preview-modal',
    body: <Emoji identifier={identifier} post={activePost} />,
    style: {
      minHeight: 100,
    },
  })
}

// Planning post modal
export const openShowPlanningOptions = () => {
  AppStore.openModal({
    id: 'preview-modal',
    body: <PostPlanning />,
    style: {
      minWidth: 644,
      maxHeight: 744,
      backgroundColor: 'transparent',
    },
  })
}

// Modale UTM
export const openUTMModal = (
  post: IPostData | IPostVariant,
  visibleLink: IUrl,
  firstComment?: boolean,
  isDescription?: boolean
) => {
  AppStore.openModal({
    id: 'utm-modal',
    body: <UtmModal post={post} visibleLink={visibleLink} firstComment={firstComment} isDescription={isDescription} />,
    style: {
      minHeight: 300,
      minWidth: 800,
    },
  })
}

// Modale per il feedback delle generazioni di testo
export const openFeedbackModal = (isFeedbackPositive: boolean) => {
  AppStore.openModal({
    id: 'generation-feedback-modal',
    body: <FeedbackGenerationModal isFeedbackPositive={isFeedbackPositive} />,
    style: {
      minWidth: 787,
    },
  })
}

/* ----------------- AUTOPILOT MODAL ----------------- */

export const onOpenAutopilotGenerateKeyModal = (setterStateBol: () => void) => {
  return AppStore.openModal({
    id: 'autopilot-modal',
    body: <AutopilotGenerateKeyModal setterStateBol={setterStateBol} />,
    style: {
      minWidth: 671,
      minHeight: 309,
    },
  })
}

// MANAGE LABELS MODAL
export const openManageLabelModal = (refreshLabels: () => void) => {
  AppStore.openModal({
    id: 'manage-labels',
    body: <ManageLabels />,
    onCancel: () => refreshLabels(),
  })
}

// PREVIEW MODAL
export const openPreviewModal = (postId: string, removePostId: () => void) => {
  AppStore.openModal({
    id: 'preview-post-modal',
    body: <PreviewPostModal postId={postId} />,
    onCancel: removePostId,
    style: {
      width: 932,
      minWidth: 932,
    },
  })
}

// MODALE BOZZE
export const openDraftModal = async () => {
  const check = await AppStore.checkPermission('manage_draft')
  if (!check) return showInfo(`${T.error.noPermissionTo} ${T.error.manageDrafts}`)

  return AppStore.openModal({
    id: 'draft-modal',
    style: { minWidth: 1603, minHeight: 893 },
    body: <DraftModal />,
  })
}

// MANAGE BOARDS FOLLOW
export const openManageDiscoverModal = async (sidebarType: TSidebarType) => {
  const check = await AppStore.checkPermission('manage_discover')
  if (!check) return showInfo(`${T.error.noPermissionTo} ${T.error.manageDiscover}`)

  return AppStore.openModal({
    id: 'manage-content-modal',
    body: <ManageContentModal key={Utils.generateRandomUIDKey()} sidebarType={sidebarType} />,
    style: {
      maxWidth: 430,
    },
  })
}

export const openRenameModal = (setRoleName: (name) => void) =>
  AppStore.openModal({
    id: 'rename-role-modal',
    body: (
      <RenameRoleModal
        onConfirm={(name: string) => {
          setRoleName(name ?? 'role name')
        }}
      />
    ),
  })

export const requestConfirmDialog = (title: any, onConfirm: () => void) => {
  AppStore.openModal({
    id: 'preview-modal',
    body: (
      <ConfirmModal
        text={title}
        cancelText={T.postPage.cancel}
        confirmText={T.settings.confirm}
        onClickCancel={() => {
          AppStore.closeModal('preview-modal')
        }}
        onClickConfirm={async () => {
          await onConfirm()
          AppStore.closeModal('preview-modal')
        }}
      />
    ),
    style: {
      minHeight: 300,
      minWidth: 700,
      alignContent: 'center',
    },
  })
}

export const changeWorkspaceModal = (account, handleForceUpdate) => {
  AppStore.openModal({
    id: 'preview-modal',
    body: <ChangeWorkspaceModal socialAccount={account} handleForceUpdate={handleForceUpdate} />,
    style: {
      minHeight: 765,
      maxWidth: 470,
    },
  })
}

export const openConfirmModalSocial = (confirm) => {
  AppStore.openModal({
    id: 'preview-modal',
    body: (
      <ConfirmModal
        text={
          <p>
            {T.settings.popEnvConfirmAccount} <br /> {T.settings.popEnvConfirm2}
            <span style={{ marginRight: 5, marginLeft: 5 }} />
            {T.settings.popEnvConfirm3}
            <br />
            {T.settings.popEnvConfirm4}
          </p>
        }
        cancelText={T.postPage.cancel}
        confirmText={T.settings.confirm}
        onClickCancel={() => {
          AppStore.closeModal('preview-modal')
        }}
        onClickConfirm={async () => {
          await confirm()
          AppStore.closeModal('preview-modal')
        }}
      />
    ),
    style: {
      minHeight: 300,
      minWidth: 700,
      alignContent: 'center',
    },
  })
}

/**
 * Open the partners modal
 */
export const openPartnersModal = () => {
  AppStore.openModal({
    id: 'preview-modal',
    body: (
      <Box
        bc={AppStore.theme.o.surface}
        style={{
          minWidth: 1603,
          minHeight: 800,
        }}
      >
        <iframe
          src={`https://partners.asters.ai/${AppStore.darkTheme ? 'dark' : 'light'}`}
          style={{
            width: 1603,
            height: 800,
            border: 'none',
            borderRadius: 14,
          }}
        />
      </Box>
    ),
    style: { minWidth: 1603, minHeight: 800 },
  })
}

/**
 * Open the crop modal
 */
export const openCropModal = async (media: Media, onChangeMedia: (media: Media) => void) => {
  AppStore.openModal({
    id: 'preview-modal',

    body: (
      <Box style={{ padding: 24 }} center>
        <PhotoEditorSDK media={media} onChangeMedias={onChangeMedia} />,
      </Box>
    ),
    style: {
      minHeight: 700,
      minWidth: 1600,
      backgroundColor: 'transparent',
    },
  })
}

/**
 *
 */
export const openConfirmModal = ({ text, onConfirm, confirmText, cancelText }) => {
  AppStore.openModal({
    id: 'preview-modal',
    body: (
      <ConfirmModal
        text={text}
        confirmText={confirmText}
        cancelText={cancelText}
        onClickCancel={() => AppStore.closeModal('preview-modal')}
        onClickConfirm={async () => {
          await onConfirm()
          AppStore.closeModal('preview-modal')
        }}
      />
    ),

    style: {
      minHeight: 250,
      minWidth: 700,
      alignContent: 'center',
    },
  })
}
