/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { openUTMModal } from 'components/Modal/ModalManager'
import { truncateText } from 'pages/ai-discover/commonFunction'
import { useEffect, useState } from 'react'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { IUrl } from 'types/PostInterface'
import { AppStore, T } from 'utils'
import { getActiveText, updateActive, updatePost } from 'utils/PostUtils/Post'
import {
  converUtmConfig,
  findWorkingUTM,
  getLinkProperties,
  newUtmUrl,
  setShortenedLink,
} from 'utils/PostUtils/ShortUrl'

/* ------------------- INTERFACES  */
interface Props {
  post: any
  firstComment?: boolean
  isDescription?: boolean
}

export const Utm = ({ post, firstComment, isDescription }: Props) => {
  /* ----------------- VARIABLES  */
  const firstCommentParameter = firstComment ? post : undefined
  const { activePost } = useCreatePostStore((s) => ({
    activePost: s.activePost as any,
  }))

  /* ----------------- STATE  */
  const [selectedIndex, setSelectedIndex] = useState(getLinkProperties(firstCommentParameter)!.length - 1)
  // @ts-ignore
  const [visibleLink, setVisibleLink]: IUrl = useState(findWorkingUTM(activePost, selectedIndex, firstComment))
  const [dropdownVisible, setDropdownVisible] = useState(false)

  /* ----------------- USEEFFECT  */
  // Cambia visible link in base al l'index selezionato

  useEffect(() => {
    setVisibleLink(findWorkingUTM(activePost, selectedIndex, firstComment))
  }, [selectedIndex, activePost])

  // Setta l'ultimo link come default
  useEffect(() => {
    setSelectedIndex(getLinkProperties(firstCommentParameter)!.length - 1)
  }, [getLinkProperties(firstCommentParameter)!.length])

  // Quando inserisco utmConfigs vengano aggiornati nel text
  useEffect(() => {
    if (visibleLink && !visibleLink?.shortened) {
      if (firstComment === undefined) {
        const actualLinkValue = visibleLink.shortened ? visibleLink?.shortUrl : visibleLink.originalUrl
        const newText = getActiveText().replace(actualLinkValue, newUtmUrl(visibleLink, converUtmConfig(visibleLink)))
        updateActive({ text: newText })
      }

      if (firstComment !== undefined) {
        const actualLinkValue = visibleLink.shortened ? visibleLink?.shortUrl : visibleLink.originalUrl

        const newText = activePost?.data?.firstComment.text.replace(
          actualLinkValue,
          newUtmUrl(visibleLink, converUtmConfig(visibleLink))
        )

        updatePost(activePost.variantId, {
          firstComment: {
            ...activePost?.data?.firstComment,
            text: newText,
          },
        })
      }
    }
  }, [visibleLink?.utmConfig])

  const removeDuplicatedUrls = (urls: IUrl[]) => {
    // check urls of the active post and remove the duplicated ones

    const uniqueUrls = urls.filter((url, index) => {
      return urls.findIndex((u) => u.originalUrl === url.originalUrl) === index
    })

    return uniqueUrls
  }

  // remove duplicated links
  const urlsToChecks = removeDuplicatedUrls(getLinkProperties(firstCommentParameter) ?? [])
  // const urlsToChecks = getLinkProperties(firstCommentParameter)?.filter((url, index) => index !== selectedIndex) ?? []

  return (
    <UtmContainer>
      {/* Scelta del link */}
      <ButtonContainer
        key={`button-${visibleLink?.originalUrl}`}
        onClick={() => {
          setDropdownVisible((prev) => !prev)
        }}
        style={
          dropdownVisible
            ? { background: AppStore.theme.o.lightBlue, color: AppStore.theme.o.blue }
            : { background: AppStore.theme.o.lightestGrey, color: AppStore.theme.o.black }
        }
      >
        {truncateText(visibleLink?.originalUrl ?? visibleLink?.shortUrl, 20)}

        <DropDownContainer
          style={{
            display: dropdownVisible ? 'flex' : 'none',
          }}
        >
          {urlsToChecks.map((url, index) => (
            <LinkContainer
              style={{ color: selectedIndex === index ? AppStore.theme.o.blue : AppStore.theme.o.black }}
              onClick={() => {
                setSelectedIndex(index)
                setDropdownVisible(true)
              }}
            >
              {newUtmUrl(url, converUtmConfig(url) ?? {})}
            </LinkContainer>
          ))}
        </DropDownContainer>
      </ButtonContainer>

      {/* Accorcia / Espande link */}
      {AppStore.shortenAvailable && (
        <>
          {visibleLink?.shortened && (
            <ButtonContainer
              onClick={() => setShortenedLink(activePost, false, visibleLink, firstComment, isDescription)}
            >
              {T.postPage.showFullLink} <Icons.expand fill={AppStore.theme.o.black} />
            </ButtonContainer>
          )}
          {!visibleLink?.shortened && (
            <ButtonContainer
              onClick={() => setShortenedLink(activePost, true, visibleLink, firstComment, isDescription)}
            >
              {T.postPage.shortFullLink} <Icons.collapse fill={AppStore.theme.o.black} />
            </ButtonContainer>
          )}
        </>
      )}

      {/* Apre modale UTM */}
      <ButtonContainer onClick={() => openUTMModal(activePost, visibleLink, firstComment, isDescription)}>
        UTM <Icons.utm fill={AppStore.theme.o.black} />
      </ButtonContainer>
    </UtmContainer>
  )
}

const UtmContainer = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  height: 56px;
  width: 100%;
  border-radius: 14px;
  border: 1px solid ${() => AppStore.theme.o.lightGrey};
  background: ${() => AppStore.theme.o.surface};
`

const ButtonContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 4px 14px;
  gap: 6px;
  border-radius: 12px;
  height: 40px;
  color: ${() => AppStore.theme.o.black};
  text-overflow: ellipsis;
  font-family: Aktiv Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  &:hover {
    background: ${() => AppStore.theme.o.lightestGrey};
  }
`

const DropDownContainer = styled(Box)`
  position: absolute;
  bottom: 62px;
  left: 50%;
  transform: translateX(-50%);
  width: 98%;
  border-radius: 14px;
  background: ${() => AppStore.theme.o.lightGrey};
  border: 1px solid ${() => AppStore.theme.o.lightestGrey};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  gap: 4px;
`

const LinkContainer = styled(Box)`
  font-family: Aktiv Grotesk;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 12px;
  word-wrap: break-word;

  color: ${() => AppStore.theme.o.darkGrey};
  background-color: ${() => AppStore.theme.o.lightGrey};

  &:hover {
    background-color: ${() => AppStore.theme.o.surface};
  }
`
