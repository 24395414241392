/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'

/* ------------------- INTERFACES  */
interface Props {
  imgSrc: string
}

export const TiktokAssets = ({ imgSrc }: Props) => {
  return (
    <TiktokAssetsContainer>
      <AllTopIcons />
      <AddTiktok />
      <MinorInfo />

      <ProfileImg style={{ zIndex: 1 }} src={imgSrc} />
      {imgSrc && <SpinningMusic style={{ zIndex: 20 }} src={imgSrc} />}
    </TiktokAssetsContainer>
  )
}

const TiktokAssetsContainer = styled(Box)``

const AllTopIcons = styled((props) => <Icons.tiktokAllTopIcons {...props} />)`
  position: absolute;
  top: 45px;
  z-index: 20;
`

const AddTiktok = styled((props) => <Icons.tiktokAdd {...props} />)`
  z-index: 2;
  bottom: 20.2px;
  right: 11.43px;
  position: absolute;
`

const MinorInfo = styled((props) => <Icons.tiktokMinorInfo {...props} />)`
  z-index: 2;
  bottom: 20.2px;
  left: 11.43px;
  position: absolute;
`

const ProfileImg = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  z-index: 0;
  position: absolute;
  bottom: 279.64px;
  right: 12.3px;
`

const SpinningMusic = styled.img`
  width: 18.2px;
  height: 18.2px;
  border-radius: 50%;
  z-index: 0;
  position: absolute;
  bottom: 26.29px;
  right: 20.88px;
`
