/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { openEmojidModal } from 'components/Modal/ModalManager'
import { useEffect, useRef, useState } from 'react'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { AppStore, T } from 'utils'
import { getActiveDescription, getActiveText, updateActive } from 'utils/PostUtils/Post'
import { canShowMetadata, getLinkProperties, manageGlobalLinkUpdate, onPasteLink } from 'utils/PostUtils/ShortUrl'
import { Metadata } from './Metadata/Metadata'
import { TextPostInfo } from './TextInfo/TextPostInfo'
import { Utm } from './Utm/Utm'
import { is } from 'date-fns/locale'

/* ------------------- INTERFACES  */
interface Props {}

export const TextContainer = ({}: Props) => {
  /* ----------------- ZUSTAND  */
  const { activePost, posts, textLoading } = useCreatePostStore((s) => ({
    activePost: s.activePost as any,
    posts: s.posts,
    textLoading: s.textLoading,
  }))

  /* ----------------- REF  */
  const inputRef = useRef<any>(null)

  const [forceUpdate, setForceUpdate] = useState(false)

  /* ----------------- VARIBLES  */
  const showMetadata = canShowMetadata()

  const showUtm = getLinkProperties()!.length > 0

  const isYoutube = activePost && activePost?.info?.account?.provider === 'youtube'

  /* ----------------- METHODS  */
  // Calcola l'altezza del Textarea in base alle condizioni date
  const calculateTextareaHeight = () => {
    if (isYoutube) return '55px'
    else {
      // su figma le preview hanno altezze diverse, questa è messa perchè sembra la più bilanciata a livello di padding
      let height = 604
      if (showUtm && utmProviderCheck()) height -= 56
      if (showMetadata && utmProviderCheck()) height -= 160
      return `${height}px`
    }
  }

  const calculateTextareaHeightDescriptionYT = () => {
    let height = 545
    if (showUtm && utmProviderCheck()) height -= 56
    return `${height}px`
  }

  const utmProviderCheck = () => {
    const provider = activePost && activePost?.info?.account?.provider
    const bannedProviders = ['instagram', 'tiktok']
    return !bannedProviders.includes(provider)
  }

  const linkCheck = () => {
    return activePost?.data?.urls?.length > 0 || activePost?.urls?.length > 0
  }

  const onTextAreaChange = async (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setForceUpdate(!forceUpdate)
    updateActive({ text: e.target.value })
  }

  const verifyUrls = async (activeText: string, description: string): Promise<void> => {
    // extract urls from the text wit all the parameters
    useCreatePostStore.setState({ textLoading: true })

    const urlRegex =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g

    // Urls in the active text
    const contentUrls: string[] = description?.match(urlRegex) ?? []
    const updatedPosts = await manageGlobalLinkUpdate(contentUrls, posts, urlRegex)
    // Update the posts in the store
    useCreatePostStore.setState({ posts: updatedPosts })

    onPasteLink({ activePost, newText: description, finalText: activeText })

    useCreatePostStore.setState({ textLoading: false })
  }

  /* ----------------- USEEFFECT  */
  useEffect(() => {
    verifyUrls(getActiveText(), getActiveText())
  }, [posts.length, activePost?.info])

  const handlePasteText = async (event: any) => {
    event.preventDefault()
    const pastedText = event.clipboardData.getData('text')
    const start = inputRef?.current?.selectionStart
    const end = inputRef?.current?.selectionEnd

    const inputValue = getActiveText()
    const beforePaste = inputValue?.substring(0, start)
    const afterPaste = inputValue?.substring(end)

    const processedText = pastedText
    const newValue = beforePaste + processedText + afterPaste

    updateActive({ text: newValue })

    await verifyUrls(newValue, pastedText)
  }

  const onDescriptionChange = async (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const description = e.target.value
    const activeDescription = getActiveDescription() ?? ''
    if (description.length > activeDescription.length + 1)
      onPasteLink({ activePost, newText: description, isDescription: true })
    updateActive({ description })
  }

  return (
    <TextContainerContainer>
      {/* Textarea  generale */}
      <TextareaContainer
        style={{
          height: calculateTextareaHeight(),
        }}
      >
        {/* Titolo del post */}
        <TextAreaPost
          style={{ opacity: !textLoading ? 1 : 0.5 }}
          disabled={textLoading}
          ref={inputRef}
          id="text-area-create-post"
          value={isYoutube ? getActiveDescription() : getActiveText()}
          onChange={isYoutube ? onDescriptionChange : onTextAreaChange}
          onPaste={handlePasteText}
          placeholder={isYoutube ? T.postPage.insertHereTextYouTube : T.postPage.insertHereText}
        />

        {!isYoutube && <EmojiIcon onClick={() => openEmojidModal('text-area-create-post')} />}
      </TextareaContainer>

      {/* Textarea  descrizione youtube */}
      {isYoutube && (
        <TextareaContainer>
          <TextAreaPost
            id="text-area-create-post-description"
            style={{ height: calculateTextareaHeightDescriptionYT() }}
            value={getActiveText()}
            placeholder={T.postPage.changeDescriptionYouTube}
            onChange={onTextAreaChange}
            onPaste={handlePasteText}
          />
          <EmojiIcon onClick={() => openEmojidModal('text-area-create-post-description')} />
        </TextareaContainer>
      )}

      {/* Metadata */}
      {linkCheck() && showMetadata && utmProviderCheck() && <Metadata post={activePost} />}

      {/* UTM */}
      {linkCheck() && showUtm && utmProviderCheck() && <Utm post={activePost} isDescription={isYoutube} />}

      {/* Length text check, time to read, Text difficulty */}
      <TextPostInfo />
    </TextContainerContainer>
  )
}

const TextContainerContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  height: 100%;
  gap: 16px;
`

const TextareaContainer = styled(Box)`
  border: 1px solid ${() => AppStore.theme.o.grey};
  position: relative;
  border: none;
  width: 442px;
`

const EmojiIcon = styled((props) => <Icons.emojiIcons {...props} />)`
  width: 35px;
  height: 35px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  transition: 300ms ease-in-out;
  cursor: pointer;
  fill: ${AppStore.theme.o.darkGrey};

  :hover {
    fill: ${AppStore.theme.o.grey};
  }
`

const TextTitlePost = styled.input`
  width: 100%;
  height: 100%;
  padding: 16px;
  border-radius: 16px;
  resize: none;
  background: ${() => AppStore.theme.o.lightestGrey};
  transition: 300ms ease-in-out;
  color: ${() => AppStore.theme.o.black};
  font-weight: 600;
  border: 1px solid ${() => AppStore.theme.o.grey};
  box-sizing: border-box;

  &:hover,
  &:focus {
    background: ${() => AppStore.theme.o.lightGrey};
  }

  &::placeholder {
    font-weight: 600;
    color: ${() => AppStore.theme.o.darkGrey};
  }
`

const TextAreaPost = styled.textarea`
  width: 100%;
  height: 100%;
  padding: 16px;
  border-radius: 16px;
  scrollbar-width: none;
  resize: none;
  border: 1px solid ${() => AppStore.theme.o.grey};
  background: ${() => AppStore.theme.o.lightestGrey};
  transition: 300ms ease-in-out;
  color: ${() => AppStore.theme.o.black};
  font-weight: 600;

  &:hover,
  &:focus {
    background: ${() => AppStore.theme.o.lightGrey};
  }

  &::placeholder {
    font-weight: 600;
    color: ${() => AppStore.theme.o.darkGrey};
  }
`
