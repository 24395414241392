import PostHistory from 'api/PostHistory'
import { Tracker } from 'api/Tracker'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { Media, PostStatus } from 'types'
import {
  IPostData,
  IPostVariant,
  IUrl,
  IVariantInfo,
  IVariantSocial,
  TActiveMediaType,
  TPreviewPost,
} from 'types/PostInterface'
import { AppStore, T, showError, showInfo, showSuccess, showWarning } from 'utils'
import {
  OWNER_POST_INFO_PLANNED,
  OWNER_POST_LIMITS_AVAILABLE,
  OWNER_POST_LIMITS_INFINITE,
  OWNER_POST_LIMITS_USED,
} from 'utils/Constants/LimitUserConstants'
import {
  TRACKEREVENTS,
  getMixpanelPostDirectlyPublished,
  getMixpanelPostDraftData,
  getMixpanelPostScheduledData,
} from 'utils/eventsTracker'
import { AccountProblem, checkPostValidation } from './ValidationPost'
import { ILabel } from 'components/CreatePost/PreviewComponents/LabelsComponents/LabelsContentComponents/Label'
import { th } from 'date-fns/locale'
import { showMultipleProblems, showMultipleWarningsWithTitle } from 'utils/SuperUtils'

export const getSocialsAccount = (): IVariantSocial[] => {
  const { posts } = useCreatePostStore.getState()

  // Utilizziamo un Map per tracciare gli account_id già visti
  const uniqueAccountsMap = new Map<string, IPostVariant>()

  // Iteriamo attraverso i post e aggiungiamo gli account unici al Map
  for (const post of posts) {
    const accountId = post.info.account.account_id

    // Aggiungiamo il post al Map solo se non abbiamo già visto l'account_id
    if (!uniqueAccountsMap.has(accountId)) {
      uniqueAccountsMap.set(accountId, post)
    }
  }

  // Convertiamo i valori del Map in un array

  const filteredAccounts = Array.from(uniqueAccountsMap.values())

  const newFilteredAccounts = filteredAccounts.map((post) => {
    return post.info.account
  })

  return newFilteredAccounts
}

export const getPostByAccountId = (accountId?: string) => {
  const { posts, activeAccountId, globalPost } = useCreatePostStore.getState()
  if (!!accountId) return posts.find((post) => post.info.account.account_id === (accountId ?? activeAccountId))
  if (activeAccountId === 'GLOBAL') return globalPost
  return posts.find((post) => post.info.account.account_id === (accountId ?? activeAccountId))
}

export const getPostByAccountIdType = (accountId: string, type: string) => {
  const { posts, activeAccountId, globalPost } = useCreatePostStore.getState()

  // find post by account id and type
  return posts.find(
    (post) => post.info.account.account_id === (accountId ?? activeAccountId) && post.info.type === type
  )
}

export const getPreviewByVariant = (post: IPostData | IPostVariant, mediaType: TActiveMediaType): TPreviewPost => {
  if (post === undefined) return 'GLOBAL'

  if ('_id' in post && post._id === 'GLOBAL') return 'GLOBAL'

  if ('variantId' in post) {
    switch (post.info.account.provider) {
      case 'instagram':
        if (post.info.type === 'STANDARD') {
          if (mediaType === 'image') return 'INSTAGRAM_STANDARD'
          if (mediaType === 'video') return 'INSTAGRAM_REEL'
        }
        if (post.info.type === 'STORY') return 'INSTAGRAM_STORY'
        return 'INSTAGRAM_STANDARD'

      case 'youtube':
        if (post.info.type === 'STANDARD') return 'YOUTUBE_STANDARD'
        if (post.info.type === 'SHORT') return 'YOUTUBE_SHORT'
        return 'YOUTUBE_STANDARD'

      case 'facebook':
        return 'FACEBOOK'

      case 'x':
        return 'X'

      case 'linkedin':
        return 'LINKEDIN'

      case 'tiktok':
        return 'TIKTOK'
    }
  }

  return 'GLOBAL'
}

// -----------------------------------------------------------------

export const getSinglePostById = (variantId: string) => {
  const { posts } = useCreatePostStore.getState()

  // Find the specific post
  return posts.find((post) => post.variantId === variantId)
}

// -----------------------------------------------------------------
//unique gesture

export const setUnique = (variantId: string) => {
  const { globalPost, uniqueVariants, setUniqueVariants } = useCreatePostStore.getState()

  if (uniqueVariants.includes(variantId)) return

  const post = getSinglePostById(variantId)
  if (!post) return

  // Controlli dei parametri per definire se un post è unico o meno
  const textCheck = post.data.text === globalPost.text
  const mediaCheck = JSON.stringify(post.data.medias) === JSON.stringify(globalPost.medias)
  const labelCheck = JSON.stringify(post.data.labels) === JSON.stringify(globalPost.labels)

  // Se le condizione sono vere ( e quindi sono uguali al globale) allora non è unico
  if (textCheck && mediaCheck && labelCheck) return

  // Setta le varianti come uniche
  setUniqueVariants([...uniqueVariants, post.variantId])
}

export const removeUnique = (variantId: string) => {
  const { uniqueVariants, setUniqueVariants } = useCreatePostStore.getState()

  const newUniqueVariants = uniqueVariants.filter((id) => id !== variantId)

  setUniqueVariants(newUniqueVariants)
}

export const pullFromGlobal = () => {
  const { globalPost, activePost } = useCreatePostStore.getState()
  const active = activePost as IPostVariant

  const post = getSinglePostById(active.variantId)
  if (!post) return

  updateActive(globalPost)
  removeUnique(active.variantId)
}

export const isUnique = (variantId: string) => {
  const { uniqueVariants } = useCreatePostStore.getState()
  return uniqueVariants.includes(variantId)
}

export const updateAllNonUniquePosts = (newData: IPostData) => {
  const { posts, uniqueVariants } = useCreatePostStore.getState()

  // Loop through all the posts
  posts.forEach((post) => {
    if (!uniqueVariants.includes(post.variantId)) updatePost(post.variantId, newData)
  })
}

/**
 * Aggiorna il post specifico
 *
 * @param variantId Valore variant con la quale si identifica su quale post stiamo operando
 * @param newData Il valore da aggiornare nei "data" nel IPostVariant (es: {text: 'nuovo testo'})
 * @returns Setta la lista dei post aggiornati
 */
export const updatePost = (variantId: string, newData: any) => {
  const { posts, setPosts, setActivePost, activePost } = useCreatePostStore.getState()
  // Find the specific post to update
  let post = getSinglePostById(variantId)

  if (!post) return setPosts(posts)

  const updatedPostData: IPostData = { ...post.data, ...newData, _id: undefined }

  post = { ...post, data: updatedPostData }

  setUnique(variantId)

  const postIndex = posts.findIndex((p) => p.variantId === variantId)
  posts[postIndex] = post

  // Update the active if is the modified
  if ('variantId' in activePost && post.variantId === activePost.variantId) setActivePost(post)

  return setPosts(posts)
}

// questo aggiorna il global
export const updateGlobal = (newData: any) => {
  const { globalPost, setGlobalPost } = useCreatePostStore.getState()

  updateAllNonUniquePosts(newData)

  const updatedGlobalData = { ...globalPost, ...newData }

  setGlobalPost(updatedGlobalData)
}

// questo aggiorna il post attivo e anche il suo corrispondente nella lista dei post (se esiste)
// newData è un valore che si trova dentro IPostData
export const updateActive = (newData: any) => {
  //variabili
  const { activePost, setActivePost } = useCreatePostStore.getState()

  //aggiornamento
  let updatedActiveData: any = {}

  if ('_id' in activePost && activePost._id === 'GLOBAL') updatedActiveData = { ...activePost, ...newData }

  if ('variantId' in activePost)
    updatedActiveData = {
      ...activePost,
      data: {
        ...activePost.data,
        ...newData,
      },
    }

  setActivePost(updatedActiveData)

  // ---

  //aggiornamento effettivo del post
  if ('_id' in activePost && activePost._id === 'GLOBAL') return updateGlobal(newData)

  if ('variantId' in activePost) return updatePost(activePost.variantId, newData)
}

// -----------------------------------------------------------------

const checkIfDraftIsValid = (draft: IPostVariant): boolean => {
  const { data, info } = draft
  const { text, medias } = data

  if (text.length === 0 && medias.length === 0) return false

  return true
}

/**
 * Verifica se l'utente ha i permessi per pubblicare o fare altre azioni con il post
 * @param postStatus
 */
const publishCheckPermissions = async (postStatus: 'draft' | 'publish-now' | 'schedule') => {
  if (postStatus === 'publish-now' || postStatus === 'schedule') {
    const check = await AppStore.checkPermission('publish_post')
    if (!check) {
      showInfo(`${T.error.noPermissionTo} ${T.error.publishPosts}`)
      throw new Error('No permission to publish posts')
    }
  }

  if (postStatus === 'draft') {
    const check = await AppStore.checkPermission('manage_draft')
    if (!check) {
      showInfo(`${T.error.noPermissionTo} ${T.error.createDraft}`)
      throw new Error('No permission to create drafts')
    }
  }
}

/**
 * Verifica se il post è valido
 * @param posts
 * @param post
 * @param postStatus
 * @returns
 */
const publishPostValidation = (
  posts: IPostVariant[],
  post: IPostVariant,
  postStatus: 'draft' | 'publish-now' | 'schedule'
) => {
  let checkDraft = true
  const allChecksPost: AccountProblem[] = []
  if (postStatus === 'draft') {
    post.data.status = postStatus
    checkDraft = checkIfDraftIsValid(post)
    Tracker.trackEvent(TRACKEREVENTS.POST_DRAFT_SAVED, getMixpanelPostDraftData(posts, post))
  }

  if (postStatus === 'publish-now' || postStatus === 'schedule') {
    const problems = checkPostValidation(post)
    allChecksPost.push(...problems)
    postStatus === 'publish-now'
      ? Tracker.trackEvent(TRACKEREVENTS.POST_DIRECTLY_PUBLISHED, getMixpanelPostDirectlyPublished(posts, post))
      : Tracker.trackEvent(TRACKEREVENTS.POST_SCHEDULED, getMixpanelPostScheduledData(posts, post))
  }
  return { allChecksPost, checkDraft }
}

// -----------------------------------------------------------------
// Actions
// -----------------------------------------------------------------

const doSchedule = async (allChecksPost: AccountProblem[]) => {
  if (allChecksPost.length > 0) {
    showMultipleProblems(allChecksPost)
    return
  }
  await PostHistory.publishV2()

  //? Notifica l'utente che il post potrebbe fallire a causa del limite di post
  if (!OWNER_POST_LIMITS_INFINITE && OWNER_POST_INFO_PLANNED + OWNER_POST_LIMITS_USED >= OWNER_POST_LIMITS_AVAILABLE) {
    showInfo(
      T.limitUser.warning_reaching_max_post(
        OWNER_POST_INFO_PLANNED,
        OWNER_POST_LIMITS_USED,
        OWNER_POST_LIMITS_AVAILABLE
      )
    )
  }

  showSuccess(T.success.postPlanned)
  resetPage()
  // if (allChecksPost.length > 0) {
  // }
  // if (allChecksPost.length > 0) showError(T.error.verify_that_all_posting_requirements_are_correct)
}

const doDraft = async (checkDraft: boolean) => {
  if (checkDraft) {
    await PostHistory.publishV2()
    showSuccess(T.success.postSavedInDraft)
    resetPage()
  }
  if (!checkDraft) showError(T.error.emptyDraft)
}

/**
 * Pubblica il post
 * @param allChecksPost check di tutti i post
 * @param activeChecks check dell'active
 * @returns
 */
const doPublish = async (allChecksPost: AccountProblem[], activeChecks: AccountProblem[]) => {
  if (allChecksPost.length === 0 && activeChecks.length === 0) {
    await PostHistory.publishV2()
    showSuccess(T.success.postGenerated)
    resetPage()
  }

  // Se l'active nn ha errori ma gli altri post si
  //if (activeChecks.length > 0) showError(T.error.verify_that_all_posting_requirements_are_correct)
  // mostra gli errori dell'active
  else showMultipleProblems(allChecksPost)
}

/**
 * Pubblica il post in base allo status
 * @param postStatus
 * @returns
 */
export const publish = async (postStatus: 'draft' | 'publish-now' | 'schedule') => {
  try {
    // Check if the user has the permission to publish or make other action with the post
    await publishCheckPermissions(postStatus)

    const { posts, activePost } = useCreatePostStore.getState()
    const allChecksPost: AccountProblem[] = []
    let activeChecks: AccountProblem[] = []
    let checkDraft = true

    // Loop through all the posts
    posts.forEach((postElement) => {
      const post = postElement
      // ----- Inverte text e description solo per youtube -----
      // inversione dei valori di text e description per youtube
      // if (post.info.account.provider === 'youtube') {
      //   const { text, description } = post.data
      //   post.data.text = description ?? ''
      //   post.data.description = text ?? ''
      // }

      // Verifica se il post è valido
      const { allChecksPost: problems, checkDraft: check } = publishPostValidation(posts, post, postStatus)
      allChecksPost.push(...problems)
      checkDraft = check
    })

    // --- setActiveChecks

    // Se l'active è GLOBAL allora prende tutti i check
    if ('_id' in activePost && activePost._id === 'GLOBAL') activeChecks = allChecksPost

    // Se l'active è un post normale allora prende solo i check di quell'active
    if ('variantId' in activePost) activeChecks = checkPostValidation(activePost as IPostVariant)

    switch (postStatus) {
      case 'schedule':
        doSchedule(allChecksPost)
        return

      case 'draft':
        doDraft(checkDraft)
        return

      case 'publish-now':
        doPublish(allChecksPost, activeChecks)
        return

      default:
        return
    }
  } catch (e) {
    console.error(e)
  }
}

/**
 * Gestisce l'azione di pubblicazione / cambio stato di un post
 * @param post IPostVariant
 * @param status PostStatus
 */
export const publishSinglePost = async (post: IPostVariant, status: PostStatus) => {
  try {
    await PostHistory.publishV2(post)

    switch (status) {
      case 'draft':
        showSuccess(T.success.postSavedInDraft)
        break
      case 'planned':
        showSuccess(T.success.postPlanned)
        break
      case 'published':
        showSuccess(T.success.postGenerated)
        break
    }
  } catch (e) {
    console.error(e)
  }
}

export const resetPage = async () => {
  const { clearPost } = useCreatePostStore.getState()

  clearPost()
}

const checkIfDraftIsEmpty = (draft: IPostVariant): boolean => {
  const { data, info } = draft
  const { text, medias } = data

  if (text.length === 0 && medias.length === 0) return false

  return true
}

// Ritorna il primo typo di media che gli passiamo
export const getFirstMedia = (medias: Media[], type) => {
  const firstVideoInMedia = medias.filter((item: any) => item.mimetype.includes(type))
  if (firstVideoInMedia.length === 0) return undefined
  return firstVideoInMedia[0].url
}

export const determineIsEmpty = (subsec, genText, genLink) => {
  if (subsec === 'astersAiText') return genText.length === 0

  if (subsec === 'astersAiLink') return genLink.length === 0

  return true
}

// Funzioni per spostarsi nel carosello media in preview
export const nextMedia = (selectedIndex: number, setSelectedIndex: (newIndex: number) => void, medias, e?) => {
  e && e.stopPropagation()

  const newIndex = selectedIndex + 1
  if (newIndex > medias.length - 1) {
    setSelectedIndex(0)
    return
  }

  setSelectedIndex(newIndex)
}

export const prevMedia = (selectedIndex: number, setSelectedIndex: (newIndex: number) => void, medias, e?) => {
  e && e.stopPropagation()

  const newIndex = selectedIndex - 1
  if (newIndex < 0) {
    setSelectedIndex(medias.length - 1)
    return
  }

  setSelectedIndex(newIndex)
}

export const getActiveText = () => {
  const { activePost } = useCreatePostStore.getState()
  if (activePost === undefined) return ''
  if ('variantId' in activePost) return activePost.data.text ?? ''
  if ('_id' in activePost && activePost._id === 'GLOBAL') return activePost.text ?? ''
  return ''
}

export const getActiveDescription = () => {
  const { activePost } = useCreatePostStore.getState()

  if ('variantId' in activePost) return activePost.data.description
  if ('_id' in activePost && activePost._id === 'GLOBAL') return activePost.description

  return ''
}

export const getFirstCommentText = (post: IPostVariant) => {
  return post.data.firstComment?.text
}

export const getActiveInfo = (post?) => {
  const { activePost } = useCreatePostStore.getState()

  const workingPost = post ?? activePost

  if (workingPost === undefined) return {} as IVariantInfo

  if ('_id' in workingPost && workingPost._id === 'GLOBAL') return {} as IVariantInfo

  if ('variantId' in workingPost) return workingPost.info ?? ({} as IVariantInfo)

  return {} as IVariantInfo
}

export const getActiveData = () => {
  const { activePost } = useCreatePostStore.getState()

  if ('_id' in activePost && activePost._id === 'GLOBAL') return activePost

  if ('variantId' in activePost) return activePost.data ?? {}

  return {}
}

export const getPostData = (post) => {
  if ('_id' in post && post._id === 'GLOBAL') return post

  if ('variantId' in post) return post.data ?? []

  return []
}

export const getFirstCommentData = (post) => {
  return post.data.firstComment
}

//to be removed : fix yt short preview saving
export const checkYTPreview = (func: any) => {
  const { activePost, activePreviewYT, setActivePreview } = useCreatePostStore.getState()

  if (activePost === undefined) return

  if (activePost._id === 'GLOBAL') return

  if ('variantId' in activePost && activePost.info.account.provider.includes('youtube')) {
    if (activePreviewYT === 'YOUTUBE_STANDARD') setActivePreview('YOUTUBE_STANDARD')
    if (activePreviewYT === 'YOUTUBE_SHORT') setActivePreview('YOUTUBE_SHORT')
  } else {
    typeof func === 'function' && func()
  }
}

export const getActiveLabels = (): ILabel[] => {
  const { activePost } = useCreatePostStore.getState()

  if ('variantId' in activePost && activePost.data && activePost.data.labels) {
    return activePost.data.labels
  }

  if ('_id' in activePost && activePost._id === 'GLOBAL' && (activePost as IPostData).labels) {
    return (activePost as IPostData).labels!
  }

  return []
}

export const hasExtrasData = (post: IPostVariant) => {
  const {
    firstComment,
    place,
    tags,
    duet,
    stitch,
    commentAvaible,
    privacyLevel,
    description,
    playlist,
    privacyStatus,
    category,
    hashtags,
    embedded,
    notifySubscriber,
    adultOnly,
  } = post.data

  if (
    firstComment ||
    place ||
    tags ||
    duet ||
    stitch ||
    commentAvaible ||
    privacyLevel ||
    description ||
    playlist ||
    privacyStatus ||
    category ||
    hashtags ||
    embedded ||
    notifySubscriber ||
    adultOnly
  )
    return true

  return false
}

export function extractAndCompareUrls(text: string, existingUrls: IUrl[]): IUrl[] {
  // Regex per estrarre gli URL dal testo
  const urlRegex = /(https?:\/\/[^\s]+)/g

  // Estrai tutti gli URL dal testo
  const extractedUrls = text.match(urlRegex) || []

  const urls: IUrl[] = []
  for (const url of extractedUrls) {
    const els = existingUrls?.filter((u) => u.originalUrl === url || u.shortUrl === url) ?? []
    const el = els.find((u) => u.metaData?.visible)?.[0] ?? els[0]
    el && urls.push(el)
  }
  return urls
}
