import config from 'config'
import { LoginResponse, TokenResponse, User } from 'types'
import { AppStore } from 'utils'
import Utils, { getUserInfo } from './Utils'
import { api, loginApi, responseErrorCheck } from './api'
import { useUserStore } from 'stores/UserStore'
import BillingService from './service/BillingService'

export default class Auth {
  //Login
  static login(email: string, password: string) {
    return loginApi.post<LoginResponse>('auth/login', { email: encodeURI(email), password }).then(responseErrorCheck)
  }

  //Login
  static googleLogin() {
    // finish with '/'
    const endpoint = config.endpoint.endsWith('/') ? config.endpoint : `${config.endpoint}/`
    return window.open(
      `${endpoint}auth/google?affiliate=${(window as any)?.Rewardful?._cookie?.affiliate?.token}&callbackUrl=${window.location.origin
      }/token`,
      '_self'
    )
  }
  //Login
  static googleRedirectLogin() {
    return api.get<LoginResponse>('auth/google/redirect').then(responseErrorCheck)
  }
  //Login
  static facebookLogin() {
    const endpoint = config.endpoint.endsWith('/') ? config.endpoint : `${config.endpoint}/`

    return window.open(
      `${endpoint}auth/facebook?affiliate=${(window as any)?.Rewardful?._cookie?.affiliate?.token}&callbackUrl=${window.location.origin
      }/token`,
      '_self'
    )
  }
  //Login
  static facebookRedirectLogin() {
    return api.get<LoginResponse>('auth/facebook/redirect').then(responseErrorCheck)
  }

  //Sign up
  static signup(
    name: string,
    password: string,
    email: string,
    type?: string,
    affiliate?: string,
    prefineryCode?: string,
    licenseKey?: string,
  ) {
    const { language, timeZone, utcOffset } = getUserInfo()
    const timezone = {
      name: timeZone,
      value: utcOffset,
    }
    return api
      .post<TokenResponse>('auth/signup', { name, password, email, licenseKey, type, affiliate, prefineryCode, language, timezone })
      .then(responseErrorCheck)
  }

  //Profile
  static async loggedUser() {
    const user = await api.get<User>('auth/loggedUser').then(responseErrorCheck)
    // if (!user.stripe?.customerId) await Utils.createStripeCustomer()
    // request plan and save current plan and scopes

    const currentPlan = user?.subscription?.activePlan
    if (!currentPlan) {
      useUserStore.getState().setUser(user)
      return user
    }
    try {
      const availablePlans = await BillingService.getPlans(!!user.license)
      const currentPlanInfo = availablePlans.find((plan) => plan.identifier === currentPlan?.plan?.identifier)
      const availableScopes = {
        autopilot: currentPlanInfo.enterprise || currentPlanInfo.features.autopilot,
        analytics: currentPlanInfo.enterprise || currentPlanInfo.features.api,
      }
      useUserStore.getState().setScopes(availableScopes)
      useUserStore.getState().setCurrentPlan(currentPlanInfo)
    } catch (e) {
      console.error(e)
    }

    useUserStore.getState().setUser(user)

    return user
  }

  static confirmUser(tempToken: string) {
    return api.post<string>('auth/confirmUser', { tempToken }).then(responseErrorCheck)
  }

  static logout() {
    AppStore.setToken()
    AppStore.forceTheme(false) // reset theme in light mode
    window.location.href = '/'
  }

  static updateEmail(email: string) {
    return api.post<string>('auth/updateEmailRequest', { email }).then(responseErrorCheck)
  }

  static updatePassword(password: string) {
    return api.post<string>('auth/updatePassword', { password }).then(responseErrorCheck)
  }

  static updateName(name: string) {
    return api.post<string>('auth/updateName', { name }).then(responseErrorCheck)
  }

  //Login
  static recoverPassword(email: string) {
    return api.post('auth/recoverPassword', { email }).then(responseErrorCheck)
  }

  static changePasswordWithToken(tempToken: string, password: string) {
    return api.post('auth/changePasswordWithToken', { tempToken, password }).then(responseErrorCheck)
  }

  static acceptNewEmail(token: string) {
    return api.post<string>('auth/acceptNewEmail', { token }).then(responseErrorCheck)
  }

  static async resendEmailConfirmation() {
    return api
      .post('auth/resendEmail', { temp_token: AppStore.loggedUser.temp_token, email: AppStore.loggedUser.email })
      .then(responseErrorCheck)
  }

  static addPrefinery() {
    return api.post(`auth/addPrefinery`)
  }

  static mixpanelRegister() {
    return api.post(`auth/mixpanelRegister`)
  }

  static async addNumber(prefix: string, number: string) {
    return api.post(`auth/${AppStore.workspace._id}/addNumber`, { prefix, number }).then(responseErrorCheck)
  }

  static async verifyNumber(code: string) {
    return api.get(`auth/${code}/verifyNumber`).then(responseErrorCheck)
  }

  /**
   * The function `isUserRegistered` checks if a user is registered in a workspace using a temporary
   * token and workspace ID.
   * @param {string} tempToken - A temporary token used for authentication.
   * @param {string} workspaceId - The `workspaceId` parameter is a string that represents the unique
   * identifier of a workspace or a specific location within a system. It is used to identify and
   * differentiate between different workspaces or locations.
   * @returns A Promise<boolean> is being returned.
   */
  static async isUserRegistered(tempToken: string, workspaceId: string): Promise<boolean> {
    return api.get<boolean>(`auth/${tempToken}/${workspaceId}/isUserRegistered`).then(responseErrorCheck)
  }



  static async getAppsumoLicenseKey(authorizationCode: string) {
    return api.get<AppsumoLicenseKeyRes>(`auth/oauth/appsumo/license-key`, { authorizationCode }).then(({ data }) => data)
  }
}

export type AppsumoLicenseKeyRes = {
  license_key: string
  status: string
  scopes: string[]
}