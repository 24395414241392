/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { SocialPicture } from 'components/UI/SocialPicture'
import { on } from 'events'
import { useState } from 'react'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { IVariantSocial } from 'types/PostInterface'
import { AppStore, showWarning } from 'utils'
import { getPostByAccountId } from 'utils/PostUtils/Post'
import { AccountProblem, checkPostValidation } from 'utils/PostUtils/ValidationPost'
import { showMultipleProblems, showMultipleWarningsWithTitle, showWarningWithTitle } from 'utils/SuperUtils'

/* ------------------- INTERFACES  */
interface Props {
  account: IVariantSocial
}

export const AccountCheck = ({ account }: Props) => {
  /* ----------------- VARIABLES  */
  const { picture, provider } = account
  const [isHover, setIsHover] = useState(false)

  /* ----------------- ZUSTAND  */
  const { activePost, uniqueVariants, activeAccountId, hasClickedPublish } = useCreatePostStore((s) => ({
    posts: s.posts,
    activePost: s.activePost as any,
    uniqueVariants: s.uniqueVariants,
    activeAccountId: s.activeAccountId,
    hasClickedPublish: s.hasClickedPublish,
  }))

  /* ----------------- METHODS  */

  /**
   * Check if the account is active
   * @param accountInfo
   * @returns
   */
  const checkIfAccountIsActive = (accountInfo: IVariantSocial) => {
    if (accountInfo === undefined) return false

    if (accountInfo.account_id === activeAccountId) return true

    const post = getPostByAccountId(accountInfo.account_id)

    let postVariant

    if (post === undefined) return false

    if ('_id' in post && post?._id === 'GLOBAL') postVariant === 'GLOBAL'
    if ('variantId' in post) postVariant = post.variantId

    if (activePost?._id === 'GLOBAL' && !uniqueVariants.includes(postVariant)) return true

    if (activePost?._id === 'GLOBAL' && uniqueVariants.includes(postVariant)) return false

    return false
  }

  /**
   * Verify if the account is valid
   * @param accountId
   * @param showProblems
   * @returns
   */
  const checkIfValid = (
    accountId: string,
    showProblems?: boolean
  ): {
    isValid: boolean
    problems: AccountProblem[]
  } => {
    // Queste funzioni partono dopo che l'utente ha cliccato la prima volta la funzione publish
    let response: { isValid: boolean; problems: AccountProblem[] } = {
      isValid: true,
      problems: [],
    }
    let problems: AccountProblem[] = []

    // If the user has not clicked the publish button, bypass the validation check
    if (!hasClickedPublish) return response

    // Get the post by account id
    const post = getPostByAccountId(accountId)

    // post is undefined, bypass the validation check
    if (post === undefined) return response

    // If the post is GLOBAL, bypass the validation check
    if ('_id' in post && post?._id === 'GLOBAL') return response

    // Check if the post is valid
    if ('variantId' in post) problems = checkPostValidation(post) ?? []

    // Check if the account has problems and notify with a warning
    if (showProblems && problems.length > 0) showProblemsWarnings(problems)

    response = {
      isValid: problems.length === 0,
      problems,
    }

    return response
  }

  /**
   * Show problem warnings
   * @param problems
   */
  const showProblemsWarnings = (problems: AccountProblem[]) => {
    const providerName = account.provider.charAt(0).toUpperCase() + account.provider.slice(1)
    showMultipleProblems(problems)
  }
  /**
   * On account check click
   */
  const onAccountCheckClick = () => {
    const { setActiveAccountId } = useCreatePostStore.getState()
    setActiveAccountId(account.account_id)
    checkIfValid(account.account_id, true)
  }

  // ----------------- other variables ---------------  */
  const isActive = checkIfAccountIsActive(account)

  const { isValid, problems } = checkIfValid(account.account_id)
  const hasProblems = problems.length > 0

  return (
    <AccountCheckContainer
      active={isActive}
      valid={!isValid}
      onClick={onAccountCheckClick}
      shadow={isActive ? AppStore.theme.smallOuterShadow : undefined}
      onHoverStart={() => setIsHover(true)}
      onHoverEnd={() => setIsHover(false)}
    >
      {isHover && hasProblems && <AccountProblemHover problems={problems} />}
      <SocialPicture
        picture={picture}
        provider={provider}
        dimensionPicture={36}
        dimensionProvider={24}
        active={isActive}
      />

      {!isValid && (
        <ContainerIconWarning>
          <Icons.postWarning />
        </ContainerIconWarning>
      )}
    </AccountCheckContainer>
  )
}

/**
 * Show account problems on hover
 * @param param0
 * @returns
 */
const AccountProblemHover = ({ problems }: { problems: AccountProblem[] }) => {
  return (
    <AccountProblemHoverContainer>
      {problems.map((problem, index) => (
        <AccountProblemHoverText last={problems.length - 1 === index} key={index}>
          {problem.problem}
        </AccountProblemHoverText>
      ))}
    </AccountProblemHoverContainer>
  )
}

const AccountProblemHoverContainer = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 28px;
  min-width: 200px;
  z-index: 99999;
  border-radius: 16px;
  background: #fff;
  border: 1px solid #e1631e;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`

// add divider with css if the index is not the last one
const AccountProblemHoverText = styled(Box)<{ last: boolean }>`
  font-size: 12px;
  color: ${AppStore.theme.o.darkGrey};
  margin-bottom: ${({ last }) => (last ? '0px' : '8px')};
  padding-bottom: ${({ last }) => (last ? '0px' : '8px')};
  line-height: 16px;
  border-radius: 0px;
  border-bottom: ${({ last }) => (last ? 'none' : '1px solid #e1631e')};
  padding-inline: 8px;
`

const AccountCheckContainer = styled(Box)<{ shadow?: string; active: boolean; valid: boolean }>`
  display: flex;
  padding: 12px 16px;
  cursor: pointer;
  width: 74px;
  height: 66px;
  border-radius: 16px;
  border: 1px solid;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all 0.2s;
  box-shadow: ${({ shadow }) => shadow};
  background: ${({ active, valid }) =>
    active ? AppStore.theme.o.lightGreen : valid ? '#FFF0C2' : AppStore.theme.o.surface};
  border-color: ${({ valid, active }) =>
    valid ? '#E1631E' : active ? AppStore.theme.o.green : AppStore.theme.o.surface};
`

const ContainerIconWarning = styled(Box)`
  position: absolute;
  top: -4px;
  right: -4px;

  border-radius: 4px;
  background: #fff0c2;
`
