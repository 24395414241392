import styled from '@emotion/styled'
import Medias from 'api/Medias'
import Tags from 'api/Tags'
import { Tracker } from 'api/Tracker'
import { AnimatedPlaceholderLogo, Box, Button, FolderModal, Icons, MediaImage, Text, ZoomedContainer } from 'components'
import { LottieSadSearch } from 'components/CreatePost/MediaComponents/LottieSadSearch'
import { Modal } from 'components/Modal/Modal'
import { openEditor } from 'components/Modal/ModalManager'
import { ConfirmModal } from 'components/UI'
import JSZip from 'jszip'
import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import { useNavigate } from 'react-router-dom'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { useModalNavigationStore } from 'stores/ModalNavigationStore'
import { T } from 'translation/i18n'
import { CrelloResponse, Media } from 'types'
import { IPostData } from 'types/PostInterface'
import {
  AppStore,
  downloadMedias,
  getMediaName,
  normalizeString,
  openVistaCreate,
  showError,
  showInfo,
  urlToBlob,
} from 'utils'
import {
  TRACKEREVENTS,
  getEditorData,
  getEditorFileSaveData,
  getMediaStorageData,
  getUploadedFileData,
} from 'utils/eventsTracker'
import { MixpanelEditorData } from 'utils/eventsTracker/editor/editorInterfaces'
import { UploadFile } from 'utils/eventsTracker/uploadFile/uploadFileInterfaces'
import { MixpanelEditorFiles } from 'utils/eventsTracker/utils/utilsInterfaces'
import { updateGlobal } from 'utils/PostUtils/Post'
import background from './../../assets/BackEdit.jpeg'
import ChangeFileFolder from './ChangeFileFolder'
import CustomMediaModal from './CustomMediaModal'
import IconEdit from './IconEdit'
import MediaBottomBar from './MediaBottomBar'
import MediaHeader from './MediaHeader'
import MediaPaths from './MediaPaths'
import MediaSort from './MediaSort'

export const openMediaModal = (setState) => {
  let selectedMedias: Media[] = []
  AppStore.openModal({
    width: 1700,
    id: 'media-modal-selector',
    body: (
      <ZoomedContainer center flex style={{ position: 'relative', padding: '16px 12px', maxWidth: 1800 }}>
        <div
          style={{
            overflow: 'auto',
            minWidth: '95%',
            backgroundColor: 'transparent',
          }}
        >
          <MediaContainer
            isModal
            onChangeMedias={(medias) => {
              selectedMedias = [...medias]
            }}
          />
        </div>
        <InsertContainer>
          <Button
            height={65}
            width={220}
            variant="primary"
            onClick={() => {
              if (selectedMedias.length === 0) return

              const lastSelectedMedia = selectedMedias[selectedMedias.length - 1]

              if (lastSelectedMedia && lastSelectedMedia.mimetype.startsWith('image/')) {
                setState((prevState) => ({
                  ...prevState,
                  image: lastSelectedMedia.url,
                }))
              } else {
                return
              }

              AppStore.closeModal('media-modal-selector')
            }}
          >
            {T.settings.insert}
          </Button>
        </InsertContainer>
      </ZoomedContainer>
    ),
  })
}

interface Props {
  style?: React.CSSProperties
  onChangeMedias?: (selectedMedia: Media[]) => void
  preselectedMedia?: Media[]
  isModal?: boolean
  onlyOneSelectable?: boolean
  showLottie?: boolean
  navigate?: any
  context?: string
}

interface State {
  medias: Media[]
  search: string
  path: string
  loading: boolean
  selectedMedias: Media[]
  onHoverMedia?: Media
  progressLoading?: number
  selectedFolder?: number
  counter: number
  mediaTaged: any[]
  video: {
    toLoad: number | undefined
    loaded: number | undefined
  }

  modifyingActive: boolean
  sortType?: (array: any) => void
  selectedSortType: string
  filter?: [{ item: string }]
  filesArray?: any
  showLottie?: boolean
}

export const MediaContainerComp = (props: Props) => {
  const navigate = useNavigate()

  return <MediaContainer navigate={navigate} {...props} />
}
export class MediaContainer extends Component<Props, State> {
  state: State = {
    medias: [],
    search: '',
    path: `${AppStore.workspace._id}/`,
    loading: true,
    selectedMedias: this.props.preselectedMedia ?? [],
    onHoverMedia: undefined,
    progressLoading: undefined,
    selectedFolder: -1,
    counter: 0,
    mediaTaged: [],
    filesArray: [],

    video: {
      toLoad: undefined,
      loaded: 0,
    },

    modifyingActive: false,
    showLottie: false,
    sortType: (array) => {
      return array.sort((a, b) => {
        const dateA = new Date(a.updated_at)
        const dateB = new Date(b.updated_at)
        return dateB.getTime() - dateA.getTime()
      })
    },
    selectedSortType: T.postPage.fromLatest,
  }

  navigate = this.props.navigate

  inputFileRef = React.createRef<any>()

  componentDidMount() {
    this.refreshMedia()
  }

  trackMediaPageViewed(medias) {
    const mediaData = {
      medias: medias,
      used_storage: AppStore.storageUsed,
      available_storage: 1000 - AppStore.storageUsed,
      files_order: this.state.selectedSortType,
    }

    Tracker.trackEvent(TRACKEREVENTS.MEDIA_PAGE_VIEWED, getMediaStorageData(mediaData, this.state.path))
  }

  refreshMedia = async () => {
    this.setState({ loading: true })

    // get parameter from url
    const urlParams = new URLSearchParams(window.location.search)
    const path = urlParams.get('path') || `${AppStore.workspace._id}/`
    this.setState({ path })

    try {
      const medias = await Medias.paginate<Media>({
        path: `${AppStore.workspace._id}/${this.state.path.replace(AppStore.workspace._id ?? '', '')}`.replace(
          /\/\//g,
          '/'
        ),
        workspace: AppStore.workspace._id,
      })

      this.trackMediaPageViewed(medias?.results)
      this.setState({ medias: medias?.results ?? [], loading: false })
    } catch (error) {
      showError(error)
      this.setState({ medias: [], loading: false })
    }
  }

  changePath = async (media?: Media) => {
    if (!media) {
      await this.setState({
        path: this.state.path.split('/').slice(0, -2).join('/') + '/',
      })
    } else {
      await this.setState({ path: media.path + media.name + '/' })
    }

    // ---- Add path to url ----
    const newUrl = `${window.location.origin}${window.location.pathname}?path=${encodeURIComponent(this.state.path)}`
    history.pushState({}, '', newUrl)
    // ----
    this.refreshMedia()
  }

  returnToClickPath = async (index: number) => {
    //la lunghezza del vettore che sarebbero tutti i path
    const length = this.state.path.replace(AppStore.workspace._id + '/', '').split('/').length - 1

    //quanto ce da rimuovere per arrivare al path giusto
    const removeIndex = index - length

    //si setta il state per aggiornare il path
    await this.setState({
      path: this.state.path.split('/').slice(0, removeIndex).join('/') + '/',
    })

    //e refreshiamo i media
    this.refreshMedia()
  }

  renderFolder = (media: Media, index) => (
    <Box
      style={{ position: 'relative' }}
      onHoverStart={() => this.setState({ selectedFolder: index })}
      onHoverEnd={() => {
        if (!this.state.modifyingActive) this.setState({ selectedFolder: -1 })
      }}
    >
      <Box
        key={'folder' + index}
        width={150}
        height={150}
        onClick={() => this.changePath(media)}
        justify="space-evenly"
        center
        removeHover
      >
        <Icons.folder />

        <Text
          style={{
            width: 150,
            alignContent: 'center',
            textAlign: 'center',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          weight={500}
        >
          {media.name}
        </Text>
      </Box>

      <IconEdit
        media={media}
        renameFolder={this.renameFile}
        changePath={(newPath) => {
          this.setState({ path: newPath })
          this.refreshMedia()
          AppStore.closeAllModal()
        }}
        show={this.state.selectedFolder === index}
        path={this.state.path}
        modifyOn={() => {
          this.setState({
            modifyingActive: !this.state.modifyingActive,
            selectedFolder: -1,
          })
        }}
      />
      {this.state.selectedFolder === index && (
        <Box
          onClick={() => this.changePath(media)}
          style={{
            position: 'absolute',
            backgroundColor: 'rgba(0,0,0,0.1)',
            top: -8,
          }}
          width={150}
          height={150}
        ></Box>
      )}
    </Box>
  )

  checkIfMediaIsSelected = (media: Media) => {
    return this.state.selectedMedias.find((m) => m._id === media._id)
  }

  //add media or remove into selectedMedia array
  onSelectMedia = async (media: Media) => {
    const { selectedMedias } = this.state
    const mediaIndex = selectedMedias.findIndex((m) => m._id === media._id)
    if (mediaIndex === -1) {
      selectedMedias.push(media)
      await this.setState({ counter: this.state.counter + 1 })
    } else {
      await this.setState({ counter: this.state.counter - 1 })
      selectedMedias.splice(mediaIndex, 1)
    }
    this.props.onChangeMedias?.(selectedMedias)
    this.setState({ selectedMedias })
  }

  renderImage = (media: Media, index, id?: string) => {
    let fileName = media.visible_name === undefined ? getMediaName(media.name) : media.visible_name
    const imageExtensions = /\.(jpg|jpeg|png|webp)$/i

    if (!imageExtensions.test(fileName)) {
      fileName = `${fileName}`
    }

    return (
      <Box
        className={id}
        style={{
          position: 'relative',
          backgroundColor: this.checkIfMediaIsSelected(media) ? AppStore.theme.o.lightGrey : '',
        }}
        onMouseEnter={() => this.setState({ onHoverMedia: media })}
        onMouseLeave={() => this.setState({ onHoverMedia: undefined })}
        shadow={
          this.checkIfMediaIsSelected(media) || this.state.onHoverMedia === media
            ? ''
            : AppStore.theme.simpleOuterShadow
        }
        key={'file' + index}
        width={240}
        borderRadius={14}
        onClick={async () => {
          this.onSelectMedia(media)
        }}
        justify="flex-start"
      >
        <MediaImage media={media} index={index} finishLoad={this.onFinishLoadingVideo} />
        <Box mv={8} ml={16}>
          <MediaName>{fileName.split('/').pop()}</MediaName>
        </Box>

        {(this.state.selectedMedias.length > 0 || this.state.onHoverMedia === media) && (
          <SelectedBox>
            {this.checkIfMediaIsSelected(media) ? (
              <Icons.selectedBox style={{ position: 'absolute', top: 12, left: 12 }} />
            ) : (
              <Icons.notSelectedBox style={{ position: 'absolute', top: 12, left: 12 }} />
            )}
          </SelectedBox>
        )}
      </Box>
    )
  }

  onUploadProgress = (progressEvent: ProgressEvent) => {
    this.setState({
      progressLoading: (progressEvent.loaded / progressEvent.total) * 100,
    })
  }

  async processFile(file: UploadFile, data) {
    try {
      const mediaData: Media = data.data

      const fileWeight: string = (file.size / 1000000).toFixed(2) // Conversione da Byte a Megabytes
      const fileFormat: string = mediaData?.metadata?.extension ?? 'unknown'
      const { width, height, duration }: { width: number; height: number; duration: number } = mediaData.metadata
      const durationInMinutes = duration ? (duration / 60).toFixed(2) : null // Conversione da secondi a minuti

      const url = await Medias.getUrl(mediaData)

      const fileData: MixpanelEditorFiles = {
        file_weight: fileWeight,
        file_format: fileFormat,
        file_size: `${width}x${height}`,
        video_length: durationInMinutes ?? undefined,
        file_url: url.data as string,
      }

      Tracker.trackEvent(TRACKEREVENTS.UPLOAD_FILE_COMPLETED, getUploadedFileData(fileData))
    } catch (e) {
      // console.error(e)
    }
  }

  checkFolderToCreate = async (event) => {
    const folders: { path: string; name: string }[] = []

    //------ per ogni folder ------------------------------------------------------
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i]
      const splitedFile = file?.path?.split('/') ?? []

      //------ if se sta in una cartella ------------------------------------------
      if (splitedFile.length > 2) {
        //------ if se sta in una cartella trova il nome e il path ----------------
        let pathName = ''

        const startingPath = this.state.path.split('/').splice(1)

        for (const startPath of startingPath) {
          if (startPath !== '') pathName += startPath + '/'
        }
        for (let j = 0; j < splitedFile.length - 2; j++) {
          if (j === 0) continue
          pathName += splitedFile[j] + '/'
        }

        const name = splitedFile[splitedFile.length - 2]

        //------ se nn è stato agiunto aggiungilo ------------------------------------------
        if (folders.indexOf({ path: pathName, name }) === -1) {
          folders.push({ path: pathName, name })
          const pathNameSplit = pathName.split('/')

          let emptyFolder = ''
          //---------------------------------------------------------------------------------
          //questo si fa perchè react-dropzone rileva solo i file
          //quindi per rilveare anche le cartelle si fa sta funzione che trova le sotto cartelle
          //e se nn sono state aggiunte le aggiunge

          for (let k = 0; k < pathNameSplit.length - 2; k++) {
            emptyFolder += pathNameSplit[k] + '/'
            if (folders.indexOf({ path: emptyFolder, name: pathNameSplit[k + 1] }) === -1) {
              await folders.push({ path: emptyFolder, name: pathNameSplit[k + 1] })
            }
          }
        }
      }
      //senno niente nn si crea cartelle perchè nn sta in una cartella
    }

    //------ qui si fa il controllo che siano univoche le cartelle----------------------------
    //per qualche strno motivo il if dell controllo non funziona avolte quindi ho messo il controllo
    const uniqueFolder = folders.filter((value, index, self) => {
      return index === self.findIndex((obj) => obj.path === value.path && obj.name === value.name)
    })

    //si ritorna le cartelle univoche
    return uniqueFolder
  }

  createFiles = async (event: any) => {
    try {
      const owner = AppStore.workspace.user

      const { path } = this.state
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i]
        const normalizedFileName = normalizeString(`${owner}_${file.name}`)
        const normalizedFilePath = normalizeString(file.webkitRelativePath || file.path || file.name)

        // Creare un oggetto UploadFile con le proprietà richieste
        const uploadFile: UploadFile = {
          name: normalizedFileName,
          size: file.size,
          type: file.type,
          lastModified: file.lastModified,
          lastModifiedDate: new Date(file.lastModified),
          webkitRelativePath: normalizedFilePath,
        }

        const splitedFile = normalizedFilePath.split('/') ?? []
        const formData = new FormData()

        // Update the formData object
        formData.append('file', new File([file], uploadFile.name, { type: file.type }), uploadFile.name)

        // Get current path to upload the file in the right directories
        const pathFromUrl = new URLSearchParams(window.location.search).get('path') ?? `${AppStore.workspace._id}/`
        let pathName = decodeURIComponent(pathFromUrl) ?? `${AppStore.workspace._id}/`

        // Se il file è contenuto in una cartella
        if (splitedFile.length > 2) {
          pathName = ''
          const startingPath = this.state.path.split('/').splice(1)

          for (const startPath of startingPath) {
            if (startPath !== '') pathName += startPath + '/'
          }

          for (let j = 0; j < splitedFile.length - 1; j++) {
            if (j === 0) {
              continue
            }
            pathName += splitedFile[j] + '/'
          }

          // Si appende un altro path che corrisponde al path del file dentro la cartella
        }
        formData.append('path', pathName)

        try {
          const data: any = await Medias.createFile(formData, this.onUploadProgress)

          // Se il ritorno dal BE indica che si supera i limiti dello storage
          if (data?.data?.message === 'Limit for storageLimits ') {
            return showInfo(T.limitUser.max_reached_storage)
          }

          this.processFile(uploadFile, data)
        } catch (error) {
          showError(error)
        }

        await this.setState({ progressLoading: undefined })
      }
      AppStore.updateStorageUsed()
    } catch (e) {
      console.error(e)
    }
  }

  onFileChange = async (event) => {
    try {
      this.setState({ loading: true })

      const uniqueFolder = await this.checkFolderToCreate(event)

      //-------- CONTROLLO ROOT CREABILE
      if (uniqueFolder[0] !== undefined) {
        for (const media of this.state.medias.filter((m) => m.type === 'folder')) {
          const originalPath = `${media.path}${media.name}`
          const newPath = `${AppStore.workspace._id}/${uniqueFolder[0].path}${uniqueFolder[0].name}`

          //controlla se la cartella che si sta creando è gia esistente
          //se è gia esistente da errore
          if (originalPath === newPath) {
            showError(T.error.folderAlreadyExists)
            this.setState({ loading: false })
            return
          }
        }

        // -------- CREAZIONE DI CARTELLE
        for (const folder of uniqueFolder) {
          await Medias.createFolder({ path: folder.path, name: folder.name })
        }
      }

      // console.log('uniqueFolder', event)
      //-------- -------- CREAZIONE DI FILE
      await this.createFiles(event)

      //-------- -------- REFRESH DEI MEDIA
      await this.refreshMedia()
      const element = event.target as HTMLInputElement
      element.value = ''
      this.setState({ loading: false })
    } catch (e) {
      console.error(e)
    }
  }

  onCrelloSave = async (crelloResponse: CrelloResponse) => {
    try {
      this.setState({ loading: true })
      const { path } = this.state
      const promises: Promise<any>[] = []

      const buffers: Blob[] = []

      if (crelloResponse?.extension === 'zip') {
        //unzip this url using fetch and create a blobs array with the files
        const zipBlob = await fetch(crelloResponse.url).then((res) => res.blob())
        const zip = new JSZip()
        const zipFiles = await zip.loadAsync(zipBlob)
        for (const [key, value] of Object.entries(zipFiles.files)) {
          const file = await value.async('blob')
          buffers.push(file)
        }
      } else {
        buffers.push(await urlToBlob(crelloResponse.url))
      }

      const extension = crelloResponse?.extension === 'zip' ? 'png' : crelloResponse?.extension
      const owner = AppStore.workspace.user
      let i = 1
      for (const buffer of buffers) {
        const fileName = `${owner}_${crelloResponse.projectName + '-' + i}.${extension}`
        const formData = new FormData()
        formData.append('file', buffer, fileName)
        formData.append('path', path.replace(`${AppStore.workspace._id}/`, `${AppStore.workspace._id}/editor/`))
        promises.push(Medias.createFile(formData) ?? Promise.resolve())
        Tracker.trackEvent(TRACKEREVENTS.EXPORT_GRAPHIC_EDITOR_EXPORT_FILE, getEditorFileSaveData(crelloResponse))
        i = i + 1
      }

      await Promise.all(promises)
      this.setState({ loading: false })
      this.refreshMedia()
    } catch (e) {
      console.error(e)
    }
  }

  onDeleteMedias = async () => {
    const { selectedMedias } = this.state
    try {
      const check = await AppStore.checkPermission('manage_media')
      if (!check) return showInfo(`${T.error.noPermissionTo} ${T.error.deleteMedia}`)

      const promises = selectedMedias.map((m) => Medias.delete(m._id))
      await Promise.all(promises)

      this.setState({ selectedMedias: [] })
      this.refreshMedia()
    } catch (error: any) {
      showError(error?.message ?? T.error.error)
    }
  }

  /**
   * This function is used to update the global post with the selected medias and navigate to the create post page
   */
  onCreatePost = async () => {
    const { globalPost } = useCreatePostStore.getState()

    const reusablePost: IPostData = {
      ...globalPost,
      medias: this.state.selectedMedias,
    }

    updateGlobal(reusablePost)

    useModalNavigationStore.getState().setNavigatePage('/post/preview/basic')
  }

  editImage = () => {
    const { selectedMedias } = this.state
    if (selectedMedias.length > 1) return showError(T.mediaPage.canModifyOneAtTime)
    openEditor(this.state.selectedMedias, this.onCrelloSave)
  }

  openCrello = (template, selectedMedia?: any) => {
    const { width, height, measureUnits } = template.customDimensions ?? { width: 0, height: 0, measureUnits: 'px' }

    const graphicEditorData: MixpanelEditorData = {
      designType: template.designType,
      width: width,
      height: height,
      measureUnits: measureUnits,
    }

    Tracker.trackEvent(TRACKEREVENTS.GRAPHIC_EDITOR_OPENED, getEditorData(graphicEditorData))

    AppStore.openModal({
      id: 'crello-loader',
      closeIcon: false,
      modal: (
        <Modal prefixCls="editor-modal ant-modal" closable={false} centered visible>
          <AnimatedPlaceholderLogo
            containerStyle={{ backgroundColor: 'transparent' }}
            style={{ height: '10vh', width: '10vw' }}
          />
        </Modal>
      ),
    })

    openVistaCreate(this.onCrelloSave, {
      sidebarDesignType: template.designType,
      user: {
        // email: AppStore.loggedUser.email,
        externalUserId: AppStore.loggedUser._id.toString(),
      },
      ...template,
      image: selectedMedia !== undefined ? selectedMedia[0]?.url : '',
    })
  }

  openCustomMediaCreate = () => {
    setTimeout(() => {
      AppStore.openModal({
        width: 500,
        id: 'custom-media-create',
        body: (
          <CustomMediaModal
            onConfirm={(width, height) => {
              AppStore.closeModal('custom-media-create')
              this.openCrello({
                designType: 'instagramSM',
                customDimensions: { width, height, measureUnits: 'px' },
              })
            }}
          />
        ),
      })
    }, 300)
  }

  downloadImages = async () => {
    const { selectedMedias } = this.state
    await downloadMedias(selectedMedias)
  }

  renameFile = async (mediaId: string) => {
    const { selectedMedias } = this.state

    try {
      const check = await AppStore.checkPermission('manage_media')
      if (!check) return showInfo(`${T.error.noPermissionTo} ${T.error.renameMedia}`)

      AppStore.openModal({
        id: 'folder-modal',
        body: <FolderModal moreSocials={selectedMedias} media={mediaId} refreshMedias={this.refreshMedia} />,
      })
    } catch (e) {
      console.error(e)
    }
  }

  moveFile = async () => {
    AppStore.openModal({
      id: 'MoveFiles',
      body: (
        <ChangeFileFolder
          path={this.state.path}
          selectedMedia={this.state.selectedMedias}
          onFinish={async (path, media) => {
            await Medias.changePath({ media: media, path: path })
            //change path in state
            await this.setState({ selectedMedias: [], path: path })
            this.refreshMedia()
            AppStore.closeAllModal()
          }}
        />
      ),

      style: {
        minHeight: '30vh',
        minWidth: '50vw',
        marginBottom: 100,
      },
    })
  }

  onFinishLoadingVideo = async () => {
    this.setState({
      video: {
        toLoad: this.state.video.toLoad,
        loaded: (this.state.video.loaded ?? 0) + 1,
      },
    })
  }

  SortTypes = [
    {
      name: T.postPage.fromLatest,
      sort: (array) => {
        return array.sort((a, b) => {
          const dateA = new Date(a.updated_at)
          const dateB = new Date(b.updated_at)
          return dateB.getTime() - dateA.getTime()
        })
      },
    },
    {
      name: T.postPage.fromLeast,
      sort: (array) => {
        return array.sort((a, b) => {
          const dateA = new Date(a.updated_at)
          const dateB = new Date(b.updated_at)
          return dateA.getTime() - dateB.getTime()
        })
      },
    },
    {
      name: T.postPage.alphAZ,
      sort: (array) => {
        return array.sort((a, b) => {
          const ac = 'ciao'

          try {
            if (a.visible_name.toLowerCase() > b.visible_name.toLowerCase()) {
              return 1
            }
            if (a.visible_name.toLowerCase() < b.visible_name.toLowerCase()) {
              return -1
            }
          } catch (e) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return +1
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return -1
            }
          }
          return 0
        })
      },
    },
    {
      name: T.postPage.alphZA,
      sort: (array) => {
        return array.sort((a, b) => {
          try {
            if (a.visible_name.toLowerCase() > b.visible_name.toLowerCase()) {
              return -1
            }
            if (a.visible_name.toLowerCase() < b.visible_name.toLowerCase()) {
              return +1
            }
          } catch (e) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return +1
            }
          }
          return 0
        })
      },
    },
  ]

  confitmFileSize = (files: File[]) => {
    const fileSizeTotal = files.reduce((f, cur) => f + cur.size, 0)
    if (((fileSizeTotal / 1000000).toFixed(1) as any) > 50)
      AppStore.openModal({
        id: 'folder',
        body: (
          <ConfirmModal
            cancelText={T.postPage.cancel}
            confirmText={T.postPage.confirm}
            onClickCancel={() => AppStore.closeModal('folder')}
            onClickConfirm={() => {
              // AppStore.closeAllModal()
              AppStore.closeModal('folder')
              this.onFileChange({ target: { files } })
            }}
            text={
              <Box center mb={16} gap={8}>
                <Text style={{ fontSize: 24, fontWeight: 700 }}>{T.postPage.you_are_adding}</Text>
                <Text style={{ fontSize: 32, fontWeight: 500, color: AppStore.theme.o.red }}>
                  {(fileSizeTotal / 1000000).toFixed(1)} mb
                </Text>
              </Box>
            }
          />
        ),
        style: {
          minWidth: 696,
        },
      })
    else {
      this.onFileChange({ target: { files } })
    }
  }

  filterMedia = (medias: Media[]) => {
    const { filter } = this.state

    if (filter === undefined) return medias

    const checkArray: any = []
    filter.forEach((f) => {
      checkArray.push(f.item)
    })

    return medias.filter((m) => {
      const types = m?.mimetype?.split('/') ?? []
      if (types.length === 0) return false
      return checkArray.includes(types[0]) || checkArray.includes(types[1])
    })
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    const { search } = this.state
    if (prevState.search !== search && search !== '') {
      const tags = (await Tags.searchLables(search)) as any
      const idsTag = tags.data.map((t) => t._id)
      const mediaRek: Media[] = []
      this.state.medias.forEach((m) => {
        if (m.tags === undefined) return
        m.tags?.forEach((t) => {
          if (idsTag.includes(t)) {
            mediaRek.push(m)
          }
        })
      })
      this.setState({ mediaTaged: mediaRek.map((m) => m._id) })
    }
  }

  render() {
    const { style, isModal, context } = this.props
    const { path, loading, search, selectedMedias, medias, showLottie } = this.state

    const blackListedExtensions = ['tiff']

    // QUI SI OTTENGONO I FILE DAI NORMALI FILTRI DI SEARCH

    let filesArray =
      medias?.filter((m) => {
        if (m.type === 'file') {
          if (this.state.mediaTaged.includes(m._id)) return true
          if (m.visible_name === undefined) return getMediaName(m.name).toLowerCase().includes(search)
          return m.visible_name?.toLowerCase().includes(search)
        }
      }) ?? []

    const whiteListArrayOfMedia = filesArray.filter((m) => !blackListedExtensions.includes(m.metadata?.extension))

    filesArray = this.filterMedia(whiteListArrayOfMedia)

    filesArray = this.state.sortType?.(filesArray) ?? filesArray
    if (this.props.context) {
      filesArray =
        filesArray?.filter((m) => {
          if (m.metadata.fileType === context) {
            return true
          }
          return false
        }) ?? []
    }
    const column = window.screen.width < 1280 ? [0, 1, 2, 3] : [0, 1, 2, 3, 4]

    const folders = medias.filter((m) => m.type === 'folder')

    return (
      <Dropzone onDrop={(files) => this.confitmFileSize(files)}>
        {({ getRootProps, getInputProps }) => (
          <Box
            id={'FullMediaPage'}
            hideBoxShadowOnHover
            hideBoxShadow
            flex
            bgPrimaryColor
            removeHover
            scroll
            style={{ ...style }}
            {...(getRootProps() as any)}
            onClick={
              this.state.selectedMedias.length !== this.state.counter
                ? () => this.setState({ selectedMedias: [], counter: 0 })
                : this.state.selectedMedias.length > 0
                ? () => this.setState({ selectedMedias: [], counter: 0 })
                : undefined
            }
          >
            <MediaHeader
              background={background}
              onFileChange={this.onFileChange}
              inputFileRef={this.inputFileRef}
              customInputOnChange={(s) => this.setState({ search: s })}
              refreshMedia={this.refreshMedia}
              path={path}
              isModal={isModal}
              openEditor={openEditor}
            />

            {/* qui ci sta la parte del path  */}
            <Box overflow={'auto'} ph={75} mt={20} gap={32}>
              <MediaPaths path={path} changePath={this.changePath} returnToClickPath={this.returnToClickPath} />
              {!loading && (
                <>
                  {/* ---------------- qui ci sta il grid dei folder ---------------- ` */}
                  <GridFolder>
                    {medias
                      .filter((m) => m.type === 'folder' && getMediaName(m.name).toLowerCase().includes(search))
                      .map(this.renderFolder)}
                  </GridFolder>

                  {/* ---------------- qui ci sta il sort ---------------- ` */}
                  <MediaSort
                    search={search}
                    medias={medias}
                    getMediaName={getMediaName}
                    onChangeSelect={async (index) => {
                      this.setState({
                        sortType: this.SortTypes[index].sort,
                        selectedSortType: this.SortTypes[index].name,
                      })
                    }}
                    sortTypes={this.SortTypes}
                    filters={this.state.filter}
                    setFilters={(value: [{ item: string }]) => {
                      this.setState({ filter: value })
                    }}
                  />

                  {/**** GRIGLI IMMAGINI *****/}
                  {/* ---------------- qui ci sta il grid dei file ---------------- ` */}
                  {filesArray.length > 0 && (
                    <Box flex row style={{ justifyContent: 'space-between' }} pv={32}>
                      {column.map((item, index) => {
                        return (
                          <ColumnBox id={`column-${item}`} style={{ minHeight: '40vh' }} key={index}>
                            {filesArray.map((file, _index) => {
                              if (_index % 5 === item) {
                                return this.renderImage(file, _index, `column-${item}-item`)
                              }
                            })}
                          </ColumnBox>
                        )
                      })}
                    </Box>
                  )}
                </>
              )}

              {loading && (
                <Box flex>
                  <AnimatedPlaceholderLogo progress={this.state.progressLoading} />
                </Box>
              )}
            </Box>

            {!loading && filesArray.length === 0 && folders.length === 0 && !showLottie && (
              <Box relative width={340} height={340} m={'20px auto'}>
                <LottieSadSearch width={340} height={340} />
              </Box>
            )}

            {!isModal && selectedMedias.length > 0 && (
              <MediaBottomBar
                downloadImages={this.downloadImages}
                editImage={this.editImage}
                moveFile={this.moveFile}
                renameFile={() => this.renameFile(this.state.selectedMedias[0]._id)}
                onDeleteMedias={this.onDeleteMedias}
                onCreatePost={this.onCreatePost}
                selectedMedias={selectedMedias}
                isModal={isModal}
              />
            )}
          </Box>
        )}
      </Dropzone>
    )
  }
}

const GridFolder = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 150px);
  grid-auto-rows: 150px;
  grid-gap: 50px;
  justify-content: space-between;
`

const SelectedBox = styled.div`
  position: absolute;
  width: 100%;
  height: 70%;
  opacity: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 1) -10%, rgba(0, 0, 0, 0) 100%);
  top: 0px;
  left: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`

const MediaName = styled((props) => <Text {...props} />)`
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  /* height: 4em; */
  /* Fallback for non-webkit, line-height * 2 */
  -webkit-line-clamp: 2; /* if you change this, make sure to change the fallback line-height and height */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

const ColumnBox = styled(Box)`
  gap: 32px;
`

const InsertContainer = styled(Box)`
  width: 100px;
  position: absolute;
  bottom: -80px;
  right: 122px;
`
