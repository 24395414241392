import { commonToAll } from './commonToAll'

export const en = {
  title: '[ENG] ASTERS',
  common: commonToAll,

  temp: {
    settings: 'Settings',
    billing: 'Billing',
    publish: 'Publish',
    discover: 'Discover',
  },

  upperSettings: {
    quickStart: 'Quick start',
    helpSupport: 'Help center',
    roadmap: 'Roadmap',
    academy: 'Asters Academy',
    docs: 'API docs',
    settings: 'Settings',
    billing: 'Billing',
    darkMode: 'Dark mode',
    logout: 'Log out',
  },

  pages: {
    post: 'Post',
    discover: 'Discover',
    dashboard: 'Dashboard',
    calendar: 'Calendar',
    media: 'Media',
    analytics: 'Analytics',
  },

  permissions: {
    contactAdmin: 'You do not have the necessary authorizations, contact the workspace admin',
    post: 'Manage all your social channels from a single dashboard, publishing directly or scheduling the publication of content over time',
    calendar: 'View and manage all posts in the editorial calendar from one screen, filtering them to your liking',
    medias:
      'In Asters you can create any graphic in seconds, use thousands of templates, organize your media in one storage and search them quickly and intelligently',
    analytics:
      'Discover key insights on your social profiles and share them with your customers through customized reports',
    discover:
      "Losing hours searching for ideas on Google is the past. Now in 1 click you can find new trending content ideas on the topics you want to talk about and monitor your competitors' communications",
  },

  misc: {
    signin: 'Login',
    signup: 'Signup',
    name: 'Name',
    connectedBy: 'Connected by',
  },
  date: {
    startDate: 'Start date',
    endDate: 'End date',

    today: 'Today',
    yesterday: 'Yesterday',
    last7Days: '7 days',
    lastMonth: '30 days',
    last3Months: '3 months',
    last6Months: '6 months',
    last12Months: '12 months',
    previousDay: 'Previous day',
    previousWeek: 'Previous week',
    previousMonth: 'Previous month',
    previousQuarter: 'Previous quarter',
    previousYear: 'Previous year',
    selectDateFirst: 'Select a date first',

    months: {
      january: 'January',
      february: 'February',
      march: 'March',
      april: 'April',
      may: 'May',
      june: 'June',
      july: 'July',
      august: 'August',
      september: 'September',
      october: 'October',
      november: 'November',
      december: 'December',
    },
  },

  limitUser: {
    cannot_add_all_social: 'You cannot add all socials because you would exceed the limit of your plan',
    max_reached_social_accounts: 'You have reached the maximum number of connected accounts in your plan ',
    max_reached_generation: "You've reached the maximum number of generations in your plan",
    max_reached_storage: "You've reached the maximum storage space in your plan",
    max_reached_collaborators: "You've reached the maximum number of collaborators in your plan",
    max_reached_post: "You've reached the maximum number of posts published in your plan",
    warning_reaching_max_post: (postPlanned, postPublished, postTotal) =>
      `This post may not be published because you are reaching the maximum number of posts in your plan\n 
      You have ${postPlanned} scheduled posts and ${postPublished} published posts, for a total  ${
        postPlanned + postPublished
      }/${postTotal} posts`,
    max_reached_post_in_social: (social) =>
      `You have reached the maximum number of posts published on ${social} in your plan`,
    max_reached_post_details: (postPublishing, postPublished, postTotal) =>
      `The posts you are creating exceed the limits of your plan.\nYou are creating ${postPublishing} of ${
        postTotal - postPublished
      } available posts`,
  },

  referral: {
    referral: 'Earn',
    step_1: 'Share your referral link with friends, colleagues, followers, partners, customers…',
    step_2: 'The invited person joins Asters and subscribes to one of the paid plans',
    step_3: 'You earn €10 credit and your invited person earns €10',
    step_4: 'Share again and earn money!',
    how_it_works: 'How does it work?',
    copy_link: 'Copy link',
    by_joining: 'By participating in the referral program, you are accepting our',
    terms_and_conditions: 'Terms and Conditions',
    share_via_social: 'Share on social media',
    progress: 'Progress',
    link_visiter: 'Link views',
    subscriber: 'Signups',
    subscriber_plan: 'Subscribers',
    credit: 'Credits',
    preText:
      'Register on Asters and save time managing your social medias thanks to the power of artificial intelligence. Here is my invitation link:',
  },

  sidebarNavigation: {
    post: 'Post',
    discover: 'Discover',
    dashboard: 'Dashboard',
    calendar: 'Calendar',
    media: 'Media',
    analytics: 'Analytics',
  },

  updateSocial: {
    how_to_update: 'How to refresh tokens',
    onese_closed: 'Once this message is closed, you will see your accounts flash as in the example below.',
    clik_on_icon: 'Clicking on the icon will open a window of the selected social where you will have to log in again.',
    days_to: 'days to ',
    change_pass: 'if you change password ',
    ok_understood: 'Ok, understood',
  },

  postType: ['All', 'Scheduled', 'Published', 'Not published'],
  cancelPlan: {
    cancel: 'cancel',
    cancelTotalPlan: 'Cancel subscription',
    cancel_done_whith_success: 'Subscription cancellation completed successfully',
    resons: [
      'Extenuating circumstances (e.g. covid-19, budget cuts, etc...)',
      'Too complicated',
      'Too expensive',
      'Does not have the features I need',
      'Bugs or usability issues',
      'Other reasons',
    ],
    conditions1:
      'I understand that by cancelling my subscription now, I will continue to have access to the features provided in my current plan until',
    conditions2:
      'I understand that the moment the deletion becomes effective, I will lose access to all the features in my current plan, and any planned posts will not be published.',
    message1: 'We are sorry to see you go!',
    message2: 'At Asters we are always trying to improve!',
    message3: 'Would you mind sharing the reason for your cancellation?',
    message4: 'I am cancelling my subscription because:',
    howCanWeImprove: 'How can we improve?',
    cancelPlan: 'Subscription cancellation',
    changedMyMind: 'I changed my mind, go back',
    youPlanWillRemainUntil: 'Your current subscription will remain active until',
  },
  logIn: {
    reset: 'Reset',
    privacyPolicy: 'privacy policy',
    welcomeYouAre: {
      1: 'A warm welcome 👋',
      2: 'To best personalize your experience, select the type in which you most reflect yourself.',
    },

    welcome1: 'Congratulations for joining Asters 🎉',
    welcome2: 'We sent an email to your address with a link to activate your account.',
    welcome3: 'To be sure, check the spam folder as well.',
    error1: 'An error occurred.',
    error2: 'Try again in a few moments.',
    returnToSigning: 'Back to sign-up',
  },

  intercom: {
    iHadAProblemWithAPost: 'I had a problem with a post',
  },

  singleWord: {
    from: 'From',
    seconds: 'seconds',
    generate: 'Generate',
    post: 'Post',
    translate: 'Translate',
    retry: 'Retry',
    at: 'at',
    next: 'Next',
    confirm: 'Confirm',
    cancel: 'Cancel',
    and: 'and',
    video: 'Video',
    image: 'Image',
    images: 'Images',
    media: 'Media',
    medias: 'Medias',
    reel: 'Reel',
    on: 'on',
    are: 'are',
    is: 'is',
    minimum: 'minimum',
    maximum: 'maximum',
    of: 'of',
    for: 'for',
    height: 'height',
    width: 'width',
    aspectRatio: 'aspect ratio',
    codecAudio: 'codec audio',
    codecVideo: 'codec video',
    bitrate: 'bitrate',
    bitrateVideo: 'bitrate video',
    bitrateAudio: 'bitrate audio',
    fps: 'fps',
    reelThumbnail: 'reel thumbnail',
    colorSpace: 'color space',
    story: 'Story',
    gif: 'Gif',
    thumbnail: 'Thumbnail',
    manage: 'Manage',
    delete: 'Delete',
    edit: 'Edit',
    reuse: 'Reuse',
    download: 'Download',
    labels: 'Labels',
    goToPost: 'Go to post',
    more: 'More',
    others: 'Others',
    pending: 'Pending',
    accepted: 'Accepted',
    declined: 'Declined',
    with: 'with',
    carousel: 'Carousel',
    typology: 'Typology',
    format: 'Format',
    text: 'Text',
    photo: 'Photo',
  },

  tableGraphCol: {
    date: 'Date',
    likes: 'Likes',
    comments: 'Comments',
    saved: 'Saved',
    reach: 'Reach',
    interaction_rate: 'Interaction rate',
    plays: 'Plays',
    shares: 'Shares',
    impressions: 'Impressions',
    link_clicks: 'Link clicks',
    interactions_rate: 'Interactions rate',
    interactions_rate_with_link_clicks: 'Interactions rate with link clicks',
    video_views: 'Video views',
    videos_views_clicked_to_play: 'Videos views clicked to play',
    add_to_favorites: 'Add to favorites',
    views: 'Views',
    total_time_watched: 'Total time watched',
    full_video_watched_rate: 'Full video watched rate',
    average_time_watched: 'Average time watched',
    clicks: 'Clicks',
    engagement_rate: 'Engagement rate',
    video_duration: 'Video duration',
    engagement: 'Engagement',
  },

  notifyCategory: {
    settings: 'Settings',
    billing: 'Billing',
    publish: 'Publish',
    discover: 'Discover',
    inbox: 'Inbox',
    autopilot: 'Autopilot',
  },
  notifyTexts: {
    showUnreadNotifications: 'Show unread notifications',
    markAllAsRead: 'Mark all as read',
    notificationSettings: 'Notification settings',
  },
  emptyNotify: 'There are no notifications',
  emptyNotifyForCategory: 'There are no notifications to read for this category',
  emptyNotifyForCategories: 'There are no notifications to read for these categories',
  notifications: 'Notifications',
  error: {
    analyticsNotEnoughUsers:
      'Not enough users to generate data. The social account needs to have at least 100 followers',
    colaboratorError: 'An error occurred while adding the collaborator',
    analyticsPeriodErrorSocial:
      '{provider} allows you to access data for this metric only for the last {days} days. Try changing the reference period.',
    analyticsPeriodError:
      'The data for the selected period is not available, try to change the period with minor dates range',
    noPermissionTo: 'You do not have permission to',
    renameMedia: 'rename the media',
    uploadMedia: 'upload media',
    deleteMedia: 'delete media',
    createFolder: 'create a folder',
    deleteFolder: 'delete the folder',
    manageMedia: 'manage media',
    manageFolders: 'manage folders',
    useEditor: 'use the editor',
    newProject: 'New project',
    myProjects: 'My projects',
    noTemplates: 'There are no templates',
    noProjects: 'There are no projects',
    templates: 'Templates',
    seeCalendar: 'see the calendar',
    editPost: 'edit the post',
    publishPosts: 'publish posts',
    generateText: 'generate text',
    manageDrafts: 'manage drafts',
    createDraft: 'create a draft',
    addSocials: 'add socials',
    deleteSocials: 'delete socials',
    manageReport: 'manage a report',
    viewReport: 'view a report',
    shareReport: 'share a report',
    searchContent: 'search content',
    manageDiscover: 'manage discover',

    reportAProblem: 'Report a problem',
    aiServerError: 'Tuning in progress, please try again later',
    lostConnection: 'You have lost the connection of some social profile',
    onlyVideoAllowedIn: 'Only video is allowed in ',
    mustBe: 'must be ',
    mustBeWithin: 'must be within ',
    mustBeUnder: 'must be under ',
    mustBeOver: 'must be over ',
    mustBeEqual: 'must be equal to ',
    theStoriesOf: 'The stories of ',
    theMaximumSize: 'The maximum size for ',
    theAcceptedExtensions: 'The accepted extensions for a ',
    allMediaSameRatio: 'All media in a carousel must have the same ratio',
    atLeastOneVariantMustBePresent: 'At least one variant must be present',
    alreadyWorkingOnThisPost: 'You are already working on this post ',
    checkSocialConnection: 'Check social connection',
    generation_placeholder: 'Your generations will be shown here',
    brand_voice_requirements: 'Select a Meta or Linkedin  social profile to use Brand Voice',
    brand_voice_not_included: 'Brand voice is not included in your plan',
    no_data_at_this_moment: 'No data available at this moment',
    noPostInSelectedPeriod: 'No published posts in the selected period',
    generic_error: 'An error occurred, try again later or contact support',
    verify_that_all_posting_requirements_are_correct: 'Verify that all posting requirements are correct',
    user_already_exist: 'User already exists',
    selectObjectiveFirst: 'Please select a goal first',
    updateConnection: 'Update connection to be able to publish',
    plese_check_the_conditions: 'Please check the conditions',
    error: 'Error',
    user_not_valid: 'Invalid email',
    password_not_valid: 'Invalid password',
    user_or_password_wrong: 'Invalid email or password',
    serverError: 'Server error',
    credentialError: 'Credential error',
    notAuthorized: 'Not authorized',
    notHavePermiseToGetRecord: "You don't have permission to access this resource",
    genericError: 'Generic error',
    malformedResponseServer: 'Malformed response from server',
    notEsistente: 'Post does not exist',
    urlNotFound: 'Post URL not found',
    urlNotYetAvailable: 'Post URL not yet available. It may take up to 5 minutes. Please try again later',
    cantHaveMoreThan25: 'You cannot have more than 25 groups, try eliminating some of them',
    addHashtagToGenerate: 'Add some text to your post in order to generate hashtags.',
    addTextToGenerate: 'Enter some keywords so you can generate your text.',
    nameCantSupass: 'Name cannot exceed 30 characters',
    selectObjectiveAndWrite: 'Add some text and select a goal for greater accuracy',
    selectProfile: 'Select at least one social profile in order to post',
    selectMedia: 'You need to add at least one media or text to all posts before publishing',
    somePostAreInError: 'Some posts show errors, please correct them before proceeding',
    SentAnEmailForActivation:
      'We have sent an email to your address with the link to activate your account. To be safe, check the spam folder as well',
    SentEmailForPasswordRecovery:
      'We sent an email to your address to recover your password. To be safe, check your spam folder as well',
    unableToFindMail: 'Email not found, unable to proceed with request',
    acceptTerm: 'Acceptance of terms of use required',
    acceptPrivay: 'Acceptance of privacy policy mandatory',
    cannotLoadDraft: 'Loading of your post drafts failed',
    postDontExist: 'Post does not exist',
    eliminationFaild: 'Elimination failed',
    unknownError: 'Unknown error',
    insertPixlLeght: 'Enter height and width in pixels',
    errorOnChangePasseword: 'Error in changing password',
    allFildRequired: 'All fields are required',
    didentChangedPlan: 'Attention, you have not made any changes to your plan',
    canNotHaveNegativeQuantity: 'You cannot have negative quantities',
    invalidEmail: 'Invalid email',
    invalidPassword: 'Invalid password',
    invalidName: 'Invalid name',
    authFaild: 'Authorization rejected. Try again or contact support via live chat',
    mailNotSended: 'Error when sending confirmation email.',
    errorAccount: 'Wrong account',
    confirmMail: 'Confirm your email to access this feature',
    writeSomeThing: 'Write something in the comment before adding it to the post',
    noChangedReveald: 'No changes detected',
    youFinishedYourTrial: 'Your free trial period expired. Please confirm your email to access this feature.',
    toManyRequest: 'Too many requests',
    weSentAMail:
      'We have sent an email to your email address with a link to activate your account.\nFor security, check your spam folder as well',
    downlordFaild: 'File download failed',
    autorizationFails: 'Authorization declined. Please try again or contact support via live chat',

    obligatoryMedia: 'Mandatory media for',
    obligatoryText: 'Mandatory text for',
    obligatoryTitle: 'Mandatory title for',
    MaxMedia: 'can post max',
    maxHashtag1: 'can add max',
    maxHashtag2: 'hashtags in post caption',
    dontConsentFotoAndVideo: 'does not allow publishing photos and videos in the same post',
    errorSelectable: 'Data retrieval error',
    selectTimezoneFirst: 'Select a timezone',
    addNameOfWorkspace: 'Please add name to this workspace',
    addTimezoneOfWorkspace: 'Please add timezone to this workspace',
    completeFieldsFirst: 'Please complete empty fields',
    descriptionMaxChars: 'allows a maximum character description of',
    dontHavePermissionToPerformAction: 'You do not have permission to perform this action permission needed : ',
    paymentDeclined: 'Payment has been declined',
    subscriptionPayment: 'Error during subscription payment',
    folderAlreadyExists: 'Folder already exists in this path',
    dontHavePermissionToAccessFeature: 'You do not have permission to use this feature',
    addAtLeastOneSocialAccount: 'Make sure you have added at least one social account',
    retrieveDataFromSite: 'Error getting data from site',
    selectAToneOfVoice: 'Please select a tone of voice first',
    roleAlreadyExists: 'Role name already exists',
    roleNotFound: 'Role not found',
    validPassword: 'Enter a valid password',
    validName: 'Enter a valid name',
    roleStillUsed: 'The role is assigned to other collaborators, change their role first.',
    needText: 'requires text in order for the post to be published',
    needMedia: 'requires media in order for the post to be published',
    needTextOrMedia: 'requires text or media in order for the post to be published',
    needTextAndMedia: 'requires text and media in order for the post to be published',
    descriptionTooLong: (social, maxLength) =>
      `${social} doesn't allow a description of this length. \n (max ${maxLength})`,
    titleTooLong: (social, maxLength) => `${social} doesn't allow a title of this length. \n (max ${maxLength})`,
    emptyDraft: 'Draft cannot be empty!',
    emptyPost: 'Post cannot be empty!',
    socialExist:
      'One or more social profiles are already present in another workspace. By proceeding, they will be disconnected and connected to this workspace.',
    noBusinessSocial: 'There is no Instagram Business profile associated with a Facebook page.',
    missingAppsumoAuthCode: `Missing 'code' query-param from url. Are you sure you come from appsumo?`,
    genericErrorWhileFetchingLicenseKey: "Something went wrong during license key retrieval",
    specificErrorWhileFetchingLicenseKey : "While retrieving the license key we got the following error"
  },
  socialError: {
    maxPostToday: 'allows publishing a maximum of posts per day:',
    maxCharDescription: 'allows a maximum character description of',
    maxCharTitle: '',
    obligatoryMedia: 'Mandatory media for',
    maxMedia: 'Instagram allows you to publish a maximum of media equal to',
    formatType: 'allows the following formats:',
    maxSizeImg: 'expects images to be under',
    maxSizeVideo: 'expects videos to be under',
    minMaxVideoDuration: 'requires videos to have a duration between',
    videoCodec: 'allows the following video codecs:',
    minMaxVideoFps: 'requires the FPS (frames per second) to be between',

    xCarousel: `${commonToAll.socials.xTwitter} expects carousels to have: a maximum of 4 medias and cannot container video and gif at the same time`,
  },
  socialWarning: {
    minMaxImgWidth: "might resize your image if it doesn't fall within the width range of",
    rangeImgRatio: "might resize your image if it doesn't comply with the aspect ratio between",
    specificImgRatio: '',
    minMaxVideoWidth: "might resize your video if it doesn't fall within the width range of ",
    rangeVideoRatio: "might resize your video if it doesn't comply with the aspect ratio between ",
    specificVideoRatio: '',
    minMaxVideoFps: 'might modify the video if the fps (frames per second) are not between',
    rangeRatio: "might resize your video/image if it doesn't comply with the aspect ratio between ",
    specificRatio: "might resize your video/image if it doesn't comply with the following aspect ratio:",
    specificMultipleImgRatio: "might resize your image if it doesn't comply with the following aspect ratios:",
    possibleWrongDisplay: "might not display your video properly if it doesn't comply with these video codecs:",
  },
  success: {
    settedTimezone: 'Successful timezone setted',
    eliminationSuccess: 'Successful post deletion',
    eliminationDraftSuccess: 'Successful draft deletion',
    theModalCreated: 'Template successfully created',
    modalEliminated: 'Template successfully deleted',
    modalAdded: 'The model has been added to your post',
    nameChanged: 'Name successfully changed',
    generationInProgress: 'Generation in progress...',
    socialProfileAdded: 'Social profiles successfully added',
    eliminationSuccses: 'Successful deletion',
    envImgUpdated: 'Workspace image successfully updated',
    envNameUpdated: 'Workspace name successfully updated',
    envCreated: 'Workspace successfully created',
    postPlanned: 'Post successfully scheduled',
    postSavedInDraft: 'Post successfully saved to draft',
    postGenerated: 'Post successfully generated',
    cancelazioneFolder: 'Folder and files successfully deleted',
    passwordChanged: 'Password successfully changed',
    dataSaved: 'Data successfully saved',
    planCanceled: 'Subscription successfully cancelled',
    nameModified: 'Name successfully changed',
    creditCardAdded: 'Credit card added correctly',
    weSendEmailWhithNewEmail:
      'We sent an email to your new email address.\nClick the link inside to confirm the new email',

    passworldUpdated: 'Password successfully updated',
    accountOf: 'account of',
    accountLinkedInOf: 'LinkedIn account of',
    accountXOf: 'X account of',
    accountYouTubeOf: 'YouTube account of',
    accountTikTokOf: 'TikTok account of',
    isUpdated: 'has been updated',
    profileSocialEliminated: 'Social profile successfully deleted',
    mailSended: 'Confirmation email successfully sent',
    emailUpdated: 'Email updated with success',
    accontIsUpdated: {
      one: 'Account ',
      two: ' of ',
      three: ' has been updated ',
    },
    languageChanged: 'Language successfully changed',
    reportNameChanged: 'Report name successfully changed',
    reportLogoChanged: 'Report logo successfully changed',
  },
  info: {
    shortening_link: "We're shortening your link, please wait...",
    copied_error_in_clipboard:
      'Error copied to clipboard, contact support via live chat and paste the error code you just copied',
    exit_without_confirmation: 'Sei uscito senza confermare',
    insert_at_least_4_words: 'Insert at least 4 words',
    selectReason: 'Please select a reson',
    onlyDemo: 'This is only a demo',
    cancelOrConfirmPin: 'You have to confirm or cancel the previous pin',
    userNotFoundInstagram: `User not found on ${commonToAll.socials.instagram} please rewrite the username`,
    haveToEnterUsername: 'You have to enter a username',
    enterLink: 'Enter a link',
    generationAttempt: 'New generation attempt in progress ...',
    blockerPreventing: 'Some blocker prevents us from generating on this website',
    gettingDataFromSite: 'Getting data from site in progress...',
    roleUpdated: 'Role updated',
    roleCreated: 'Role created',
    linkCopied: 'Link copied',
    onUser: 'on user',
    userAlreadyExistsInCollaborator: 'User already existing in collaborator :',
    arrived: 'Arrived',
    selectWorkspace: 'Select a workspace',
    enterName: 'Enter a name for the site',
    enterTitle: 'Enter a title for the site',
    enterDescription: 'Enter a description for the site',
    updating: 'Updating',
    youHaveBeenInvitedToTheWorkspace: 'You have been invited to the workspace',
    completeYourRegistrationOn: 'Complete your registration on',
    register: 'Register',
  },
  warning: {
    comingSoon: 'Coming Soon',
    undefined: 'Undefined',
    confirmOrCancelBeforeAddingTag: 'Conferm the action or cancel it before adding another tag',
    linkAlreadyPresent: 'This link is already present',
    cantCommentTextAlreadyCommented: 'You cannot comment on a text that has already been commented ',
    problemSettingMetadata: 'There was a problem changing the metadata',
    notValidUrl: 'The link you entered is invalid',
  },
  loading: {
    loadingCompleted1: 'Loading completed,',
    loadingCompleted2: 'please wait a few seconds',
  },
  messages: {},
  placeholder: {
    logInWithFacebook: 'Login with Facebook',
    logInWithGoogle: 'Login with Google',
    subscribeInWithFacebook: 'Signup with Facebook',
    subscribeInWithGoogle: 'Signup with Google',
    dontHaveAnAccountYet: 'New to Asters?',
    alreadyHaveAnAccount: 'Already have an account?',
    subscribeNow: 'Signup now!',
    iHaveReadUnderstoodAndAcceptedThe: 'I have read, understood and accepted',
    siteName: 'Site name (domain)',
    siteTitle: 'Site title',
    siteDescription: 'Site description',
  },
  articles: {
    the: 'the',
    there: 'the',
  },
  confirm: {},
  labels: {
    remember: 'Remember me',
    recoverPassword: 'Forgot password?',
    signIn: 'Login',
    subscribe: 'Signup',
    orLogInUsing: 'Or login with',
    OrSignUpUsing: 'Or signup with',
  },
  status: {
    all: 'All',
    notPublished: 'Not published',
    draft: 'Draft',
    planned: 'Scheduled',
    error: 'Publishing error',
    published: 'Published',
    publishing: 'Publishing in progress',
  },
  analyticsPage: {
    tableGraphEmptyState: 'There is no data to display for the selected period',
    tableGraphEmptyStateLimit:
      'The time limit imposed by the social network has been reached \n Please try again later',
    analytics: 'Analitycs',
    myReports: 'My reports',
    yourReportNotice: 'Your report',
    yourReportNoticeIsReady: 'is ready!',
    currentlyDevelopingComingSoon: 'We are currently developing this section and it will be coming soon!',
    visitRoadMap:
      'Visit our public roadmap by clicking the button below to monitor the development status, vote on features we are considering or planned, and suggest useful new features. \n We value your opinion, and we also organize future developments according to the most-voted requests among all Asters users.',
    publicRoadMap: 'Public roadmap',
    addMetric: 'Add metric',
  },
  indexPage: {
    index: 'Index',
    currentlyDevelopingComingSoon: 'We are currently developing this section and it will be coming soon!',
    visitRoadMap:
      'Visit our public roadmap by clicking the button below to monitor the development status, vote on features we are considering or planned, and suggest useful new features. \n We value your opinion, and we also organize future developments according to the most-voted requests among all Asters users.',
    publicRoadMap: 'Public roadmap',
  },
  mediaPage: {
    showResults: 'Show results',
    images: 'Images',
    fromLatest: 'From the latest',
    addFilters: 'Add filters',
    typeHere: 'Type here',
    deselectAll: 'Deselect all',
    openInEditor: 'Open in editor',
    warningTagEditor: 'You are about to resize an image with tags, if you continue you will lose the image tags',
    media: 'Media',
    loadFile: 'Upload',
    createFolder: 'Create folder',
    openEditor: 'Open editor',
    storageSpaceAvailable: 'Storage:',
    storageSpaceUsed: 'used of',
    folders: 'Folders',
    file: 'File',
    rename: 'Rename',
    canModifyOneAtTime: 'You can only edit one image at a time',
    move: 'Move',
    instal: 'Download',
    openProject: 'Open project',
    selectAProject: 'Select a project',
    selectATemplate: 'Select a template',
    selectSocial: 'Choose a social channel',
    selectFormat: 'Choose a format',
    create: 'Create',
    where_you_want_to_move_file: 'Where do you want to move your selected files ?',
    where_you_want_to_move_folder: 'Where do you want to move your selected folder ?',
    askDelete: 'Are you sure you want to delete?',
  },

  draftModal: {
    yourDrafts: 'Your drafts',
    noDraft: 'No drafts',
    noDateInserted: 'No date inserted',
  },

  calendarPage: {
    calendar: 'Calendar',
    queue: 'Queue',
    profiles: 'Profiles',
    noProfiles: 'No profiles',
    noPostsOnThisDay: 'No posts on this day',
    manual: 'Manual',
    autopilot: 'Autopilot',

    reuse: 'Recycle',
    preview: 'Preview',
    previewInProgress: 'Preview in progress',
    sureToRemovePost: 'Are you sure you want to delete this post?',
    goToPlaned: 'Drafts',
    weekdaysMin: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    today: 'Today',
    selectAll: 'Select all',
    deselectAll: 'Deselect all',
    cancel: 'Cancel',
  },
  DashboardPage: {
    createSocial: 'Connect social',
    dashboard: 'Dashboard',
    planedPost: 'Scheduled posts',
    publishedPost: 'Published posts',
    notPublishedPost: 'Not published posts',
    generatedText: 'AI caption generated',
    connectedSocials: 'Social channels connected',
    socialPosts: 'Social posts',
    teamMember: 'Team members',

    generatedHashtag: 'AI-generated hashtags',
    generatedImage: 'AI generated images',
    increaseLimit: 'Upgrade',
    teamMembers: 'AI analytics',
    discoverArticle: 'AI discover article found',
    comingSoon: 'Coming soon',
    loadFile: 'File Upload',
    addSocial: 'Connect Social',
    draft: 'Drafts',
    enviromentManager: 'Manage workspace',
    accountManager: 'Manage account',
    blog: 'Blog',
    guideAndTutorial: 'Help center',
    community: 'Community',
    publicRoadMap: 'Public roadmap',

    yourEnvironment: 'Your workspace',
    yourOrganization: 'Your organization',
    // -----------------------------------------------

    video_review: 'Video review',
    tell_us_what_you_think: 'Tell us what you think of Asters in a video review and',
    earn_50: 'earn €50',
    lets_go: 'Let’s go!',

    receve_from_global: 'Receive from global',
    data_and_time: 'Date and time:',
    group_name_cant_be_empty: 'Group name cannot be empty',
    group_text_cant_be_empty: 'Group text cannot be empty',
    home: 'Home',
    step: 'Step',
    select_at_least_one_social_to_add: 'Select at least one social profile to add',
    select_your_preview_link: 'Select your preview link',
    upload_image: 'Upload media',
    upload_media: 'Upload media',

    are_you_sure: 'Are you sure you want to connect a social profile? All changes to the current post will be lost',
    cancel: 'Cancel',
    yes_i_am_sure: 'Yes, I am sure',
  },
  AiDiscoverPage: {
    titlePage: 'Discover',
    placeholderPageText:
      'Wasting hours Googling for ideas is the past. With AI Discover in 1 click you can find new trending content ideas on the topics you want to talk about and monitor your competitors communications.',
    placeholderLoadingText: 'We are verifying your permissions',
    placeholderNoPermissions: 'You do not have the necessary permissions, contact che workspace admin',
    word: {
      topic: 'Topic',
      source: 'Source',
      private: 'Private',
      pluralPrivate: 'Private',
      shared: 'Shared',
      pluralShared: 'Shared',
      search: 'Search',
      comment: 'Comment',
    },
    deleteFilters: 'Remove filters',
    addComment: 'Enter your comment',
    lastUpdate: 'Last update: ',
    loadArticles: 'Load another 24 articles',
    boardCard: {
      createBoard: 'Create board',
      saveOnBoard: 'Save to board',
      newBoard: 'New board',
      adviseNewBoard: 'Press new board to create',
      adviseSearchBoardNotFind: 'The board you are looking for does not exist',
      articles: 'Articles',
      nameBoard: 'Name Board',
      createdBy: 'Created by:',
      yourBoard: 'Your Board',
      noBoardText: 'No boards available',
      yourFistBoard: 'Your first board',
      createNewBoard: 'Create your first board',
      selectionAll: 'Select all',
      deselectAll: 'Deselect all',
      Manage: 'Manage',
      Delete: 'Delete',
    },
    boardCardSucces: {
      createBoard: 'Board created successfully',
      saveArticle: 'Article saved successfully',
    },
    boardCardError: {
      createBoard: 'Error creating board.',
      createComment: 'Error in creating the comment',
      saveArticle: 'Error saving article to the board:',
    },
    boardCardWarning: {
      createDoubleBoard: 'You cannot create two boards with the same name and type',
      noComment: 'You cannot comment on a portion of text that has already been commented on',
      cantCreateBoardPrivate: 'You cannot create a private board in this workspace',
    },
    carousel: {
      trendingArticles: 'Trending articles',
      trendingArticleFor: 'trending articles for',
      noTrendsAvailable: 'No trends available',
      noBoardsAvailable: 'No boards available',
      followYourFirstTopic: 'Follow your first topic',
      createYourFirstBoard: 'Create your first board',
      yourBoards: 'Your boards',
      showAllArticles: 'Show all articles',
      showAllBoards: 'Show all boards',
      refreshArticles: 'Refresh articles',
    },
    followPage: {
      followArticle: 'Topics followed',
      followArticleFor: 'Articles followed for',
      noFollowAvailable: 'No articles followed',
      followYourFirstTopic: 'Follow your first topic',
    },
    boardPage: {
      noArticle: 'No articles saved',
      SearchFirstArticle: 'Search for your first article',
    },
    linkCopiedInClipboard: 'Link copied in clipboard',
    copyLink: 'Copy Link',
    readFullArticle: 'Read full article',
    imageNotFound: 'Preview image not available',
  },
  postPage: {
    username: 'Username',
    usernameRequired: 'Username is required',
    yourText: 'Your text',
    noMediaInserted: 'No media inserted',
    noTextInserted: 'No text inserted',
    confirmPostElimination: 'Do you confirm that you want to proceed with the deletion of the post?',
    confirmPostEdit: 'A working post is currently already present within the posting area.\nBy proceeding to modify this specific post, you will lose the content of the working post.',
    labelsSection: {
      applyLabels: 'Apply labels',
      createOrApplyLabel: 'Create label or apply existing label',
      alreadyAdded: 'The label is already present among those selected',
      addHere: 'Add here',
      noLabelIsPresent: 'No label is present',
      manageLabels: 'Manage labels',
      labelRename: (min, max) => `The label must have at least ${min} letter and a max of ${max} characters`,
    },
    noMedia: 'No media',
    noText: 'No text',
    changeDescription: 'Write here your description (optional)',
    changeDescriptionYouTube: 'Description',
    daysAgo: 'days ago',
    more: 'more',
    utm_road: 'UTM',
    whatAreUtm: 'What are UTM?',
    expand_link: 'Expand link',
    short_link: 'Short link',
    instagramStories: {
      warning: {
        maxStoriesMedia: 'You can only select one media at a time for Stories',
      },
    },
    your_workspace: 'Your workspace',
    currentWorkspace: 'Current workspace',
    select_the_workspace: 'Select the workspace you would like to move the social profile to',
    switch_accounts: 'Move profile',
    switch_accounts_success: 'Social profile successfully moved',

    generalExtra: {
      noCarouselInstagramLocation: 'It is not possible to include a location in an Instagram carousel post',
      addAImageForUseTheTags: 'Add an image to use the tags',
      noTagsForVideos: 'It is not possible to add tags to videos',
    },

    instagramExtra: {
      previewDisplayInProfileFeed: 'Preview display in profile feed',
    },

    tiktokExtra: {
      follower: 'Followers',
      friends: 'Friends',
      private: 'Private',
      public: 'Public',
      duet: 'Duet',
      confirmedTimestamp: 'Cover successfully confirmed',
      whoCanWatchThisVideo: 'Who can watch this video',
      allowUsersTo: 'Allow users to:',
      discloseVideoContent: 'Disclose post content',
      letOthersKnow: 'Let others know this post promotes a brand, product or service.',
      yourBrand: 'Your Brand',
      promoteYourselfOrBusiness: 'You are promoting yourself or your own business.',
      brandedContent: 'Branded content',
      inPaidPartnership: 'You are in a paid partnership with a brand.',
      toDisclose: 'To disclose your post as branded content, your post must be set to “Public”.',
      brandedContentCantBePrivate: 'Branded content posts cannot be set to Private.',
    },
    youtubeTubeExtra: {
      privacy: 'Privacy*',
      public: 'Public',
      private: 'Private',
      unlisted: 'Unlisted',
      title: 'Title',
      enterTitle: 'Enter a title',
      description: 'Description',
      enterDescription: 'Enter a description',
      category: 'Category*',
      selectCategory: 'Select category',
      audience: 'Audience*',
      isSuitableForChildren: 'Suitable for children',
      advancedOptions: 'Advanced options',
      playlist: 'Playlist',
      selectPlaylist: 'Select playlist',
      singlePlaylistSelected: 'playlist selected',
      multiplePlaylistSelected: 'playlists selected',
      extras: 'Extras',
      thumbnail: 'Thumbnail',
      addThumbnail: 'Add thumbnail',
      thumbnailConfirm1: 'Do you want to change the thumbnail by selecting a frame from the video?',
      thumbnailConfirm2: 'The previous image will be deselected. ',
      conferma: 'Confirm',
      tag: 'Tag',
      enterTag: 'Enter your tags here',
      embeddable: 'Embeddable',
      notifySubscribers: 'Notify Subscribers',
      adultsOnly: 'Adults Only',
      subtitles: 'Subtitles',
      addSubtitles: 'Add subtitles',
    },
    confirm: 'Confirm',
    you_are_adding: 'You are uploading more than 50MB, would you like to proceed?',
    // -----------------------------------------------
    lengthGeneration: 'Length generation',
    textShort: 'Testo troppo corto',
    textMedium: 'Testo medio',
    textLong: 'Testo troppo lungo',

    // -----------------------------------------------
    addMediaFirst: 'Please select a media first',
    savedSucces: 'Caption added successfully',
    saveDraft: 'Save draft',
    carousel: 'Selected',
    editor: 'Editor',
    extra: 'Extra',
    socials: 'Socials',
    addComent: 'Add comment...',
    likes: 'Likes',
    comments: 'Comments',
    viewAll: 'View all',
    like: 'Like',
    comment: 'Comment',
    share: 'Share',
    download: 'Download',
    eightComments: '8 comments',
    addComment: 'Add a comment...',
    visualizations: '1000 views 12 months ago',
    reposts: 'Reposts',
    send: 'Send',
    suggestedDate: 'Suggested time',
    loadingData: 'Loading data...',
    under100Follower: 'Instagram profile must have at least 100 followers',

    difficultyText: {
      dataSet: {
        basic: 'Basic level for those who are still learning to read.',
        easy: 'Very easy to read.',
        ideal: 'Ideal for average readers.',
        fairly: 'Fairly difficult to read.',
        hard: 'Too hard to read for the majority of readers.',
      },
      approximate: 'This is an approximate grade level needed to comprehend your post, based on',
      automated: 'automated readability index',
      it: 'It roughly corresponds to the following age ranges:',
      grade: 'Grade',
      age: 'Age',
      description: 'Description',
      copywriters: 'Copywriters and journalists typically aim for a grade level of',
      lower: '8 or lower.',
      this: 'This is a good measuring stick for social media posts as well.',
    },

    //------------------ Da qui  --------------------------------------------------------
    sfortunatly_we_cant:
      'Note: Instagram does not allow using autocomplete or tagging private profiles. Type the correct tag and it will work.',
    //------------------------------------------------------------------------------

    freeTrial: 'Free trial: ',
    remainingDayes: ' days remaining',
    generationAi: 'Generate',
    update: 'Update',
    cancel: 'Cancel',
    createGroup: 'Create group',
    reel: 'Reel',
    yourDrafts: 'Your drafts',
    sureToDeleteDraft: 'Are you sure you want to delete this draft?',
    createPost: 'Post',
    publicNowPost: 'Publish now',
    showFullLink: 'Expand',
    shortFullLink: 'Shorten',
    textOfYourPost: 'Post caption',
    insertHereText: 'Write here your post caption',
    insertHereTextYouTube: 'Title',
    textGeneration: 'Generate AI captions',
    hashtagGeneration: 'Generate AI hashtags',
    insertTextToGenerateText: 'Write some keywords here on the topic you are talking about',
    insertLinkToGenerateText: 'Insert here the link',
    generate: 'Generate',
    models: 'Templates',
    labels: 'Labels',
    writeIn: 'Write in',
    generateIn: 'Generate in',
    visibility: 'Exposure',
    event: 'Event / Holiday',
    utility: 'Useful info',
    promo: 'Offer / Discount',
    contest: 'Contest / Giveaway',
    media: 'Media',
    text: 'Caption',
    add: 'Add to post',
    delete: 'Delete',
    all: 'All',
    caption: 'Caption',
    hashtag: 'Hashtag',
    modalName: 'Template name',
    modeltype: 'Template type',
    modalText: 'Template caption',
    createModal: 'Create template',
    search: 'Search',
    public: 'Publish',
    publicNow: 'Publish now',
    plan: 'Schedule',
    saveInDraft: 'Save as draft',
    dateAndTime: 'Day and time',
    social: 'Social',
    selectDateAndTime: 'Choose day and time',
    objective: 'Goal',
    generationRemaind: 'AI-generations left',
    fromReelToPost: 'Reel switched to post',
    fromPostToReel: 'Post switched to reel',
    revealLanguage: 'Detect language',

    language: {
      AR: 'Arabic',
      BG: 'Bulgarian',
      CS: 'Czech',
      DA: 'Danish',
      DE: 'German',
      EL: 'Greek',
      EN: 'English',
      ES: 'Spanish',
      ET: 'Estonian',
      FI: 'Finnish',
      FR: 'French',
      HU: 'Hungarian',
      ID: 'Indonesian',
      IT: 'Italian',
      JA: 'Japanese',
      KO: 'Korean',
      LT: 'Lithuanian',
      LV: 'Latvian',
      NB: 'Norwegian',
      NL: 'Dutch',
      PL: 'Polish',
      PT: 'Portuguese',
      RO: 'Romanian',
      RU: 'Russian',
      SK: 'Slovak',
      SL: 'Slovenian',
      SV: 'Swedish',
      TR: 'Turkish',
      UK: 'Ukrainian',
      ZH: 'Chinese',
    },
    globalEdit: 'Global edit',
    basic: 'Basic',
    advanced: 'Advanced',
    texts: 'Texts',
    extraSelectNotAvailable: 'Not available',
    extraFirstComment: 'First comment',
    collaborationsStatus: 'Collaborations status',
    collaborators: 'Collaborators',
    collaboratorsPlaceholder: 'Add collaborators',
    collaboratorsInfo:
      'If he accepts, his username will be added to the post. The post will be shared with your followers and displayed on your profile',
    alreadyPresentCollaborator: 'This collaborator is already present ',
    extraLocation: 'Location',
    extraPaidSponsor: 'Paid Partnership',
    extraBrandWarning: 'Insert a media to be able to use the branded content ',
    extraBrand: 'Branded content',
    extraBrandTooltip:
      "Pages tagged in the post will receive a notification. They will be able to view the post's metrics and use them to create an ad.  You can type in the name of the sponsoring page or paste in its URL",
    extraBrandPlaceholder: 'Insert here the name or URL of the sponsor',
    extraLocationPlaceholder: 'Insert here the location',
    extraTag: 'Tag people',
    createNewPost: 'Create post',
    extraInsertHashtag: 'Insert hashtag in the first comment',
    extraAddFirstContent: 'Add first content',
    draftAll: 'All',
    draftPhotos: 'Photos',
    draftVideo: 'Videos',
    draftText: 'Text',
    fromLatest: 'From the latest',
    fromLeast: 'From the least',
    alphAZ: 'Alphabetical A-Z',
    alphZA: 'Alphabetical Z-A',
    searchSocial: 'Search socials',
  },
  textGenerator: {
    article: 'Article',
    subSection1: 'Profesional',
    subSection2: 'Friendly',

    brandVoice: 'Brand voice',
    brandVoiceTooltip1:
      'Asters AI Brand Voice combines the power of AI with the unique tone and style of your brand to generate precise, accurate and on-brand content.',
    brandVoiceTooltip2: 'Social platforms currently supported:',
    brandVoiceTooltip3:
      'Asters AI is analysing and learning the unique style of your brand for each linked social profile. This phase could last from a few hours to a few days.',
    brandVoiceTooltip4:
      'Brand Voice tuned in! Asters AI is ready to generate precise and accurate content about your brand. As new posts are published, Asters AI continues to learn and update to stay on-brand.',
    neutral: 'Neutral',
    textGenerator: 'Generate from keyword',
    linkGenerator: 'Generate from link',
    remainingGeneration: 'Remaining generations',
    optimalFor: 'Optimal for',
    optimalForFbIg: `Optimal for ${commonToAll.socials.facebook}/${commonToAll.socials.instagram}`,
    selectToneOfVoice: 'Select a tone of voice',
    selectObjective: 'Select a goal',
    goal: 'Goal',
    feedbackModalTitle: 'What did you dislike about the generation?',
    feedbackPlaceholder: "I didn't like the generation because ...",
    feedbackBrandVoiceDislike: "What didn't you like about Brand Voice?",
    feedbackBrandVoiceLike: 'What did you like about Brand Voice?',
    enterHere: 'Enter here',
    sendFeedback: 'Send feedback',
    thanksForFeedback: 'Thanks for the feedback!',
    somethingWentWrong: 'Ops, something went wrong',
    short: {
      tweet: {
        generic: 'Generic',
        tweet: 'Tweet',
        humSatirical: 'Humorous / Satirical',
        interrogative: 'Interrogative',
        news: 'News',
        inspMotivation: 'Inspiration / Motivation',
      },
      contest: {
        contest: 'Contest',
        engaging: 'Engaging',
        creative: 'Creative',
        competitive: 'Competitive',
        friendly: 'Friendly',
      },
      prodEvent: {
        prodEvent: 'Product / Event',
        explicative: 'Explicative',
        info: 'Informative',
        formal: 'Formal',
        creative: 'Creative',
      },
      quotes: {
        quotes: 'Quotes',
        inspiration: 'Inspiration',
        funny: 'Funny',
        motivational: 'Motivational',
        educational: 'Educational',
      },
    },
    medium: {
      discPromotion: {
        discPromotion: 'Discount / Promotion',
        inspiration: 'Inspiration',
        funny: 'Funny',
        descriptive: 'Descriptive',
        professional: 'Professional',
      },
      sesonHolidays: {
        sesonHolidays: 'Seasonal / Holidays',
        enthPlayful: 'Enthusiastic / Playful',
        celebFestive: 'Festive / Celebrative',
        infoEducational: 'Educational / Informative',
        gratitude: 'Gratitude',
      },
      contestGiveaway: {
        contestGiveaway: 'Contest / Giveaway',
        enthusiastic: 'Enthusiastic',
        engaging: 'Engaging',
        funny: 'Funny',
        exclusive: 'Exclusive',
      },
      highProduct: {
        highProduct: 'Highlight Product / Service',
        inspiration: 'inspiration',
        funny: 'Funny',
        descriptive: 'Descriptive',
        professional: 'Professional',
      },
      tipsInfo: {
        tipsInfo: 'Tips / Useful info',
        infoEducational: 'Educational / Informative',
        friendly: 'Friendly',
        explicative: 'Explicative',
        professional: 'Professional',
      },
    },
    longer: {
      informative: {
        informative: 'Informative',
        institutional: 'Institutional',
        objective: 'Objective',
        technician: 'Techniciam',
        thoughtful: 'Thoughtful',
      },
      educative: {
        educative: 'Educative',
        technician: 'Technician',
        motivational: 'Motivational',
        informative: 'Informative',
        academic: 'Academic',
      },
      reflexive: {
        reflexive: 'Reflexive',
        inspiration: 'Inspiration',
        authorative: 'Authorative',
        metaphorical: 'Metaphorical',
        motivational: 'Motivational',
      },
      promotion: {
        promotion: 'Promotion',
        professional: 'Professional',
        explicative: 'Explicative',
        persuasive: 'Persuasive',
        emotional: 'Emotional',
      },
    },
  },
  emoji: {
    suggested: 'Suggestions',
    smileys_people: 'Emoticons and people',
    animals_nature: 'Animals and nature',
    food_drink: 'Food and beverages',
    travel_places: 'Travel and places',
    activities: 'Activities and sports',
    objects: 'Objects',
    symbols: 'Symbols',
    flags: 'Flags',
  },
  courtesy: {
    subscriptionExpired: 'Subscription expired',
    title: 'Oh - oh!',
    description: 'Your subscription has expired.',
    cta: 'Contact support',
  },
  settings: {
    API: 'API',
    apiButton: 'API',
    remove_user_from_workspace: 'Remove user from workspace',
    remove_user_from_workspace_confirm: 'Are you sure you want to remove this user from the workspace?',
    unlimited: 'Unlimited',
    addImage: 'Add image',
    addTimezone: 'Add timezone',
    defoutWorkspaceNotOwner: '(Shared workspace)',
    astersAi: 'Asters.ai',
    version: 'Version',
    lastUpdate: 'Last Update',
    nextRenewal: 'Next renewal',
    you_have_to_accept_the_privacy_policy: 'You have to accept the privacy policy',
    you_have_to_buy_at_least_1_test: 'You have to buy at least 1 text',
    update: 'Update',
    iva_excluded: 'VAT excluded',
    obtained: '€ earned',
    missing: '€ missing',
    you_don_have_any: "You don't have any social channel to check",
    popEnvConfirm1: 'Are you sure you want to delete this workspace?',
    popEnvConfirmAccount: 'Are you sure you want to delete this account?',
    popEnvConfirm2: 'All posts will be permanently deleted',
    popEnvConfirm3: 'past, scheduled, drafts, and media.',
    popEnvConfirm4: 'This action is irreversible.',
    deleteEnv: 'Delete workspace',
    applica: 'Apply',
    sureToCancelPlan: 'Are you sure you want to cancel your subscription?',
    mode: 'Mode',
    differenceToPayNow: 'Difference to be settled immediately',
    addAll: 'Add all',
    settings: 'Settings',
    account: 'Account',
    profileSocial: 'Social profiles',
    environment: 'Workspace',
    subscription: 'Plan',
    accountData: 'Account details',
    yourMail: 'Your email',
    yourName: 'Your name',
    yourPassword: 'Your password',
    selectLanguage: 'Change language',
    logOut: 'Logout',
    deleteAccount: 'Delete account and data',
    visualLightDark: 'Light / dark mode',
    manageCode: 'Manage publishing queue',
    manageNotifications: 'Manage notifications',
    savedChangedNotify: 'Changes saved successfully',
    notSavedChangedNotify: 'Save changes before going back',
    addSocial: 'Add social',
    selectSocialCreatePost: 'Select social',
    addEnv: 'Create workspace',
    defoutWorkspace: '(Your personal workspace)',
    searchSocial: 'Search social profile',
    modify: 'Edit',
    confirm: 'Confirm',
    confirmModify: 'Confirm changes',
    createYourEnv: 'Create new workspace',
    searchEnv: 'Search workspace',
    nameEnv: 'Workspace name',
    save: 'Save',
    createWorkspace: 'Create workspace',
    saveTheModify: 'Save changes',
    goOutWhitautSaving: 'Exit without saving',
    saveThePassword: 'Save password',
    modifyPlan: 'Edit plan',
    expireIn: 'Expiring',
    addOn: 'Add-on',
    modifyAddOn: 'Edit add-on',
    costAddOn: 'Add-on price',
    fromPlan: 'Included:',
    extra: 'Extra:',
    total: 'Total:',
    addCreditAi: 'Add AI credits (artificial intelligence)',
    generationUsed: 'AI-generations used',
    zeroOn: ' of ',
    extraTextGenPrice: '10 extra AI captions for only 3€',
    extraHashGenPrice: '10 extra AI hashtags for only 2€',
    faturationDetail: 'Billing details',
    modifyYourData: 'Edit your information',
    paymentDetails: 'Payment details',
    nameSurnameAccountholder: 'Credit or debit card',
    private: 'Private',
    iva: 'Vat',
    folderName: 'Folder name',
    surname: 'Last name',
    completeAddress: 'Full billing address',
    fiscalCode: 'Fiscal code',
    addGeneration: 'Add AI-generations',
    cancelPlan: 'Cancel subscription',
    customization: 'Customization',
    includedInPlan: 'Included in your plan',
    yourPlan: 'Your plan',
    saveThe: 'Save ',
    freeMonths: 'free months',
    yourClientSocial: "FOR MANAGING YOUR CLIENTS' SOCIAL MEDIA",
    annual: 'Yearly',
    mensil: 'Monthly',
    annualPayment: 'Yearly payment ',
    continue: 'Continue',
    upgrade: 'Upgrade',
    do_upgrade: 'Do upgrade',
    downgrade: 'Downgrade',
    forYourSocialAndActivity: 'FOR PROFESSIONALS AND BUSINESSES',
    youArePaying: 'You are paying:',
    youWillPay: 'You will pay:',
    personalize: 'Customization',
    year: 'year',
    month: 'month',
    paymentDone: 'Subscription successfully activated!',
    wantToAddAi: 'Would you like to add AI credits?',
    paymentMothly: 'Monthly payment',
    payment: 'Checkout',
    ragioneSociale: 'Billing name',
    partitaIva: 'VAT number',
    CodiceSdiOrPEC: 'SDI or PEC (only for Italy)',
    youPaid: 'You were paying',
    youWllPayNextUpdate: 'You will pay on next renewal',
    importedAt: 'amount as of',
    summary: 'Summary',

    corispondTo: 'equals to ',
    yourPlamWillBeActivated1: 'Your plan will be activated as soon as we receive payment on ',
    yourPlamWillBeActivated2: 'automatically renewed on expiration. Subscription is non-refundable.',
    yourPlamWillBeActivated3:
      'Your plan will be activated as soon as we receive payment and will automatically renew on expiration. Subscription is non-refundable.',
    willAPliedNextUpdate:
      'Changes will be effective at your next renewal. Until then you can continue to enjoy the add-ons and benefits of your current plan.',
    selectAmbientation: 'Choose workspace',
    socialToConnect: 'Which social profiles would you like to connect?',
    setEnv: 'Assign to a workspace',
    notSeeingProfiles1: "Don't see your social profile?",
    notSeeingProfiles2: "If you don't see the social profile you would like to connect, check",
    notSeeingProfiles3: 'our guides',
    notSeeingProfiles4: 'on social profiles connection or contact our support',
    me: '€/month',
    planCost: 'Plan price',
    confirmPrivacy1: 'I confirm I have read and understood',
    confirmPrivacy2: 'terms of use',
    subscribe: 'Subscribe',
    buy: 'Purchase',
    creditAi: 'AI credits',
    yourData: 'Your data',
    standard: 'Standard',
    changePlan: 'Change plan',
    creditsAvailabe: 'Credits available',
    goAnnual: 'Go annual',
    earnMoreCredits: 'Earn more credits',
    yourMedia: 'Your media',
    caricaMedia: 'Select Media',
    youHaveModifyNotsaved: 'You have unsaved changes!',
    howYouWannaProcced: 'How would you like to proceed?',
    cardWasentAproved: 'Your payment method has not been confirmed, retry and authorize the payment',
    retry: 'Retry',
    weAreProccesingThePayment: 'We are processing the payment, please wait until confirmation message',
    insertTextGenerateHash: 'Write some keywords here to generate your hashtags',
    insert: 'Add',
    insertSomeKeyWord: 'Write some keywords here',
    insertHereYourFeedBack: 'Write your feedback here',
    controlSsocial: 'Check connection',
    pleaseSelectAReason: 'Select at least one option',
    ivaPayment: 'VAT',
    cardNumberPlaceholder: 'Card number',
    cvcPlaceholder: 'CVC',
    expiryPlaceholder: 'MM/AA',
    invalidCardCvc: 'CVC not valid',
    invalidCardExpiry: 'Expiry date not valid',
    invalidCardNumber: 'Card number not valid',
    invalidExpiryDate: 'Expiry date is not valid',
    monthOutOfRange: 'Expiry month is not valid',
    yearOutOfRange: 'Expiry year is not valid',
    dateOutOfRange: 'Expiry date is not valid',
    typeHere: 'Type here your ',
    coupon: 'Coupon',
    aplicate: 'Apply',
    eSara: 'e sarà ',
    difrance: 'difrance',

    // da qui
    type_name: 'Type name',
    type_surname: 'Type surname',
    type_email: 'Type email',
    type_address: 'Type address',
    type_password: 'Type password',
    type_fiscalCode: 'Type fiscal code',
    type_password_again: 'Type password again',
    type_company_name: 'Type company name',
    type_company_vat: 'Type company VAT',
    type_company_sdi: 'Type company SDI',
    type_company_pec: 'Type company PEC',
    type_company_address: 'Type company address',
    type_company_city: 'Type company city',
    type_company_cap: 'Type company CAP',
    type_company_province: 'Type company province',
    type_company_country: 'Type company country',
    type_sdi_or_pec: 'Type SDI or PEC',
    type_coupon: 'Type coupon',
    there_are: 'Ci sono',
    accounts_to_reconnect: 'account da ricollegare nel tuo attuale workspace',
    account_to_refresh: 'You have no account to refresh',
    payment_success: 'Payment successful',
    payment_error: 'Payment error',
    yourWorkspaces: 'Your workspaces',
    searchWorkspace: 'Search workspace',
    sharedWorkspaces: 'Shared workspaces',
    setTimeZone: 'Select the timezone',
    whatsapp: 'Whatsapp',
    yourPhone: 'Your number',
    sendCode: 'Send verification code',
    resendCode: 'Submit new code',
    verifyCode: 'Verify code',
    noNumber: 'You have not entered a WhatsApp number yet, add it to save the changes.',
    otpSendSuccess: 'We have sent you a verification code to the number you entered',
    otpSendError: 'Error sending verification code',
    otpSuccess: 'Number verified successfully',
    otpError: 'Incorrect verification code',
    otpJustVerified: 'Number is already verified',
    otpExpired: 'Code is expired ',
    tokenExpired: 'Token expired, please log in again',
    saveFor: 'Save for:',
    allEnvironments: 'All workspaces',
    thisEnvironment: 'This workspace',
    allNotifications: 'All notifications',
    apiSection: {
      noKeyPresent: 'No API key present',
      API: 'API',
      deleteBtn: 'Delete',
      generateApiBtn: 'Generate API key',
      documentationTitle: 'API docs',
      readDocumentation: 'Read the API docs',
      docDescription:
        'The Asters API makes it easier for developers to integrate many Asters features into other applications.',
      docDescriptionLearnMore: 'Want to learn more?',
      table: {
        name: 'Name',
        key: 'Key',
        createdTime: 'Creation date',
        createdBy: 'Created by',
        scopes: 'Scopes',
      },
    },
  },
  header: {
    upgrade: 'Upgrade',
    increasePlan: 'Upgrade',
    leaveComment: 'Review Asters',
    guideTutorial: 'Tutorials and guides',
    redBanners: ['red banner 1', 'red banner 2'],
    yellowBanner: ['yello banner 1'],
    showAll: 'Show all',
    convalidYourMail: 'Confirm your email to access all features',
    convalidYourMailInner: "Didn't get your account confirmation email?",
    clickHereToReceveMail: 'Resend confirmation email',
    updateTheConnection: 'Update social connection to keep scheduling',
    whyHaveToUpdate: 'Why do I need to update the connection?',
    updateYourConnection: 'Some of your social profiles are about to disconnect from Asters',
    youAccountIsExpired: 'Connection expired',
    daysToExpire: ' days to expiry',
    updateNow: 'Update now',
    click_for_more_info: 'Click for information',

    weNoticed: {
      1: 'Congratulations for joining Asters 🎉',
      2: 'We sent an email to your address with a link to activate your account.',
      3: 'To be sure, check the spam folder as well.',
      4: 'If you did not receive the confirmation email, click the button below.',
    },
  },
  langauge: {
    eng: 'English',
    ita: 'Italian',
  },

  plans: {
    base: 'Base',
    complete: 'Complete',
    articles: 'Articles',
    getDemo: 'Book a call',
    alreadyInDemo: 'Already in Demo',
    annualVersionChipText: 'Versione annuale del tuo piano corrente',
    tooltips: {
      profileSocial: {
        note: 'Note Social Profiles',
        title: 'Socials Profile',
        text: 'Number of social profiles that can be managed from your account',
      },
      teamMembers: {
        note: 'Note Team Members',
        title: 'Team Members',
        text: 'Centralize user management in Asters, assigning specific roles and permissions',
      },
      allSocial: {
        note: 'Note Social',
        title: 'Social',
        text: 'Asters currently supports Instagram, TikTok, Facebook, LinkedIn, X and YouTube',
      },
      extraStorage: {
        note: '',
        title: 'Cloud Storage',
        text: 'Storage space for your media with infinite folders and subfolders',
      },
      generationText: {
        note: '',
        title: 'Text Generations',
        text: 'Asters AI generates formatted captions for your social posts, in 27 languages ​​and dozens of objectives and tones of voices',
      },
      whatsapp: {
        note: '',
        title: 'WhatsApp Notifications',
        text: 'Receive custom notifications directly on your WhatsApp number',
      },
      generationImage: {
        note: '',
        title: 'Image Generations',
        text: 'Asters AI generates images with different styles and ready for different social formats',
      },
      generationHashtag: {
        note: '',
        title: 'Hashtag Generations',
        text: 'The number of hashtag generations you have available',
      },
      analytics: {
        note: '',
        title: 'Analytics',
        text: 'Track and measure your growth and see which posts generate the most engagement',
      },
      aiDiscover: {
        note: '',
        title: 'AI Discover',
        text: "Find trending content ideas and monitor your competitors' communications",
      },
      inbox: {
        note: '',
        title: 'Inbox',
        text: 'Manage all your social messaging in one place and deliver exceptional service at scale',
      },
      bestTimeToPost: {
        note: '',
        title: 'Best Time to Post',
        text: 'Asters AI suggest best performing Instagram posting days and times ',
      },
      advertising: {
        note: '',
        title: 'Advertising',
        text: 'Make more impactful organic posts by adding advertising budget for an improved visibility',
      },
      quickSearch: {
        note: '',
        title: 'Quick Search',
        text: 'Search images or videos in your Asters library by describing the content',
      },
      autoPilot: {
        note: '',
        title: 'Auto Pilot',
        text: 'Social media automation at scale. Create custom flows of work, regain time and improve your productivity',
      },

      advancedCalendar: {},
      saveInDraft: {},
      graficEditor: {},
      support: {},
      application: {},
      instagramReels: {},
    },
    professionist: {
      professional: 'Professional',
      profileSocial: 'Social Profiles',
      teamMembers: 'Team Members',
      allSocial: 'All Social',
      extraStorege: 'Cloud Storage',
      generationText: 'AI Generated Captions',
      generationImage: 'AI Generated Images',
      advancedCalendar: 'Advanced calendar',
      generationHashtag: 'AI hashtag sets',
      saveInDraft: 'Save post to drafts',
      graficEditor: 'Full graphic editor',
      analytics: 'Analytics',
      support: 'Chat support within 24h',
      application: 'Mobile app',
      instagramReels: `${commonToAll.socials.instagram} reels`,
      inbox: 'Inbox',
      whatsapp: 'WhatsApp Notifications',
      aiDiscover: 'AI Discover',
      autoPilot: 'Autopilot',
      advertising: 'Advertising',
      bestTimeToPost: 'AI Best Time to Post',
      quickSearch: 'AI Quick Search',
      extraSocialFeatures: 'Extra social features',
      offer: {
        text: 'LAUNCH OFFER ',
        type: 'annual',
        label: 'Professional',
        slogan: 'First year',
      },
    },
    corporate: {
      corporate: 'Business',
      profileSocial: '10 social profiles',
      extraStorege: '10GB cloud storage',
      generationText: '40 AI captions',
      advancedCalendar: 'Advanced calendar',
      generationHashtag: '40 AI hashtag sets',
      saveInDraft: 'Save post to drafts',
      graficEditor: 'Full graphic editor',
      analytics: 'Analytics (coming soon)',
      support: 'Chat support within 12h',
      application: 'Mobile app (coming soon)',
      instagramReels: 'Instagram reels',
      extraSocialFeatures: 'Extra social features',
      inbox: 'Inbox (coming soon)',
    },
    premium: {
      premium: 'Premium',
      profileSocial: 'Social Profiles',
      extraStorege: 'Cloud Storage',
      generationText: 'AI captions',
      advancedCalendar: 'Advanced calendar',
      generationHashtag: 'AI hashtag sets',
      saveInDraft: 'Save post to drafts',
      graficEditor: 'Full graphic editor',
      analytics: 'Analytics (coming soon)',
      support: 'Chat support within 4h',
      application: 'Mobile app (coming soon)',
      instagramReels: 'Instagram reels',
      extraSocialFeatures: 'Extra social features',
      inbox: 'Inbox (coming soon)',
    },
    enterprise: {
      enterprise: 'Enterprise',
      speakWithOurTeam: 'Talk to our Sales team',
      enterpriseDiscoverText:
        'Discover how to help your teams increase productivity through social media automation and see Asters in action with a live demo customized specifically for your company.',
    },
  },
  additionalOptions: {
    storage: {
      lable: '3GB of extra cloud storage for only €2',
      description: 'Extra cloud storage',
    },
    extraSocial: {
      lable: '1 extra social profile for only €2',
      description: 'Social profile',
    },
    extraHashtag: {
      lable: '10 extra texts generations at €2',
      description: 'Generazione hashtag tramite AI',
    },
    extraCaptions: {
      lable: '10 extra hashtag generations at €2',
      description: 'Generazione testo tramite AI',
    },
  },
  subscriptionSteps: {
    step1: 'Plan',
    step2: 'Customization',
    step3: 'Checkout',
  },
  teamMembers: {
    manageWorkspace: 'Manage',
    setTimeZone: 'Search time-zone',
    rulesTitle: {
      publication: 'Publication',
      media: 'Media',
      calendar: 'Calendar',
      settings: 'Settings',
      analytics: 'Analytics',
      discover: 'Discover',
    },
    rules: {
      viewCommentPost: 'View and comment post',
      editPost: 'Edit post',
      publishPost: 'Publish post',
      aiCopyGenerator: 'AI copy',
      manageDraft: 'Manage draft',
      uploadMedia: 'Upload file',
      manageMedia: 'Edit file',
      manageFolders: 'Manage Folder',
      mediaEditor: 'Media editor',
      aiMediaSearch: 'AI media search',
      calendarShowPost: 'Show post',
      exportCalendar: 'Export editorial calendar',
      linkSocialAccount: 'Link social account',
      deleteSocialAccount: 'Delete social account',
      createWorkspaces: 'Create workspaces',
      editWorkspaces: 'Edit workspaces',
      deleteWorkspaces: 'Delete workspaces',
      manageBillings: 'Manage billings',
      createReport: 'Create report',
      viewReport: 'View report',
      shareReport: 'Share report',
      searchContent: 'Search content',
      manageContentDiscover: 'Manage board and followers',
    },
    tableHeaders: {
      name: 'Name',
      publication: 'Publication',
      calendar: 'Calendar',
      media: 'Media',
      settings: 'Settings',
      roleName: 'Role name',
    },
    addNewRole: 'Add role',
    addTeamMember: 'Add team member',
    manageRole: 'Manage role',
    loading: 'Loading...',
    insertEmail: 'Insert email',
    selectRole: 'Select role',
    inviteMembers: 'Invite team members',

    addEmail: 'Add email',
    invite: 'Invite',
    addText: 'Add',
    searchRole: 'Search role',
    search: 'Search',
    create: 'Create',
    comingSoon: 'Coming soon',
    roleName: 'Role name',
    successInvite: 'Invitation sent successfully',
    searchTeamMember: 'Search team member',
  },
  guidedTour: 'Guided tour',
  aiDiscoverPage: {
    sidebar: {
      sidebarTitleFollowed: 'Followed',
      sidebarTitleSuggested: 'Suggested',
      sidebarTitleBoard: 'Board',
      noArgumentFollowed: "Don't follow any topic",
      noBoardsCreated: 'No boards available',
      manage: 'Manage',
      search: 'Search',
      noResultsFound: 'No results found',
      unfollow: 'Unfollow',
      deleteBoard: 'Delete board',
      deleteBoards: 'Delete boards',
      unfollowModal: 'You no longer follow those topics',
      deleteBoardsModal: 'Board successfully deleted',
      deleteArticle: 'Article successfully deleted',
      deleteArticleProblem: 'There was a problem deleting the article',
      deleteTopicModal: 'You no longer follow this topic',
      deleteTopicProblem: 'There was a problem unfollowing the article',
      unfollowModalProblem: 'There was a problem, please try again later',
      moreRecent: 'More recent',
      lessRecent: 'Less recent',
      all: 'All',
      private: 'Private',
      public: 'Public',
      selectAllResults: 'Select all results',
      selectAllPrivateBoards: 'Select all private boards',
      selectAllSharedBoards: 'Select all shared boards',
      selectAll: 'Select all',
      StartDate: 'Start Date',
      EndDate: 'End Date',
    },
    searchbar: {
      resultsFor: 'Results for',
      following: 'Following',
      follow: 'Follow',
      searchByTopic: 'Search by topic',
      addedTopicToFollowed: 'Added topic to followed',
      problemToAddTopicToFollowed: 'There was a problem, please try again later',
      removedTopicToFollowed: 'Topic removed from followed',
      problemToRemoveTopicToFollowed: 'There was a problem, please try again later',
    },
    discoverMenu: {
      search: 'Search',
      followed: 'Followed',
      boards: 'Boards',
    },
    searchContent: {
      noResults: 'No results',
    },
  },
  analytics: {
    selectAPeriod: 'Select a period',
    reportName: 'Report name',
    vsPreviousPeriod: 'vs previous period',
    compareDateAlert: (nDays) =>
      `You can only make the comparison over two equal time periods. \n Select in a range of ${nDays} days`,
    linkedinAlert1: 'LinkedIn only allows you to read the statistics of company pages',
    linkedinAlert2: 'For this reason, it is not possible to select personal profiles within this area',
    customize: 'Customize',
    compare: 'Compare',
    lastEdit: 'Last edit',
    myReports: 'My reports',
    reportWith: 'Reports with',
    metrics: 'Metrics',
    enterTheNameOfTheReport: 'Enter the name of the report',
    recommendedDimensionLogo: 'The recommended dimensions for the logo are',
    uploadLogo: 'Upload logo',
    noReportsAvailable: 'No reports available',
    noReportsWith: 'No reports with',
    createYourFirstReport: 'Create your first report',
    reportRename: (min, max) => `The report must have at least ${min} letter and a max of ${max} characters`,
    untitled: 'Untitled',
    addProfile: 'Add profile',
    noSocialProfilesConnectedTo: 'No social profiles connected to',
    comingSoon: 'Coming soon',
    noReports: 'No reports',
    lastSave: 'Last save',
    dashboard: 'Dashboard',
    new: 'New',
    setUp: 'Set-up',
    social: 'Social',
    preview: 'Preview',
    share: 'Share',
    save: 'Save',
    link: 'Link',
    savePdf: 'Save PDF',
    selectASocialAccount: 'Select a social account',
    reportDate: 'Report date',
    creatingPdf: 'Creating PDF',
    reportTooLargeForPdf: 'The report is too large to be exported in PDF format',
  },
  socialProfileType: {
    group: 'Group',
    page: 'Page',
    profile: 'Profile',
    channel: 'Channel',
    business: 'Business',
  },
  headerAutopilotAndModal: {
    autopilot: 'Autopilot',
    analytics: 'Analytics',
    titleCreateKey: 'Generate API Key',
    inputTitle: 'Assign a name',
    assignScopeTitle: 'Assign a scope',
    placeholderName: 'Insert here',
    titleCreatedKey: 'Your API key',
    descriptionApiKeyGenerated:
      'The API key has been successfully generated. Copy the key and store it in a safe place. For security reasons, we cannot show it to you again',
    generate: 'Generate',
    warning: {
      minMaxChar: 'The name must have at least 3 characters and a maximum of 50 characters',
      selectScope: 'Select a scope',
    },
    info: {
      copyKey: 'Key copied to clipboard',
    },
  },

  billing: {
    settings: {
      error: "There's been an error, please try again later",
      title: 'Plan',
      description: 'View plan details, update billing data, cancel subscription, and download receipts',
      manage: 'Manage subscription',
      freeTrial: 'Free Trial',
      remainingDays: 'Remaining days:',
      checkCapabilities: 'Compare plans',
      faq: 'FAQ',
    },
    comingSoon: 'Coming soon',
    matchPlans: 'Compare all features',
    faq: 'FAQ',
    addOn: {
      caption: 'Choose the plan that suits you best',
      addonTitle: 'Add-on',
      addonDescription:
        'Create and analyze your social media content with Flora AI — the chatbot that increases your social media productivity and efficiency. All without lifting a finger. Add it to your plan for less than a cappuccino a day',
      floraTitle: 'Flora AI',
      floraDescription:
        'Unlimited use of AI to create social posts with brand voice, analyze posts and profile performance, and much more',
    },
    status: {
      inactive: 'Inactive',
      active: 'Active',
      expired: 'Expired',
      infinite: 'unlimited',
    },
    labels: {
      socialProfile: 'social profiles',
      suggested: 'Suggested',
      colabs: 'users',
      api: 'API Access',
      autopilot: 'Autopilot',
      privateManager: 'Dedicated Account Manager',
      customDevelopment: 'Custom features development',
      copies: 'AI generations',
      posts: 'Post',
      discover: 'Discover results',
      storage: 'GB Storage',
      infiniteStorage: ' Storage',
    },
    plans: {
      downgradeNotEnabled: 'Contact support',
      caption: 'All Plans',
      monthly: 'Monthly',
      yearly: 'Yearly',
      reserveDemo: 'Contact sales',
      activate: 'Activate',
      upgrade: 'Upgrade to',
      downgrade: 'Downgrade to',
      startTrial: 'Try free for 7 days',
    },

    upgradePlan: {
      active: 'Attivo',
      expiredAt: 'Expired at ',
      expireAt: 'Expire at ',
      planned: 'Upgrade Planned',
      upgradePlanned: 'Upgrade Pianificato, impossibile modificare il piano',
      confirm: 'Confirm',
      from: 'From ',
      to: 'To ',
      upgrade: 'Upgrade',
      vatDescription: 'VAT escluded',
      selectOption: 'Select an option',
      unusedCredit: 'Unused credit',
      pricePlan: ' Price of the plan',
      upgradePaymentDate: 'Upgrade payment date',
      upgradePlanDescription:
        'At the end of the billing cycle, the subscription plan will automatically renew and update to the new plan chosen.',
      upgradeProrateDescription:
        'The new subscription plan will be activated immediately, and the amount due today will be automatically calculated based on the unused credit from the previous plan.',
      amountNow: 'Amount due today',
      amountVat: 'VAT',
      amountWithoutVAT: 'Amount without VAT',
      currency: '€',
      optionsDescription:
        'Choose how to apply the change to your plan. You can choose to apply the change to the next renewal, or apply it immediately and your available credit will be automatically calculated based on the unused credit of the current plan.',
      applyNextRenewal: 'Apply at the next renewal',
      applyWithProrate: 'Apply immediately with prorate',
      continue: 'Continue',
      upgradeSuccess: 'Upgrade successful',
      upgradeError: 'Upgrade failed',
      planLimitExceeded: 'Plan limit exceeded. Before upgrading check the current resources.',
    },
  },
  appsumo: {
    title: 'Appsumo',
    
    welcome: 'Hello Sumo-ling! 👋' 
  }  
}
