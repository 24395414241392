import { SideBarOptions } from 'components/GeneralComponents/GeneralComponentsTypes'
import SideBar from 'components/GeneralComponents/SideBar'
import { Icons } from 'components/Icons'
import { openDraftModal, openShowPlanningOptions } from 'components/Modal/ModalManager'
import { useEffect, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { ESubsection, TSection, TSubsection } from 'types/CreatePostInferfaces'
import { AppStore, T, showError, showInfo, showWarning } from 'utils'
import {
  OWNER_POST_LIMITS_AVAILABLE,
  OWNER_POST_LIMITS_INFINITE,
  OWNER_POST_LIMITS_SOCIALS,
  OWNER_POST_LIMITS_USED,
} from 'utils/Constants/LimitUserConstants'
import { publish } from 'utils/PostUtils/Post'
import { AccountProblem, checkPostValidation } from 'utils/PostUtils/ValidationPost'
import { showMultipleProblems, showMultipleWarningsWithTitle } from 'utils/SuperUtils'

interface Props {
  section: TSection
  subsection: TSubsection
}

const PostNavigation = ({ section, subsection }: Props) => {
  /* --------------------------------------------- CHECK SOCIAL SELECTED */
  const hasPosts = useCreatePostStore.getState().posts.length > 0

  /* --------------------------------------------- ROUTER */
  const location = useLocation()
  const { socialsIds, aiText, mediasIds } = useParams()
  const stateConditionalClicked = !hasPosts ? undefined : location.state ?? undefined

  const addStateIfConditional = () => {
    const { posts } = useCreatePostStore.getState()
    const hasSocialAccounts = posts.length > 0
    return hasSocialAccounts ? { state: 'publishPost' } : { state: undefined }
  }

  /* --------------------------------------------- STATE */
  const [sideBarOptions, setSideBarOptions] = useState<SideBarOptions>()

  /* --------------------------------------------- METHODS */

  // ----------- configurazioni per quando un item del menu viene cliccato -----------
  const onSelectFuncion = {
    preview_basic: { navigate: `/post/preview/basic${socialsIds ? `/${socialsIds}` : ''}` },
    preview_drafts: {
      navigate: undefined,
      function: () => {
        openDraftModal()
      },
    },
    preview_templates: { navigate: `/post/preview/templates` },
    preview_labels: { navigate: `/post/preview/labels` },
    text_models: {
      timeout: 100,
      navigate: `/post/text/astersAiText${aiText ? `/${aiText}` : ''}`,
    },
    text_templates: {
      navigate: `/post/text/templates`,
    },
    media: { navigate: `/post/media${mediasIds ? `/${mediasIds}` : ''}` },
    extra: {
      navigate: `/post/extra`,
      function: () => {
        const { posts } = useCreatePostStore.getState()
        // Verifica se ci sono profili social selezionati
        const hasSocialAccounts = posts.length > 0
        if (hasSocialAccounts) {
          return 'ok'
        } else {
          showError(T.error.addAtLeastOneSocialAccount)
          return 'error'
        }
      },
    },
    socials: { navigate: `/post/socials${socialsIds ? `/${socialsIds}` : ''}` },

    // Seconda colonna navbar crea post

    publishPost: {
      ...addStateIfConditional,
      navigate: `/post/preview/basic`,
      state: 'publishPost',
      function: () => {
        const { posts, sethasClickedPublish } = useCreatePostStore.getState()
        const hasSocialAccounts = posts.length > 0
        sethasClickedPublish(true)

        if (!hasSocialAccounts) {
          showError(T.error.addAtLeastOneSocialAccount)
          return 'error'
        } else {
          return 'ok'
        }
      },
    },

    // Publish onSelect function options
    saveDraft: {
      navigate: `/post/preview/basic`,
      function: () => publish('draft'),
    },

    schedule: {
      navigate: `/post/preview/basic`,
      function: () => {
        const { posts } = useCreatePostStore.getState()
        let check: AccountProblem[] = []
        posts.forEach((post) => {
          check = [...check, ...checkPostValidation(post)]
        })
        if (check.length === 0) openShowPlanningOptions()
        else showMultipleProblems(check)
      },
    },

    publishPostNow: {
      navigate: `/post/preview/basic`,
      function: () => {
        const { posts } = useCreatePostStore.getState()

        //? Notifica l'utente che ha raggiunto il massimo di post disponibili
        if (!OWNER_POST_LIMITS_INFINITE) {
          //Se ha superato i limiti
          if (OWNER_POST_LIMITS_USED >= OWNER_POST_LIMITS_AVAILABLE) {
            return showInfo(T.limitUser.max_reached_post)
          }

          // Se sta per superarli con i post che sta per creare
          if (OWNER_POST_LIMITS_USED + posts.length > OWNER_POST_LIMITS_AVAILABLE) {
            return showInfo(
              T.limitUser.max_reached_post_details(posts.length, OWNER_POST_LIMITS_USED, OWNER_POST_LIMITS_AVAILABLE)
            )
          }
        }

        //? Controlla se supera i limiti di pubblicazione per provider
        // Ottieni i socials dei post che stai per creare
        const socialsToPublish = posts.map((post) => post.info.account.provider)

        // Verifica se i limiti sono superati per ogni social
        for (const social of socialsToPublish) {
          const { infinite } = OWNER_POST_LIMITS_SOCIALS[social]
          if (infinite) continue

          const { used } = OWNER_POST_LIMITS_SOCIALS[social]
          const { available } = OWNER_POST_LIMITS_SOCIALS[social]
          const totalUsed = used + 1
          if (totalUsed > available) {
            showInfo(T.limitUser.max_reached_post_in_social(social))
            return
          }
        }

        publish('publish-now')
      },
    },
  }

  // ----------- configurazioni per le opzioni del sidebar -----------
  const previewConfiguration = {
    basic: {
      title: T.postPage.basic,
      icon: <Icons.base fill={AppStore.theme.o.darkGrey} />,
      onSelect: onSelectFuncion.preview_basic,
    },
    draft: {
      title: T.DashboardPage.draft,
      icon: <Icons.save fill={AppStore.theme.o.darkGrey} />,
      onSelect: onSelectFuncion.preview_drafts,
    },
    models: {
      title: T.postPage.models,
      icon: <Icons.template fill={AppStore.theme.o.darkGrey} />,
      onSelect: onSelectFuncion.preview_templates,
    },
    labels: {
      title: T.postPage.labels,
      icon: <Icons.label fill={AppStore.theme.o.darkGrey} />,
      onSelect: onSelectFuncion.preview_labels,
    },
  }

  const textConfiguration = {
    generationAi: {
      title: T.postPage.generationAi,
      icon: <Icons.magicWand fill={AppStore.theme.o.darkGrey} />,
      onSelect: onSelectFuncion.text_models,
    },
    models: {
      title: T.postPage.models,
      icon: <Icons.template fill={AppStore.theme.o.darkGrey} />,
      onSelect: onSelectFuncion.text_templates,
    },
  }

  const publishConfiguration = {
    saveDraft: {
      title: T.postPage.saveDraft,
      icon: <Icons.save />,
      position: (i) => (i === 0 ? 0 : 290),
      onSelect: onSelectFuncion.saveDraft,
    },
    schedule: {
      title: T.postPage.plan,
      icon: <Icons.planPost />,
      onSelect: onSelectFuncion.schedule,
    },
  }

  // ----------- main nav bar configurations -----------
  const Configuration = {
    preview: {
      title: T.calendarPage.preview,
      icon: <Icons.preview fill={AppStore.theme.o.darkGrey} />,
      options: [
        previewConfiguration.basic,
        previewConfiguration.draft,
        previewConfiguration.models,
        previewConfiguration.labels,
      ],
      onSelect: onSelectFuncion.preview_basic,
    },
    text: {
      title: T.postPage.texts,
      icon: <Icons.testi fill={AppStore.theme.o.darkGrey} />,
      options: [textConfiguration.generationAi, textConfiguration.models],
      onSelect: onSelectFuncion.text_models,
    },
    media: {
      title: T.postPage.media,
      icon: <Icons.medias fill={AppStore.theme.o.darkGrey} />,
      options: undefined,
      onSelect: onSelectFuncion.media,
    },
    extra: {
      title: T.postPage.extra,
      icon: <Icons.position fill={AppStore.theme.o.darkGrey} />,
      onSelect: onSelectFuncion.extra,
      options: undefined,
    },
    socials: {
      title: T.postPage.socials,
      icon: <Icons.userCircle fill={AppStore.theme.o.darkGrey} />,
      onSelect: onSelectFuncion.socials,
      options: undefined,
    },
    publishPostConditional: {
      title: T.postPage.createNewPost,
      visible(obj) {
        return true
      },
      options: [publishConfiguration.saveDraft, publishConfiguration.schedule],
      icon: <Icons.publishNow fill={AppStore.theme.o.darkGrey} />,
      onSelect: onSelectFuncion.publishPost,
    },
    publishNow: {
      title: T.postPage.publicNow,
      visible(obj) {
        return true
      },
      icon: <Icons.publishNow fill={AppStore.theme.o.darkGrey} />,
      onSelect: onSelectFuncion.publishPostNow,
    },
  }

  useEffect(() => {
    const step = {
      preview: 0,
      text: 1,
      media: 2,
      extra: 3,
      socials: 4,
    }

    setSideBarOptions({
      default: step[section],
      options: [
        Configuration.preview,
        Configuration.text,
        Configuration.media,
        Configuration.extra,
        Configuration.socials,
      ],
      conditionalOptions: [Configuration.publishPostConditional, Configuration.publishNow],
    })
  }, [section, subsection])

  if (!sideBarOptions) return <></>

  return (
    <SideBar
      options={sideBarOptions}
      subOptionSelected={ESubsection[section][subsection]}
      conditionalClicked={stateConditionalClicked ? -1 : undefined}
      whatPage="post"
    />
  )
}

export default PostNavigation
