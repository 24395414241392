import styled from '@emotion/styled'
import ApiKeys from 'api/ApiKeys'
import { Box, Button } from 'components'
import { Icons } from 'components/Icons'
import { useEffect, useState } from 'react'
import { Workspace } from 'types'
import { IApiKeys } from 'types/SettingsInterfaces'
import { AppStore, T } from 'utils'
import { onOpenAutopilotGenerateKeyModal } from '../Modal/ModalManager'
import ApiKeyGrid from './ApiKeyGrid'
import DocumentationSection from './DocumentationSection'

interface Props {
  changeSection: (section: string, workspace?: Workspace) => void
  allWorkspaces: Workspace[]
}

const WorkspaceApiKeys = ({ changeSection, allWorkspaces }: Props) => {
  /* ------------------ STATE ------------------ */
  const [apiKeys, setApiKeys] = useState<IApiKeys[] | null>(null)
  const [selectedApiKeys, setSelectedApiKeys] = useState<string[]>([])

  /* ------------------ METHODS ------------------ */
  const onSelectApiKey = (id = 'all') => {
    // select all
    if (apiKeys === null) return
    if (id === 'all') {
      selectedApiKeys.length === apiKeys.length ? setSelectedApiKeys([]) : setSelectedApiKeys(apiKeys.map((k) => k._id))
      return
    }

    // select and unselect by id
    if (selectedApiKeys.includes(id)) {
      setSelectedApiKeys(selectedApiKeys.filter((k) => k !== id))
    } else {
      setSelectedApiKeys([...selectedApiKeys, id])
    }
  }

  /* ------------------ API CALL ------------------ */
  const getApiKeys = async () => {
    try {
      const res = await ApiKeys.getApiKeys(AppStore.getWorkspaceId())
      setApiKeys(res.data)
    } catch (error) {
      setApiKeys([])
      console.error('error', error)
    }
  }

  const deleteApiKeys = async () => {
    if (!apiKeys) return

    try {
      const res = await ApiKeys.deleteApiKey(selectedApiKeys)
      getApiKeys()
      setSelectedApiKeys([])
    } catch (error) {
      console.error('error', error)
    }
  }

  /**
   * serve a far refreshare lo scroll container con le api key e far si che parta una nuova chiamata per le api keys nuove
   */
  const toggleAddingNewKey = () => {
    setApiKeys(null)
    setTimeout(() => {
      getApiKeys()
    }, 500)
  }

  useEffect(() => {
    getApiKeys()
  }, [])

  return (
    <WorkspaceApiKeyContainer animation="fade" flex pv={64} ph={56}>
      {/* ----------------------- HEADER ----------------------- */}
      <Box vcenter row>
        <BackwardIcon
          onClick={() => {
            changeSection('workspace')
          }}
          width={20}
          height={20}
          style={{ marginRight: '24px' }}
        />

        <TitleText>{T.settings.apiSection.API}</TitleText>
      </Box>

      {/* ----------------------- HEADER CONTAINER BUTTON ----------------------- */}
      <HeaderContainerButton removeHover>
        <DeleteBtnContainerFade className={`${selectedApiKeys.length > 0 ? 'visible' : ''}`}>
          <Button
            variant={'delete-revert'}
            onClick={deleteApiKeys}
            size="smaller"
            style={{ width: 176, height: 40, fontWeight: 500, gap: 4, justifyContent: 'center', alignItems: 'center' }}
          >
            <Icons.trash width={32} height={32} fill="currentColor" />
            {T.settings.apiSection.deleteBtn}
          </Button>
        </DeleteBtnContainerFade>

        <Button
          variant={'primary'}
          onClick={() => onOpenAutopilotGenerateKeyModal(toggleAddingNewKey)}
          size="smaller"
          style={{ minWidth: 215, height: 40, fontWeight: 500 }}
        >
          {T.settings.apiSection.generateApiBtn}
        </Button>
      </HeaderContainerButton>

      {/* ----------------------- APIKEYS CONTAINER ----------------------- */}
      <ApiKeyGrid apiKeys={apiKeys} selectedApiKeys={selectedApiKeys} onSelectApiKey={onSelectApiKey} />

      {/* ----------------------- DOCUMENTATION CONTAINER ----------------------- */}
      <DocumentationSection />
    </WorkspaceApiKeyContainer>
  )
}
export default WorkspaceApiKeys

const WorkspaceApiKeyContainer = styled(Box)``

const TitleText = styled.p`
  font-family: 'Aktiv Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 27px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: ${() => AppStore.theme.o.green};
`

const BackwardIcon = styled(Icons.backward)`
  cursor: pointer;
  fill: ${() => AppStore.theme.o.black};
  :hover {
    fill: ${() => AppStore.theme.o.blueOnLight};
  }
`

const HeaderContainerButton = styled(Box)`
  min-width: 100%;
  margin-bottom: 24px;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
`

const DeleteBtnContainerFade = styled(Box)`
  display: invisible;
  opacity: 0;
  border-radius: 0;
  transition: 0.3s ease-in-out;

  &.visible {
    display: flex;
    opacity: 1;
    transition: 0.3s ease-in-out;
  }
`
