import ChromeDinoGame from 'react-chrome-dino'
import { Text } from 'components/Text'
import AppStore from './AppStore'
import { isVisible } from './CheckVisibleElement'
import { toast, ToastOptions } from 'react-toastify'
import { AccountProblem } from './PostUtils/ValidationPost'

export enum DevHelperEnum {
  jurassic = 'jurassic',
}

export const LoadingDevHelper = ({ variant }) => {
  const titles = {
    [DevHelperEnum.jurassic]: "PippoSauro's Adventures",
  }

  const DevHelper = () => {
    switch (variant) {
      case DevHelperEnum.jurassic:
        return <ChromeDinoGame />
      default:
        return <></>
    }
  }

  return isVisible('superUtils') ? (
    <div
      style={{
        borderRadius: 15,
        marginTop: 16,
        height: 300,
        width: 800,
        backgroundColor: AppStore.theme.o.lightGrey,
        padding: 16,
      }}
    >
      <Text style={{ color: AppStore.theme.o.darkGrey, fontWeight: 700, marginRight: 12 }}>{titles[variant]}</Text>
      {<DevHelper />}
    </div>
  ) : (
    <></>
  )
}

export const ToastWithTitle = ({ title, message }) => {
  return (
    <div
      style={{
        borderRadius: 15,
        marginTop: 16,
        height: 300,
        width: 800,
        backgroundColor: AppStore.theme.o.lightGrey,
        padding: 16,
      }}
    >
      <Text style={{ color: AppStore.theme.o.darkGrey, fontWeight: 700, marginRight: 12 }}>{title}</Text>
      <Text style={{ color: AppStore.theme.o.darkGrey }}>{message}</Text>
    </div>
  )
}

export const showWarningWithTitle = (title: string, message: string, options?: ToastOptions) => {
  return toast.warning(
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 0,
      }}
    >
      <h4>{title}</h4>
      <p>{message}</p>
    </div>,
    {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      ...options,
    }
  )
}

export const showMultipleWarningsWithTitle = (title: string, messages: string[], options?: ToastOptions) => {
  messages.forEach((message, index) => {
    setTimeout(() => showWarningWithTitle(title, message), 500 * index, { ...options })
  })
}

export const showMultipleProblems = (problems: AccountProblem[], options?: ToastOptions) => {
  const elements = problems.map((problem) => {
    return { title: problem.provider.charAt(0).toUpperCase() + problem.provider.slice(1), message: problem.problem }
  })
  elements.forEach((element, index) => {
    setTimeout(() => showWarningWithTitle(element.title, element.message), 500 * index, { ...options })
  })
}
