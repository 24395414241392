/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import Reports from 'api/Reports'
import { Box } from 'components'
import { InputEditableContent } from 'components/InputEditableContent'
import { RefreshButton } from 'components/RefreshButton'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAnalyticsStore } from 'stores/AnalyticsStore'
import { AnalyticsDate, IDataReport, IReportConfig } from 'types/AnalyticsInferfaces'
import { AppStore, T } from 'utils'
import { changeReportName, formatDate, getReport } from 'utils/AnalyticsUtils'
import { isVisible } from 'utils/CheckVisibleElement'
import { AddMetricOnReport } from '../Graph/singleGraph/AddMetricOnReport'
import { DownloadReportButton } from '../Graph/singleGraph/DownloadReportButton'
import { DateCompareDropdown } from '../UI/DateCompareDropdown'
import { DateRangeFilter } from '../UI/DateRangeFilter'
import moment from 'moment'

/* ------------------- INTERFACES  */
interface Props {
  reportPreview: IReportConfig | null
}

export const PreviewReportHeader = ({}: Props) => {
  /* ------------------- ROUTER  */
  const { reportId } = useParams()

  const reportPreview = useAnalyticsStore((state) => state.reportPreview)

  const reportPreviewName = useAnalyticsStore((state) => state.reportPreviewName)

  // const [inputReportName, setInputReportName] = useState<string>(reportPreview?.info.reportName || '')

  const { isReportUpdating, reportUpdateDate } = useAnalyticsStore((state) => ({
    isReportUpdating: state.isReportUpdating,
    reportUpdateDate: state.reportUpdateDate,
  }))

  const selectedDate = {
    startDate: dayjs(reportPreview?.since),
    endDate: dayjs(reportPreview?.until),
  }

  const compareSelectedDate = {
    startDate: dayjs(reportPreview?.comparativeRange?.from),
    endDate: dayjs(reportPreview?.comparativeRange?.to),
  }

  /**
   * Change the report date
   */
  const onChangeSelectedDateReports = (date: AnalyticsDate | null) => updateReportDate(date!)

  /**
   * Change the report compare date
   */
  const onCompareDateSelected = (date: AnalyticsDate | null) => updateReportDate(undefined, date!)

  /**
   * Refresh the report date
   */
  const refreshReportDate = () => updateReportDate()

  /**
   * Logic for update the report date
   * @param date
   * @returns
   */
  const updateReportDate = async (date?: AnalyticsDate, compareDate?: AnalyticsDate) => {
    const nSelectedDate = date ||
      selectedDate || {
        startDate: dayjs(reportPreview?.since),
        endDate: dayjs(reportPreview?.until),
      }

    const nCompareSelectedDate = compareDate ||
      compareSelectedDate || {
        startDate: dayjs(reportPreview?.comparativeRange?.from),
        endDate: dayjs(reportPreview?.comparativeRange?.to),
      }


    const comparativeReportRange = {
      from: nCompareSelectedDate.startDate?.toISOString() || '',
      to: nCompareSelectedDate.endDate?.toISOString() || '',
    }

    if (!reportPreview || !nSelectedDate.startDate || !nSelectedDate.endDate) return

    useAnalyticsStore.getState().setReportPreview(null)

    const reportPreviewData: IDataReport[] = reportPreview.data.map((r: any) => ({
      account: r.socialProfile._id,
      provider: r.socialProfile.provider,
      metrics: [r.metricRef],
      since: nSelectedDate!.startDate!.toISOString(),
      until: nSelectedDate!.endDate!.toISOString(),
    }))

    const info = {
      ...reportPreview.info,
    }

    const isComparativeSameDay = moment(comparativeReportRange.from).isSame(moment(comparativeReportRange.to), 'day')
    if (!isComparativeSameDay) info.comparativeRange = comparativeReportRange

    try {
      const reportElement: IReportConfig = {
        data: reportPreviewData,
        info,
        reportId: reportPreview._id,
        since: nSelectedDate.startDate.toISOString(),
        until: nSelectedDate.endDate.toISOString(),
      }

      if (!isComparativeSameDay) reportElement.comparativeRange = comparativeReportRange

      const report = await Reports.createReport(reportElement)
      const addReport = await getReport(report._id)
      // console.log(JSON.stringify(addReport))
      useAnalyticsStore.getState().setReportPreview(addReport)
      useAnalyticsStore.getState().onSetReportName(addReport.info.reportName!)
    } catch (e) {
      console.error(e)
    }
  }

  return window.location.pathname.split('/')[3] && reportPreview ? (
    <HeaderDashboardAnalyticsContainer className={`header_report ${reportId === reportPreview?._id ? 'show' : ''}`}>
      {/* ---------------------- HEADER LEFT  __ last report save text*/}
      <HeaderItemContainer className="header_icon_left">
        <AddMetricOnReport />
        {isVisible('analytics.reporHeader.downloadreportBtn') && <DownloadReportButton />}
      </HeaderItemContainer>

      {/* ---------------------- HEADER CENTER __ report title*/}
      <HeaderItemContainer className="header_text_center-container">
        <InputAndDateContainer>
          <InputEditableContent
            value={reportPreviewName ?? ''}
            submitAction={(currentReportName) => changeReportName(reportId!, currentReportName, false)}
            defaultValue={reportPreviewName}
            notifyInfo={T.analytics.reportRename(1, 25)}
          />
          <LastModifyDate>
            {isReportUpdating ? (
              <>{T.info.updating}</>
            ) : (
              <>
                {T.analytics.lastSave}: {formatDate(reportUpdateDate ?? '')}
              </>
            )}
          </LastModifyDate>
        </InputAndDateContainer>
      </HeaderItemContainer>

      {/* ---------------------- HEADER RIGHT __ date picker*/}
      <HeaderItemContainer className="header_text_right">
        <RefreshButton refreshAction={refreshReportDate} />

        <DateRangeFilter
          selectedDate={selectedDate}
          setSelectedDate={onChangeSelectedDateReports}
          reportDate={[reportPreview?.since, reportPreview?.until]}
        />

        <DateCompareDropdown
          compareSelectedDate={compareSelectedDate}
          setCompareSelectedDate={onCompareDateSelected}
          selectedDateFilter={selectedDate}
        />
      </HeaderItemContainer>
    </HeaderDashboardAnalyticsContainer>
  ) : (
    <HeaderDashboardAnalyticsContainer
      className={`header_report ${reportId === reportPreview?._id ? 'show' : ''}`}
    ></HeaderDashboardAnalyticsContainer>
  )
}

const HeaderDashboardAnalyticsContainer = styled(Box)`
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &.show {
    opacity: 1;
  }
  &.header_report {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: 2;
  }
`

const HeaderItemContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;

  &.header_icon_left {
    flex: 1;
    color: ${() => AppStore.theme.o.darkGrey};
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 11.63px;
    letter-spacing: 1%;
    align-self: flex-end;

    justify-content: flex-start;
  }

  &.header_text_center-container {
    flex: 1;

    justify-content: center;
    align-items: center;
  }

  &.header_text_right {
    flex: 1;
    justify-content: flex-end;
  }
`

const InputAndDateContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 8px;
`

const LastModifyDate = styled.p`
  flex: 1;
  color: ${() => AppStore.theme.o.darkGrey};
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 11.63px;
  letter-spacing: 1%;
  align-self: center;
  justify-content: center;
  min-width: 200px;
  text-align: center;
`
